import algoliasearch from "algoliasearch";
import * as animationData from "assets/animations/loading.json";

import {
  ICON_IN_PLACE,
  ICON_SITE_WEB,
  ICON_WHATS_GREEN,
  NO_AVAILABLE,
} from "assets/icons";
import {
  ICON_MEMBERSHIP,
  PRIME_BRONCE,
  PRIME_ORO,
  PRIME_PLATA,
} from "../assets/image";

export const ID_TOKEN_STOTAGE = "id_token";

export const API_URL = {
  dev: "https://dev.vivesmas.co",
  prod: "https://api.vivesmas.co",
  test: "http://192.168.0.41:3030",
};

export const S3_CREDENTIAL = {
  baseUrl: "https://dnfhqmc2wswkq.cloudfront.net",
};

export const dictionaryNames: { [key: string]: string } = {
  IN_PLACE: "En el sitio",
  WHATSAPP: "WhatsApp",
  WEB: "Sitio web",
};
export const dictionaryIcon: { [key: string]: any } = {
  IN_PLACE: ICON_IN_PLACE,
  WHATSAPP: ICON_WHATS_GREEN,
  WEB: ICON_SITE_WEB,
  no_available: NO_AVAILABLE,
};

export const dictionaryIconMembership: { [key: string]: any } = {
  "0": ICON_MEMBERSHIP,
  "1": PRIME_BRONCE,
  "2": PRIME_PLATA,
  "3": PRIME_ORO,
};

export const dictionaryProgessBar: { [key: string]: any } = {
  "0": 0,
  "1": 12,
  "2": 50,
  "3": 86,
};

export const algoliaKeys = {
  applicationId: "06PPWQFN0Z",
  searchOnlyAPIKey: "89ae9f2f1100b61c88dc880d2ab0cb7c",
  indexList: {
    commerces: "commerces",
    benefits: "benefits",
    establishments: "establishments",
  },
};
export const searchClient = algoliasearch(
  algoliaKeys.applicationId,
  algoliaKeys.searchOnlyAPIKey
);

export const LINK_NOTICE_PERSONAL_DATA_TREATMENT =
  "https://cityprime.com.co/aviso-de-privacidad#aviso-de-privacidad";
export const LINK_NOTIVCE_PRIVACITY =
  "https://cityprime.com.co/politica-de-privacidad#politicadeprivacidad";
export const LINK_NOTICE_TERMS_CONDITIONS =
  "https://cityprime.com.co/terminos-y-condiciones#terminos-y-condiciones";
export const LINK_PAY_FACTURE = "https://www.air-e.com/paga-tu-factura/";

export const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

export const firmOnes = {
  urlFirmOnes: "https://losfirmes.vivesmas.co",
};
