import { Routes, Route, Navigate } from "react-router-dom";

import { HomePage, LoginPage } from "pages/index";
import VerificationCode from "components/pages/verification-code/Verification-code";
import { CommerceDetail, BenefitDetail } from "components/pages";
import CompleteProfile from "components/pages/Profile/CompleteProfile";
import EditProfile from "components/pages/EditProfile/EditProfile";
import SearchAlgolia from "components/pages/Homepage/Search /SearchAlgolia";
import AllCommerces from "components/pages/ShowAllCommerces/AllCommerces";
import AllFeatures from "components/pages/ShowAllFeatures/AllFeatures";
import IFrimeFirmOnes from "components/common/IFrimeFirmOnes";

import { PrivateRoute } from "./PrivateRoute";


function MainStack() {
  return (
    <Routes>
      <Route
        path="/home"
        element={
          <PrivateRoute>
            <HomePage />
          </PrivateRoute>
        }
      />
      <Route
        path="/commerce-detail/:id"
        element={
          <PrivateRoute>
            <CommerceDetail />
          </PrivateRoute>
        }
      />
      <Route
        path="/benefit-detail/:benefitId"
        element={
          <PrivateRoute>
            <BenefitDetail />
          </PrivateRoute>
        }
      />
      <Route
        path="/complete-profile"
        element={
          <PrivateRoute>
            <CompleteProfile />
          </PrivateRoute>
        }
      />
      <Route
        path="/edit-profile"
        element={
          <PrivateRoute>
            <EditProfile />
          </PrivateRoute>
        }
      />
      <Route
        path="/search"
        element={
          <PrivateRoute>
            <SearchAlgolia />
          </PrivateRoute>
        }
      />
      <Route
        path="/losfirmes"
        element={
          <PrivateRoute>
            <IFrimeFirmOnes />
          </PrivateRoute>
        }
      />
      <Route
        path="/show-all-commerces"
        element={
          <PrivateRoute>
            <AllCommerces />
          </PrivateRoute>
        }
      />
      <Route
        path="/show-all-features"
        element={
          <PrivateRoute>
            <AllFeatures />
          </PrivateRoute>
        }
      />

      <Route path="/signin" element={<LoginPage />} />
      <Route path="/verification-code" element={<VerificationCode />} />
      <Route path="*" element={<Navigate to="/signin" />} />
    </Routes>
  );
}

export default MainStack;
