import { SEPARATOR } from "assets/image";
import {
  Monserrat10px400,
  Monserrat10px500,
} from "components/typography/typographt";
import styled from "styled-components";
import { colors } from "themes/light/colors";

export const Contianer = styled.div`
  width: 348px;
  ${({ theme: { media } }) => media.tablet`
   width: 360px;
  `};
  ${({ theme: { media } }) => media.desktop`
    width: 490px;
  `};
`;
export const CardBenefit = styled.button<{ shadow?: boolean }>`
  width: 100%;
  height: 120px;
  border-radius: 10px;
  display: flex;
  outline: none;
  padding: 0;
  border: none;
  cursor: pointer;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 23px;
  line-height: 24px;
  box-shadow: ${({ shadow }) =>
    shadow ? " 0 3px 3px rgba(0, 0, 0, 0.28)" : null};
  background-color: transparent;
  ${({ theme: { media } }) => media.tablet`
    height: 150px;
  `};
  ${({ theme: { media } }) => media.desktop`
    height: 100%;
  `};
`;
export const ContentImage = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  height: 100%;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  max-width: 100px;

  ${({ theme: { media } }) => media.tablet`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    height: 100%;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
    max-width: 100px;
  `};
  ${({ theme: { media } }) => media.desktop`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    height: 100%;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
    max-width: 100px;
  `};
`;

export const ContainerSeparator = styled.div`
  height: 100%;
  background-color: transparent;
`;

export const Separator = styled.img.attrs({
  src: SEPARATOR,
})`
  width: 40px;
  height: 100%;
`;

export const ContainerRight = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-evenly;
  padding: 5px 0;
  width: 90%;
  height: 100%;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  background-color: #fff;
  ${({ theme: { media } }) => media.tablet`
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    width: 90%;
    height: 100%;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
    border-top-left-radius: 3px;
    boder-bottom-left-radius: 3px;
    background-color: #fff;
  `};
  ${({ theme: { media } }) => media.desktop`
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    width: 90%;
    height: 100%;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    background-color: #fff;
  `};
`;

export const Text = styled.div`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  text-align: start;
  line-height: 15px;
  letter-spacing: 0.02em;
  margin-bottom: 18px;
  text-transform: uppercase;
  color: #000000;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  max-height: 32px;
  ${({ theme: { media } }) => media.tablet`
    font-size: 13px;
    line-height: 20px;
      text-align: start;
  `};
  ${({ theme: { media } }) => media.desktop`
    font-size: 12px;
    text-align: start;
    white-space: wrap;
    overflow: hidden;
    width: 100%;
    max-width: 350px;
    max-height: 50px;
    margin-bottom: 10px;
    text-overflow: ellipsis;
  `};
`;
export const ImageBenefit = styled.img`
  object-fit: cover;
  width: 82px;
  height: 82px;
  border-color: #fff;
  border-width: 1px;
  border-radius: 50px;
`;

export const ContentTextBenefit = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: 4px 0;
  ${({ theme: { media } }) => media.tablet`
   justify-content: flex-start;
  `};
  ${({ theme: { media } }) => media.desktop`
   justify-content: flex-start;
  `};
`;

export const IconText = styled.img`
  width: 17px;
  height: 20px;
  object-fit: cover;
  margin: 0 3px;
`;
export const TextBenefitCategory = styled(Monserrat10px400)`
  color: black;
  text-transform: uppercase;
  font-size: 10px;
  margin-top: 5px;
  margin-left: 5px;
  ${({ theme: { media } }) => media.tablet`
   font-size: 10px;
  `};
  ${({ theme: { media } }) => media.desktop`
  font-size: 12px;
  `};
`;

export const ContentFooter = styled.div`
  flex-direction: row;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: auto;
  margin-top: 5px;
`;
export const TextActualPrice = styled(Monserrat10px400).attrs({
  numberOfLines: 1,
})`
  color: #006abf;
  font-weight: 700;
  margin-right: 5px;
  max-width: 100px;
`;
export const TextOldPrice = styled(Monserrat10px500)`
  color: gray;
  font-weight: 600;
  text-decoration: line-through;
  margin-right: 5px;
`;

export const Discount = styled.div`
  width: 55px;
  height: 20px;
  background-color: ${colors.primaryColor};
  border-radius: 10px;
  display: flex;
  margin-top: -10px;
  padding-top: 10px;
  align-items: center;
  justify-content: center;
`;

export const TextBenenifitDiscount = styled(Monserrat10px500)`
  color: #fff;
`;
