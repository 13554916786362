import styled from "styled-components";
import { colors } from "themes/light/colors";

export const BannerContainer = styled.div`
  background: linear-gradient(
    180deg,
    #006abf 0%,
    rgba(31, 119, 190, 0.83) 100%
  );
  width: 80vw;
  max-width: 358px;
  height: 120px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  font-size: 16px;
  margin-top: 20px;
  cursor: pointer;
  ${({ theme: { media } }) => media.tablet`
    font-size: 24px;
    max-width: unset;
  `};
  ${({ theme: { media } }) => media.desktop`
  width: 970px;
  height: 120px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  font-size: 36px;
  `};

  :hover {
    background: #006abf;
  }
`;

export const ClickLogo = styled.div`
  width: 90px;
  height: 90px;
  min-height: 50px;
  min-width: 50px;
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
`;

export const FactureLogo = styled.div`
  min-width: 100px;
  min-height: 117px;
`;

export const Text = styled.p`
  color: white;
  margin: 0;
`;
