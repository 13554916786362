import { SecureRequest } from "../utils/secureRequest";

export const getCommerces = async (
  token: string,
  idDepartment: string,
  membershipLevel: number
) => {
  return await SecureRequest(token).get(
    `/commerces?pagination[page]=1&pagination[perPage]=10&where[$$benefits.benefits_departments.department_id$$]=${idDepartment}&where[$$benefits.expiry$$][$gt]=${new Date().toISOString()}&where[$$benefits.status$$]=ACTIVE&where[$$benefits.membership.level$$][$lte]=${membershipLevel}`
  );
};

export const getCommerceById = async (token: string, id: string) => {
  return await SecureRequest(token).get(`/commerces/${id}`);
};

export const getAllCommerces = async (token: string, idDepartment: string, membership_level: number) => {
  return await SecureRequest(token).get(
    `/commerces?where[$$benefits.benefits_departments.department_id$$]=${idDepartment}&where[$$benefits.expiry$$][$gt]=${new Date().toISOString()}&where[$$benefits.status$$]=ACTIVE&where[$$benefits.membership.level$$][$lte]=${membership_level}`
  );
};
export const getBenefitCommerceById = async (
  token: string,
  id: string,
  idDeparment: string
) => {
  return await SecureRequest(token).get(
    `/benefits?where[$$benefits_departments.department_id$$]=${idDeparment}&where[commerce_id]=${id}`
  );
};

export const sendReviewCommerce = async (token: string, data: any) => {
  const info = {
    comment: data.comment,
    score: data.score,
    commerce_id: data.commerce_id,
    user_id: data.user_id,
  };
  return await SecureRequest(token).post(`/scores`, { ...info });
};

export const getScoresBenefit = async (token: string) => {
  return await SecureRequest(token).get(`/users-benefits/whit-out-scores`);
};
