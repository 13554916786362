import { Dispatch, SetStateAction } from "react";
import { createPortal } from "react-dom";

import {
  BodyModal,
  ContainerModal,
  ModalBackground,
  TitleHeaderModal,
  TextPendingData,
  ButtonPendingData,
  HeaderModalPendingData,
} from "./styles";

type ModalBenefitDetailProps = {
  handleClose: Dispatch<SetStateAction<boolean>>;
  handleNavigatePendingData: () => void;
};

const ModalUserPendingData = ({
  handleClose,
  handleNavigatePendingData,
}: ModalBenefitDetailProps) => {
  return createPortal(
    <ModalBackground>
      <ContainerModal>
        <HeaderModalPendingData onClick={() => handleClose(false)}>
          <TitleHeaderModal>¡Actualiza tu perfil!</TitleHeaderModal>
        </HeaderModalPendingData>
        <BodyModal>
          <TextPendingData>
            Para acceder a este beneficio, debes actualizar tus datos 🤓
          </TextPendingData>
          <br />

          <ButtonPendingData onClick={handleNavigatePendingData}>
            Aceptar
          </ButtonPendingData>
        </BodyModal>
      </ContainerModal>
    </ModalBackground>,
    document.getElementById("modal-benefit-detail") as HTMLElement
  );
};

export default ModalUserPendingData;
