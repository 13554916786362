import { useNavigate } from "react-router-dom";
import "swiper/css";

import { Search, SeacionOne, SeacionTwo, ImageSearch } from "./styles";

const SearchPage = () => {
  let navigate = useNavigate();
  const handleSearchAlgolia = () => {
    navigate("/search");
  };
  return (
    <Search onClick={handleSearchAlgolia}>
      <SeacionOne  placeholder="Buscar...">
        Buscar...
      </SeacionOne>
      <SeacionTwo>
        <ImageSearch />
      </SeacionTwo>
    </Search>
  );
};

export default SearchPage;
