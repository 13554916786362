import { useEffect, useState } from "react";
import { Layout } from "antd";

import { LayoutType } from "types/layout";
import { HeaderPage } from "components/pages";
import BannerDownloadApp from "components/banners/BannerDownloadApp";

import { WrapperLayout } from "./styles";

const { Content } = WrapperLayout;

const LayoutHome = ({ children, ...props }: LayoutType) => {
  const [showBannerApp, setShowBannerApp] = useState<boolean>(false);
  const hideBanner = () => {
    setShowBannerApp(false);
    localStorage.setItem("show-banner-app", "true");
  };
  useEffect(() => {
    const showBannerLocalS = JSON.parse(
      localStorage.getItem("show-banner-app") as string
    );

    if (showBannerLocalS) {
      setShowBannerApp(false);
      localStorage.setItem("show-banner-app", "true");
    } else {
      setShowBannerApp(true);
      localStorage.setItem("show-banner-app", "false");
    }
  }, []);

  return (
    <WrapperLayout {...props}>
      <Layout>
        {showBannerApp ? (
          <BannerDownloadApp handleHideBanner={hideBanner} />
        ) : null}

        <HeaderPage />
        <Content>{children}</Content>
      </Layout>
    </WrapperLayout>
  );
};

export default LayoutHome;
