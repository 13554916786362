import styled from "styled-components";
import {
  Monserrat12px400,
  Monserrat12px500,
  Monserrat12px600,
  Monserrat39px700,
} from "components/typography/typographt";

export const OverallContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
  background-color: #e1e1e1;
  ${({ theme: { media } }) => media.tablet`
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
     width: 100%;
    height: 100vh;
    background-color: #e1e1e1;
  `};
  ${({ theme: { media } }) => media.desktop`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
     width: 100%;
    height: 100%;
  background-color: #e1e1e1;
  `};
`;

export const ContainerSectionTwo = styled("h2")`
  width: 100vw;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;

  ${({ theme: { media } }) => media.desktop`
  width: 98vw;
  border-radius: 20px;
  display: flex;
  justify-content:center;
  align-items: center;
  margin-top:40px;
  `};
`;
export const ButtonFive = styled.div`
  width: 95%;
  height: auto;
  background: #ffffff;
  border-radius: 12px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  box-shadow: 2px 6px 10px -1px rgba(0, 0, 0, 0.12);
  font-size: 20px;
  line-height: 15px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #000000;
  padding: 30px 0;
  ${({ theme: { media } }) => media.tablet`
  width: 85%;
  height: auto;
  background: #ffffff;
  border-radius: 12px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 15px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #000000;
      
  `};
  ${({ theme: { media } }) => media.desktop`
width: 800px;
  height: auto;
  background: #FFFFFF;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
    margin-bottom: 0;
  font-size: 20px;
  line-height: 24px;
      
  `};
`;
//rgb(239,239,239)
export const ContentBenefit = styled.div`
  max-width: 90%;

  ${({ theme: { media } }) => media.tablet`
    width: 85%;
    height: 180px;
    margin-bottom: 20px;
  `};
  ${({ theme: { media } }) => media.desktop`
    width: 60.5%;
    max-height: 120px;
    margin-bottom: 20px;
  `};
`;

export const ContainerSeccionTree = styled.div`
  width: 100%;
  height: 100%;
  background: #ffffff;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 40px;
  ${({ theme: { media } }) => media.tablet`
width: 100%;
  height: auto;
  background: #FFFFFF;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
        margin-bottom: 40px;
          margin-top: 40px;
  `};
  ${({ theme: { media } }) => media.desktop`
width: 800px;
  height: auto;
  background: #FFFFFF;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
  margin-top: 40px;
  `};
`;

export const ContentImg = styled.div`
  width: 300px;
  height: 141px;
  ${({ theme: { media } }) => media.tablet`
    width: 600px;
    height: 282px;
  `};
  ${({ theme: { media } }) => media.desktop`
    width: 600px;
    height: 282px;
  `};
`;
export const ImageHeaderCode = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 6px;
`;

export const ContenFooter = styled.div`
  width: 100%;
  height: 100%;
  ${({ theme: { media } }) => media.tablet`
    width: 82.5%;
    height: 100%;
  `}
  ${({ theme: { media } }) => media.desktop`
    width: 82.5%;
    height: 100%;
  `}
`;

export const ContentError = styled.div`
  width: 90%;
  height: 150px;
  border-radius: 17px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.themeApp.loginBorderInput};
  ${({ theme: { media } }) => media.tablet`
    font-size: 20;
    width: 80%;
    height: 130px;
  `};
  ${({ theme: { media } }) => media.desktop`
    font-size: 20;
    width: 80%;
    height: 130px;
  `};
`;

export const TitleMessageError = styled.h3`
  font-family: Montserrat;
  font-size: 15px;
  color: ${({ theme }) => theme.colors.themeApp.white};
  font-weight: 700;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: center;
  ${({ theme: { media } }) => media.tablet`
    font-size: 11px;
  `};
  ${({ theme: { media } }) => media.desktop`
    font-size: 20px;
  `};
`;

export const ButtonSeeOtherBenefits = styled.div`
  width: 170px;
  height: 35px;
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  background-color: ${({ theme }) => theme.colors.primaryColor};
  border-radius: 26.5px;
  color: ${({ theme }) => theme.colors.themeApp.white};
`;

export const TextBenefitError = styled(Monserrat12px500)`
  color: ${({ theme }) => theme.colors.themeApp.white};
  font-weight: normal;
  max-width: 600px;
  text-align: center;
  text-transform: capitalize;
  font-size: 10px;
  line-height: 15px;
  ${({ theme: { media } }) => media.tablet`
    font-size: 11px;
  `};
  ${({ theme: { media } }) => media.desktop`
    font-size: 13px;
  `};
`;

//BenefitDetailFooter

export const InfoBenefitDetailBottom = styled.div`
  flex: 1;
  padding: 0 20px;
  justify-content: space-evenly;
`;

export const InfoBenefitDetailBottomItem = styled.div`
  margin-bottom: 17px;
`;

export const ButtonWhats = styled.button`
  width: 50%;
  height: 50px;
  min-width: 70px;
  background-color: green;
  flex-direction: row;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 6px;
  border: none;
  cursor: pointer;
  ${({ theme: { media } }) => media.tablet`
    width: 33%;
    height: 50px;
    min-width: 70px;
    background-color: green;
    flex-direction: row;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-radius: 6px;
    border: none;
  `};
  ${({ theme: { media } }) => media.desktop`
    width: 25%;
    height: 50px;
    min-width: 70px;
    background-color: green;
    flex-direction: row;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-radius: 6px;
    border: none;
  `};
`;

export const ButtonOpenUrl = styled.button`
  width: 40%;
  height: 50px;
  background-color: #006abf;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  border-radius: 6px;
  border: none;
  cursor: pointer;
  ${({ theme: { media } }) => media.tablet`
    width: 20%;
    height: 50px;
    background-color: #006abf;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    border-radius: 6px;
    border: none;
  `};
  ${({ theme: { media } }) => media.desktop`
    width: 40%;
    height: 50px;
    background-color: #006abf;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    border-radius: 6px;
    border: none;
  `};
`;

export const ButtonWhatsText = styled(Monserrat12px600)`
  color: white;
  margin-top: 10px;
  font-size: 13px;
`;

export const IconsWhats = styled.img`
  width: 20px;
  height: 20px;
  object-fit: contain;
`;

export const ReloadCodePlaceListCode = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 160px;
  height: 40px;
  border: none;
  background: #006abf;
  border-radius: 7px;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
`;

export const TextReloadPlaceListCode = styled(Monserrat12px600)`
  color: #fff;
  margin: 5px 5px;
`;

export const IconRealoadCode = styled.img`
  width: 17px;
  height: 17px;
  object-fit: cover;
`;

export const IconRealoadPlaceListCode = styled.img`
  width: 17px;
  height: 17px;
  filter: invert(100%);
  object-fit: cover;
`;

export const TextDetail = styled(Monserrat12px600)`
  color: black;
  margin-bottom: 7px;
  text-transform: capitalize;
`;
export const TextDetailInfo = styled(Monserrat12px400)`
  color: black;
  text-transform: capitalize;
`;

//BenefitWebListCode
export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const ContentCopyCode = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: auto;
  height: 50px;
`;
export const TextCodeType = styled(Monserrat39px700)`
  font-size: 30px;
  color: black;
  text-transform: uppercase;
  margin: 0 10px;
`;

export const PressableCopyCode = styled.button`
  width: 30px;
  height: 30px;
  border: 1px solid gray;
  border-radius: 5px;
  background: #fff;
`;

export const ContentButtonWeb = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin: 20px 0;
`;
export const ButtonOpenUrlWebListcode = styled.div`
  width: 144px;
  height: 40px;
  background-color: #006abf;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  border-radius: 6px;
  cursor: pointer;
`;
export const IconsWhatsWebListcode = styled.img`
  width: 20px;
  height: 20px;
  object-fit: contain;
`;
export const ButtonWhatsTextWebListcode = styled(Monserrat12px600)`
  color: white;
  margin-top: 10px;
`;

export const ButtonsWebWebListcode = styled.div`
  width: 144px;
  height: 40px;
  border-radius: 6px;
  border: 1px solid black;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  cursor: pointer;
`;
export const ReloadCodeWebListcode = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
export const TextReloadCodeWebListcode = styled(Monserrat12px600)`
  color: black;
  margin-left: 5px;
  margin-top: 10px;
`;

//button unidrogas
export const ButtonUnidrogas = styled.button`
  width: 214px;
  height: 54px;
  border-radius: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  color: #ffffff;
  background-color: #006abf;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
`;
