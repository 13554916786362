import normalize from "themes/normalize";
import { createGlobalStyle, css } from "styled-components";

const sharedStyles = css`
  ${normalize}

  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  b,
  strong {
    font-weight: bold;
  }

  /* Hide the tracking pixels */
  img[height="1"] {
    position: absolute;
  }

  button,
  input,
  select,
  textarea,
  a {
    &:focus {
      outline-color: #26ddf9;
    }
  }

  body:not(.user-is-tabbing) button:focus,
  body:not(.user-is-tabbing) input:focus,
  body:not(.user-is-tabbing) select:focus,
  body:not(.user-is-tabbing) textarea:focus,
  body:not(.user-is-tabbing) a:focus {
    outline: none;
  }
`;

export const GlobalStyle = createGlobalStyle`
  ${sharedStyles}
  body {
    box-sizing: border-box;
    background-color: ${({ theme }) => theme.colors.backgroundPrimary};
    color: ${({ theme }) => theme.colors.textPrimary};
  }
`;
