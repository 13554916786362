import React from "react";
import { BannerContainer, Image, FactureLogo, ClickLogo, Text } from "./styles";
import { ICON_FACTURE, ICON_CLICK } from "assets/icons";
import { LINK_PAY_FACTURE } from "utils/contants";

const handleRedirect = () => {
  window.open(LINK_PAY_FACTURE, "_blank");
};

const PayBanner = () => {
  return (
    <BannerContainer onClick={handleRedirect}>
      <FactureLogo>
        <Image src={ICON_FACTURE} alt="paga tu factura aqui" />
      </FactureLogo>
      <Text>Paga tu factura AIR-E dando clic AQUÍ</Text>
      <ClickLogo>
        <Image src={ICON_CLICK} alt="click aqui para pagar" />
      </ClickLogo>
    </BannerContainer>
  );
};

export default PayBanner;
