import { vivemasApi } from "utils/request";
import { SecureRequest } from "utils/secureRequest";
import { IPropUpdateInfoUser } from "./api.dto";

export const login = (data: any) => {
  return vivemasApi.post("/auth/otp", {
    id_token: data.id_token,
  });
};

export const updateInfoUser = async ({
  email,
  first_name,
  id_token,
  identification_number,
  last_name,
  nic,
  customer_quality,
  document_type,
  avatar,
  id,
  birthday_date,
  landline,
  second_name,
  second_last_name,
  sex,
}: IPropUpdateInfoUser) => {
  try {
    const infoUpdate = {
      first_name,
      last_name,
      identification_number,
      email,
      nic,
      customer_quality,
      document_type,
      avatar,
      birthday_date,
      landline,
      second_name,
      second_last_name,
      sex,
    };
    return await SecureRequest(id_token).patch(`/users/${id}`, infoUpdate);
  } catch (err) {
    console.log("error update info user", err);
    throw err;
  }
};

export const setDeleteAccount = async (token: string, userId: string) => {
  return await SecureRequest(token).delete(`/users/${userId}`);
};

export const uploadImageUser = async (token: string, newImage: any) => {
  try {
    const { name, type, size } = newImage;
    let result = await SecureRequest(token).post(`/users/upload-image`, {
      name,
      type,
      size,
    });
    await fetch(result.data.url, {
      method: "PUT",
      body: newImage,
    });
    return result;
  } catch (err) {
    console.log("error upload image user", err);
  }
};

export const updateUserDepartment = async (
  token: string,
  id: string,
  data: any
) => {
  try {
    return await SecureRequest(token).patch(`/users/${id}`, { ...data });
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getMemberships = async (token: string) => {
  try {
    return await SecureRequest(token).get("/memberships");
  } catch (error) {
    throw error;
  }
};
