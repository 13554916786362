import { BACKGROUND_BANNER_IMAGE_LOGO } from "assets/image";

import {
  ContainerBanner,
  Content,
  ContentBody,
  ContentBodySubTitle,
  ContentBodyTitle,
  ContentClose,
  ContentImg,
  Img,
  GoDownloadApp,
} from "./styles";

type BannerDownloadAppProps = {
  handleHideBanner: () => void;
};

function BannerDownloadApp({ handleHideBanner }: BannerDownloadAppProps) {
  function goToDownloadVivemas() {
    window.open("https://vivemas.page.link/download", "_blank");
  }

  return (
    <ContainerBanner>
      <Content>
        <ContentBody>
          <ContentBodyTitle>¡Beneficios exclusivos en la App!</ContentBodyTitle>
          <ContentBodySubTitle>
            Descárgala y entérate al instante.
          </ContentBodySubTitle>
          <GoDownloadApp onClick={goToDownloadVivemas}>
            Descárgalo ahora
          </GoDownloadApp>
        </ContentBody>
        <ContentImg src={BACKGROUND_BANNER_IMAGE_LOGO} />
        <ContentClose onClick={handleHideBanner}>
          <Img />
        </ContentClose>
      </Content>
    </ContainerBanner>
  );
}

export default BannerDownloadApp;
