import moment from "moment";

// function that allows us to determine a boolean between two times.
export const isOpen = (
  hourStart: string,
  hourEnd: string,
  hourCurrent: string
) => {
  const start = moment(hourStart, "HH:mm");
  const end = moment(hourEnd, "HH:mm");
  const current = moment(hourCurrent, "HH:mm");
  return current.isBetween(start, end);
};
