import React from "react";
import { useNavigate } from "react-router-dom";

import {
  BusinessTabsdesktop,
  CommerceCenter,
  CommerceLeft,
  CommerceTitle,
  Image3,
} from "components/pages/Homepage/HomeCommerce/styles";
import { CommerceItems } from "redux/redux.dto";
import { S3_CREDENTIAL } from "utils/contants";
import { UserPendingData } from "../../hooks/userPendingData";
import ModalUserPendingData from "components/modals/UserPendingData";
import { useViveSelector } from "../../hooks/reduxHooks";

const Commerce: React.FC<CommerceItems> = ({ id, image, name }) => {
  const { user } = useViveSelector((state) => state.user);
  const navigate = useNavigate();
  const { handleNavigatePendingData, pendingData, setPendingData } =
    UserPendingData();

  const seeDetails = () => {
    if (user.status === "PENDING_DATA") {
      return setPendingData(true);
    }
    navigate(`/commerce-detail/${id}`);
  };

  if (pendingData) {
    return (
      <ModalUserPendingData
        handleClose={setPendingData}
        handleNavigatePendingData={handleNavigatePendingData}
      />
    );
  }

  return (
    <BusinessTabsdesktop onClick={seeDetails}>
      <CommerceLeft>
        <Image3
          src={`${S3_CREDENTIAL.baseUrl}/${image}`}
          style={{ borderRadius: "50px" }}
        />
      </CommerceLeft>
      <CommerceCenter>
        <CommerceTitle>{name}</CommerceTitle>
      </CommerceCenter>
    </BusinessTabsdesktop>
  );
};

export default Commerce;
