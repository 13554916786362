import ICON_CLICK from "./click.svg";

export const LOGO_VIVEMAS = require("../icons/vive_logo.png");
export const ICON_COL = require("../icons/icon-col.png");
export const ICON_ALERT = require("../icons/icon-alert.png");
export const EDIT_PROFILE = require("../icons/edit-profile.png");
export const DELETE_ACCOUNT = require("../icons/delete-account.png");
export const CLOSE_SESSION = require("../icons/close-session.png");
export const WHATS_SUPPORT = require("../icons/whats-support.png");
export const LOCK = require("../icons/Lock.png");
export const SEARCH = require("../icons/search.png");
export const CHECK = require("../icons/icon-check.png");
export const ICON_IN_PLACE = require("../icons-benefits/in_place.png");
export const ICON_SITE_WEB = require("../icons-benefits/in_site_web.png");
export const ICON_WHATS_GREEN = require("../icons-benefits/icon-whats-green.png");
export const NO_AVAILABLE = require("../icons-benefits/no-disponible.png");
export const ICON_RELOAD = require("../icons/icon-reload.png");
export const ICON_COPY_CODE = require("../icons/icon-copy-code.png");
export const ICON_WHATS = require("../icons/icon-whats.png");
export const ICON_WORLD = require("../icons/icon-world.png");
export const ICON_PRIME = require("../icons/icon-prime.png");
export const ICON_EDIT_PROFILE = require("../icons/icon-edit-profile.png");
export const CIRCLE_CLOSE = require("../icons/circle-close.png");
export const CIRCLE_OPEN = require("../icons/circle-state.png");
export const START_REVIEW = require("../icons/star-review.png");
export const ICON_CUPON = require("../icons/icon-cupon.png");
export const CLOSE_MODAL = require("../icons/close-modal.png");
export const PRIME = require("../icons/prime.png");
export const ICON_CANCEL_UNIDROGAS = require("../icons/icon-cancel-unidrogas.png");
export const ICON_CHECK_UNIDROGAS = require("../icons/icon-check-unidrogas.png");
export const ICON_ALERT_YELLOW = require("../icons-benefits/icon-alert-yellow.png");
export const ICON_FACTURE = require("../icons/facture.png");
export const ICON_SELECT_DEPARTMENT = require("../icons/icon-select-department.png");
export { ICON_CLICK };
