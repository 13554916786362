import { useState, useEffect, Fragment } from "react";
import { connectHits } from "react-instantsearch-dom";
import { BenefitItems } from "redux/redux.dto";

import Benefit from "components/Benefit/Benefit";

import { TextEmpty } from "./styles";

const CustomBenefitHits = ({ hits }: any) => {
  const [benenfitFiltred, setBenenfitFiltred] = useState<any[]>([]);
  useEffect(() => {
    if (hits.length > 0) {
      let hitsFildred = hits.filter((item: any) => item.status === "ACTIVE");
      setBenenfitFiltred(hitsFildred);
    } else {
      setBenenfitFiltred([]);
    }
  }, [hits]);
  return (
    <Fragment>
      {benenfitFiltred.length > 0 ? (
        benenfitFiltred.map((el: BenefitItems) => (
          <Benefit key={el.id} {...el} />
        ))
      ) : (
        <TextEmpty>No hay beneficios disponibles</TextEmpty>
      )}
    </Fragment>
  );
};

export default connectHits(CustomBenefitHits);
