import { useEffect, useState } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { PageHeader } from "antd";

import { isOpen } from "utils/isOpenCommerce";
import { CommerceItems } from "redux/redux.dto";
import { _GET_ALL_COMMERCES } from "redux/actions/commerceAction";
import { pageView } from "utils/mixpanel";
import LoaderApp from "components/loader/LoaderApp";
import { useViveDispatch, useViveSelector } from "../../../hooks/reduxHooks";
import { useUserMembershipLevel } from "hooks/useMembershipLevel";

import Item from "./Item";
import { Container, Content, EmptyAllCommerces } from "./styles";

const AllCommerces = () => {
  const [commerceSchedules, setCommerceSchedules] = useState<any>([]);
  const { allCommerces, isLoadingAllCommerces } = useViveSelector(
    (state) => state.commerces
  );
  const {selectedDepartment} = useViveSelector(state => state.departments)
  const navigate = useNavigate();
  const dispatch = useViveDispatch();
  const {membershipLevel} = useUserMembershipLevel();

  useEffect(() => {

    dispatch(_GET_ALL_COMMERCES({
      membership_level: membershipLevel.level,
      department_id: selectedDepartment.id || "1"
    })).then((r) => {});
  }, [dispatch, membershipLevel.level, selectedDepartment.id]);

  useEffect(() => {
    if (allCommerces.length > 0) {
      allCommerces.map((commerce: CommerceItems) => {
        const day = moment().format("dddd");
        const scheduleCurrent = commerce.schedules.find(
          (schedule: any) => schedule.weekday === day.toUpperCase()
        );
        if (scheduleCurrent) {
          const open = isOpen(
            scheduleCurrent.start_time,
            scheduleCurrent.end_time,
            moment().format("HH:mm")
          );
          scheduleCurrent.isOpen = open;
          commerce.isOpenCommerce = open;
        }
      });
      setCommerceSchedules(allCommerces);
      pageView({
        PageType: "Commerces List Web",
        Name: "All Commerces List Web",
        UserTime: new Date().toDateString(),
      });
    }
  }, [allCommerces]);

  if (isLoadingAllCommerces) return <LoaderApp />;
  return (
    <Container>
      <PageHeader
        className="site-page-header"
        onBack={() => navigate(-1)}
        title={"Comercios"}
      />
      <Content>
        {commerceSchedules.length > 0 ? (
          commerceSchedules.map((el: any) => <Item key={el.id} {...el} />)
        ) : (
          <EmptyAllCommerces>No Hay Comercios Disponibles</EmptyAllCommerces>
        )}
      </Content>
    </Container>
  );
};

export default AllCommerces;
