import { getCategories } from "api/categories";
import { ThunkAction } from "redux-thunk";
import { ICategoryReducer, ISelectedTabCategory } from "redux/redux.dto";
import { GET_CATEGORIES, SET_SELECTED_CATEGORY_TAB } from "redux/types";

export const _GET_CATEGORIES =
  (
    membership_level: number,
    department_id: string
  ): ThunkAction<Promise<void>, ICategoryReducer, any, any> =>
  async (dispatch, getState: Function) => {
    try {
      const token = getState().user.id_token;
      const res = await getCategories(token, department_id, membership_level);
      if (res.status === 200) {
        const { data } = res;
        dispatch({
          type: GET_CATEGORIES,
          payload: data.data,
        });
      } else {
        dispatch({
          type: GET_CATEGORIES,
          payload: [],
        });
      }
    } catch (error) {
      dispatch({
        type: GET_CATEGORIES,
        payload: true,
      });
      console.log(error);
    }
  };

export const _SET_SELECTED_CATEGORY_TAB = (data: ISelectedTabCategory) => {
  return {
    type: SET_SELECTED_CATEGORY_TAB,
    payload: data,
  };
};
