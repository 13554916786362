import { GET_ALL_FUEATURES, GET_FEATURES } from "../types";

const INITIAL_STATE = {
  features: [],
  isLoading: true,
  selectFeature: {},
  allFeatures: [],
  isLoadingAllFeatures: true,
};

export const featureReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case GET_FEATURES:
      return {
        ...state,
        features: action.payload,
        isLoading: false,
      };
    case GET_ALL_FUEATURES:
      return {
        ...state,
        allFeatures: action.payload,
        isLoadingAllFeatures: false,
      };
    default:
      return state;
  }
};
