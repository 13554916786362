import { useEffect, useState } from "react";
import { Subject } from "rxjs";
import { io } from "socket.io-client";
import { API_URL } from "utils/contants";

export const useSocket = (token: string) => {
  const [subject] = useState(new Subject());

  useEffect(() => {
    const socket = io(`${API_URL.prod}`, {
      forceNew: true,
      auth: { token },
      transports: ["websocket"],
    });

    socket.on("benefit_reclaimed", async (data: any) => {
      subject.next(data);
    });

    return () => {
      socket.removeAllListeners();
      socket.close();
    };
  }, [subject, token]);

  return subject;
};
