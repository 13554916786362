import {
  LINK_NOTICE_PERSONAL_DATA_TREATMENT,
  LINK_NOTICE_TERMS_CONDITIONS,
  LINK_NOTIVCE_PRIVACITY,
} from "utils/contants";

import {Content, TextPrivacity, TextPreview} from "components/pages/LoginPage/styles";

const TermsAndConditions = () => {
  const handlePrivacity = (url: string) => {
    window.open(`${url}`);
  };
  return (
    <Content>
      <TextPreview>
        Confirmo que he leído y acepto el tratamiento de mis datos personales,
        de conformidad con el{" "}
        <TextPrivacity
          onClick={() => handlePrivacity(LINK_NOTICE_PERSONAL_DATA_TREATMENT)}
        >
          Aviso De Privacidad,{" "}
        </TextPrivacity>
        la{" "}
        <TextPrivacity onClick={() => handlePrivacity(LINK_NOTIVCE_PRIVACITY)}>
          Política De Privacidad
        </TextPrivacity>{" "}
        y los{" "}
        <TextPrivacity
          onClick={() => handlePrivacity(LINK_NOTICE_TERMS_CONDITIONS)}
        >
          Términos y Condiciones.
        </TextPrivacity>
      </TextPreview>
    </Content>
  );
};

export default TermsAndConditions;
