import { InputAnt } from "./styles";
import React from "react";

type ICustomInputProps = {
  placeHolder?: string;
  type?: React.HTMLInputTypeAttribute;
  maxLength?: number;
  value: string;
  onChangeText: React.ChangeEventHandler<HTMLInputElement> | undefined;
  onBlur: React.FocusEventHandler<HTMLInputElement> | undefined;
  editable?: boolean;
};

const CustomInput = ({
  type = "text",
  value,
  onBlur,
  onChangeText,
  placeHolder = "",
  editable = false,
  maxLength,
}: ICustomInputProps) => {
  return (
    <InputAnt
      type={type}
      value={value}
      onChange={onChangeText}
      onBlur={onBlur}
      placeholder={placeHolder}
      maxLength={maxLength}
      disabled={editable}
    />
  );
};

export default CustomInput;
