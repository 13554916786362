import { useEffect } from "react";
import { PageHeader } from "antd";
import { useNavigate } from "react-router-dom";

import { EmptyAllCommerces } from "../ShowAllCommerces/styles";
import { useViveDispatch, useViveSelector } from "../../../hooks/reduxHooks";
import { _GET_ALL_FUEATURES } from "redux/actions/featureAction";
import { pageView } from "utils/mixpanel";
import LoaderApp from "components/loader/LoaderApp";
import { IFeaturesItem } from "redux/redux.dto";

import { Container, Content } from "./styles";
import AllFeatureItem from "./AllFeatureItem";
import { useUserMembershipLevel } from "hooks/useMembershipLevel";

const AllFeatures = () => {
  const navigate = useNavigate();
  const dispatch = useViveDispatch();
  const { isLoadingAllFeatures, allFeatures } = useViveSelector(
    (state) => state.features
  );
  const { membershipLevel } = useUserMembershipLevel();
  const { selectedDepartment } = useViveSelector((state) => state.departments);

  useEffect(() => {
    dispatch(
      _GET_ALL_FUEATURES({
        membership_level: membershipLevel.level,
        department_id: selectedDepartment.id || "1",
      })
    );
    pageView({
      PageType: "Banners List Web",
      Name: "All Banners List Web",
      UserTime: new Date().toDateString(),
    });
  }, [dispatch, selectedDepartment.id, membershipLevel]);

  if (isLoadingAllFeatures) return <LoaderApp />;

  return (
    <Container>
      <PageHeader
        className="site-page-header"
        onBack={() => navigate(-1)}
        title={"Destacados"}
      />
      <Content>
        {allFeatures && allFeatures.length ? (
          allFeatures.map((el: IFeaturesItem) => (
            <AllFeatureItem key={el.id} {...el} />
          ))
        ) : (
          <EmptyAllCommerces>No Hay Destacados Disponibles</EmptyAllCommerces>
        )}
      </Content>
    </Container>
  );
};

export default AllFeatures;
