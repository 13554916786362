import { Input } from "antd";
import { ICON_CUPON } from "assets/icons";
import {
  Monserrat10px500,
  Monserrat12px400,
  Monserrat12px500,
  Monserrat12px600,
} from "components/typography/typographt";
import styled from "styled-components";

export const AllowItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
`;
export const ConsumerIcon = styled.img`
  width: 12px;
  height: 12px;
  object-fit: cover;
  margin-top: -12px;
  ${({ theme: { media } }) => media.tablet`
    width: 36.39px;
    height: 36.39px;
    margin-top: 0;
  `};
  ${({ theme: { media } }) => media.desktop`
    width: 36.39px;
    height: 36.39px;
    margin-top: 0;
  `};
`;
export const TextInfoconsume = styled(Monserrat12px400)`
  font-size: 12px;
  color: #000000;
  width: 203px;
  margin-left: 10px;
  ${({ theme: { media } }) => media.tablet`
    margin-left: 8px;
    margin-bottom: -6px;
    display: flex;
    justify-content: start;
    margin-top: 0;
    font-size: 15px;
    line-height: 30px;
    width: 363px;
    color: #000000;
  `};
  ${({ theme: { media } }) => media.desktop`
    margin-left: 8px;
    margin-bottom: -2px;
    display: flex;
    justify-content: start;
    font-size: 25px;
    line-height: 30px;
    width: 363px;
    margin-top: 0;
    color: #000000;
  `};
`;

//CustomBenefitHits
export const ContainerBenefitHits = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 20px;
  ${({ theme: { media } }) => media.tablet`
     display: flex;
    flex-direction: column;
    margin-top: 20px;
  `};
  ${({ theme: { media } }) => media.desktop`
    display: flex;
    flex-direction: column;
    margin-top: 20px;
  `};
`;

export const ContenBenefit = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${({ theme: { media } }) => media.tablet`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;  
  `};
  ${({ theme: { media } }) => media.desktop`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    margin-top: 13px;
  `};
`;

export const TextEmpty = styled(Monserrat12px600)`
  color: gray;
  font-size: 20px;
  text-transform: capitalize;
`;

//CustomCommerceHits
export const ContainerCommerceHits = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  overflow-x: scroll;
`;

//CustomInput
export const InputAnt = styled(Input)`
  width: 100%;
  height: 80%;
  outline: none;
  padding: 0 8px;
  border: 1px solid #d9d9d9;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 500;
  border-radius: 6px;
  ::placeholder,
  ::-webkit-input-placeholder {
    color: #c0c0c0;
  }
  :-ms-input-placeholder {
    color: #c0c0c0;
  }
  ${({ theme: { media } }) => media.tablet`
    font-size: 16px;
  `};
  ${({ theme: { media } }) => media.desktop`
    font-size: 16px;
  `};
`;

//CustomSearch
export const CustomInputSearch = styled.input`
  width: 100%;
  height: 90%;
  border-radius: 8px;
  border: 1px solid #e5e5e5;
  padding: 0 10px;
  ${({ theme: { media } }) => media.tablet`
    width: 100%;
    height: 100%;
    border-radius: 8px;
    border: 1px solid #e5e5e5;
    padding: 0 10px;
  `};
  ${({ theme: { media } }) => media.desktop`
    width: 100%;
    height: 100%;
    border-radius: 8px;
    border: 1px solid #e5e5e5;
    padding: 0 10px;
  `};
`;

//HeaderDetail
export const PunctualRating = styled(Monserrat12px500)`
  font-size: 12px;
  color: black;
  margin: 0 4px 0 0;
  ${({ theme: { media } }) => media.tablet`
    font-size: 18px;
    height: 20px;
    margin-right: 5px;
    margin-top: 15px;
    color: black;
  `};
  ${({ theme: { media } }) => media.desktop`
    font-size: 20px;
    height: 20px;
    margin-right: 5px;
    margin-top: 5px;
    color: black;
  `};
`;
export const ImageContent = styled.div`
  width: 30%;
  height: 16px;
  margin-top: -12px;
  ${({ theme: { media } }) => media.tablet`
    width: 25%;
    height: 22px;
    margin-top: -5px;
  `};
  ${({ theme: { media } }) => media.desktop`
    width: 30px;
    height: 26px;
    margin-left: 5px;
  `};
`;

export const ContainerBack = styled.div`
  width: 25px;
  height: 20px;
  margin-top: -10px;
  ${({ theme: { media } }) => media.tablet`
    width: 45px;
    height: 100%;
  `};
  ${({ theme: { media } }) => media.desktop`
    margin-top: 0;
    width: 38px;
    height: 35px;
  `};
`;

//HeaderSection
export const ContentHeaderSection = styled.div`
  width: 100%;
  max-height: 25px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 8px;
  ${({ theme: { media } }) => media.tablet`
    width: 100%;
    min-height: 30px;
    padding: 0 8px;
  `};
  ${({ theme: { media } }) => media.desktop`
    width: 100%;
    align-items: start;
    min-height: 20px;
    padding: 0 8px;
    margin: 10px 0;
  `};
`;

export const TextLeft = styled(Monserrat12px500)`
  color: black;
  text-transform: uppercase;
  font-size: 15px;
  ${({ theme: { media } }) => media.tablet`
    color: black;
    text-transform: uppercase;
    font-size: 18px; 
    margin-left: 10px;
  `};
  ${({ theme: { media } }) => media.desktop`
    color: black;
    text-transform: uppercase;
    font-size: 18px;
  `};
`;

export const TextRight = styled(Monserrat10px500)`
  color: #343232;
  font-size: 12.5px;
  ${({ theme: { media } }) => media.tablet`
    color: #343232;
    font-size: 15px;  
  `};
  ${({ theme: { media } }) => media.desktop`
    color: #343232;
    font-size: 15px;
  `};
`;

export const TextPressable = styled.div`
  cursor: pointer;
  margin-right: 10px;
`;

//PressableShowModal
export const PressableShowCode = styled.div`
  width: 80%;
  display: flex;
  height: 40px;
  border: 1px solid #006abf;
  border-radius: 7px;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  cursor: pointer;
  ${({ theme: { media } }) => media.tablet`
    width: 80%;
    display: flex;
    height: 40px;
    border: 1px solid #006abf;
    border-radius: 7px;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  `};

  ${({ theme: { media } }) => media.desktop`
    width: 40%;
    display: flex;
    height: 40px;
    border: 1px solid #006abf;
    border-radius: 7px;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  `};
`;

export const Image = styled.img.attrs({
  src: ICON_CUPON,
  alt: "Icon Cupón",
})`
  width: 25px;
  height: 25px;
`;

export const PressableText = styled(Monserrat12px600)`
  margin: 0;
  color: #006abf;
`;

//IfrimeFrimOnes

export const ContainerFirmOnes = styled.div`
  width: 100%;
  height: 100vh;
  overflow: hidden;
  ${({ theme: { media } }) => media.tablet`
    width: 100vh;
    height: 100vh;
  `};
  ${({ theme: { media } }) => media.desktop`
    width: 100%;
    height: 100vh;
  `};
`;

export const IfrimeFirm = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
`;

//New header details
export const ContainerHeader = styled.div`
  width: 100%;
  height: 161px;
  display: flex;
  flex-direction: column;
  background-color: black;
  border-radius: 0 0 15px 15px;
  ${({ theme: { media } }) => media.tablet`
    width: 100%;
    min-height: 400px;
    background-color: black;
    height: 141px;
    display: flex;
    flex-direction: column;
    position: relative;
  `};
  ${({ theme: { media } }) => media.desktop`
    width: 1000px;
    min-height: 400px;
    background-color: black;
    height: 141px;
    display: flex;
    flex-direction: column;
    position: relative;
  `}
`;

export const HeaderImage2 = styled.div<{
  backImage: string;
}>`
  width: 100%;
  height: 161px;
  display: flex;
  flex-direction: column;
  background-image: url(${({ backImage }) => backImage});
  object-fit: cover;
  border-radius: 0 0 15px 15px;
  background-position: center;
  position: relative;
  -webkit-mask-image: linear-gradient(to top, transparent 25%, black 75%);
  mask-image: linear-gradient(to top, transparent -100%, black 75%);
  ${({ theme: { media } }) => media.tablet`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-position: center;
    justify-content: space-between;
    position: relative;
    -webkit-mask-image: linear-gradient(to top, transparent 25%, black 75%);
    mask-image: linear-gradient(to top, transparent -100%, black 75%);
  `};
  ${({ theme: { media } }) => media.desktop`
    width: 1000px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  `};
`;

export const ContainerBackArrow = styled.div`
  width: 100px;
  height: 30px;
  margin: 20px;
  ${({ theme: { media } }) => media.tablet`
    width: 15%;
    height: 40px;
    margin: 40px 20px;
  `}
  ${({ theme: { media } }) => media.desktop`
    width: 15%;
    height: 50px;
    margin: 20px;
  `}
`;

export const HeaderFooter = styled.div`
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  ${({ theme: { media } }) => media.desktop`
    width: 100%;
    height: 200px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
  `}
`;

export const Qualification2 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  background: #fff;
  border-radius: 1.3rem;
  padding: 10px 5px;
  ${({ theme: { media } }) => media.desktop`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    background: #fff;
    border-radius: 1.3rem;
  `}
`;

export const ContentFooterLeft = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  ${({ theme: { media } }) => media.desktop`
    width: 80%;
    display: flex;
    align-items: center;
    margin-left: 20px;
  `}
`;
export const ImageFooterLeft = styled.div`
  width: 100px;
  height: 80px;
  ${({ theme: { media } }) => media.tablet`
    width: 180px;
    height: 120px;
  `}
  ${({ theme: { media } }) => media.desktop`
    width: 135px;
    height: 135px;
  `}
`;

export const ImageLeft = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

export const ContentTitleLeft = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  margin-top: 10px;
  margin-left: 0.5rem;
  ${({ theme: { media } }) => media.tablet`
    justify-content: start;
    margin-top: 30px;
  `};
  ${({ theme: { media } }) => media.desktop`
    display: flex;
    flex-direction: column;
    justify-content: center;
  `};
`;

export const TitleHeader = styled(Monserrat12px600)`
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  text-transform: uppercase;
  color: #fff;
  ${({ theme: { media } }) => media.tablet`
    font-weight: 500;
    font-size: 18px;
    line-height: 5px;
    text-transform: uppercase;
    color: #fff;
  `};
  ${({ theme: { media } }) => media.desktop`
    font-weight: 500;
    font-size: 18px;
    line-height: 5px;
    text-transform: uppercase;
    color: #fff;
  `};
`;
export const SubTitleHeader = styled(Monserrat12px400)`
  font-weight: 500;
  font-size: 9px;
  line-height: 15px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #fff;
  max-height: 45px;
  width: 100%;
  white-space: wrap;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  ${({ theme: { media } }) => media.tablet`
    font-weight: 500;
    font-size: 14px;
    line-height: 15px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #fff;
  `};
  ${({ theme: { media } }) => media.desktop`
    font-weight: 500;
    font-size: 14px;
    line-height: 15px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #fff;
  `};
`;

export const PayButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const PayBill = styled.button`
  padding: 0 16px 0 16px;
  display: flex;
  width: 129px;
  justify-content: space-between;
  align-items: center;
  max-height: 58px;
  background: ${({ theme }) => theme.colors.themeApp.login};
  border: 1px solid ${({ theme }) => theme.colors.themeApp.login};
  border-radius: 9px;
  margin: 25px 0;
  :hover {
    background-color: #66add3;
  }
  ${({ theme: { media } }) => media.tablet`
  margin: 0;
  `};

  ${({ theme: { media } }) => media.desktop`
    padding: 0 16px 0 16px;
    display: flex;
    width: 269px;
    justify-content: space-between;
    align-items: center;
    height: 76px;
    background: {theme.colors.themeApp.login};
    border: 1px solid {theme.colors.themeApp.login};
    border-radius: 9px;
    margin: 0;
  `};
`;

export const Text = styled.p`
  margin: 0;
  font-family: "Montserrat", serif;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 20px;
  text-align: center;
  color: ${({ theme }) => theme.colors.themeApp.white};

  ${({ theme: { media } }) => media.tablet`

  `};

  ${({ theme: { media } }) => media.desktop`
    margin: 0;
    font-family: 'Montserrat',serif;
    font-style: normal;
    font-weight: 600;
    font-size: 23px;
    line-height: 30px;
    text-align: center;
    color: {theme.colors.themeApp.white};
  `};
`;

// styles for CustomOption component

export const ContainerOption = styled.div<{ currentSelected: boolean }>`
  border: 1px solid #e6e6e6;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  align-self: center;
  width: 80%;
  cursor: pointer;
  margin-bottom: 5px;
  background-color: ${({ currentSelected, theme }) =>
    currentSelected ? theme.colors.themeApp.loginBorderInput : ""};
`;

export const ContentOption = styled.div`
  width: 80%;
  height: 100%;
`;

export const Option = styled.p`
  color: ${({ theme }) => theme.colors.black};
  font-size: 21px;
  line-height: 40px;
  text-align: center;
  text-transform: capitalize;
  margin: 10px 0;
`;
