import { LOGO_VIVEMAS_HEADER } from "assets/image";

import SelectDepartment from "./SelectDepartment";
import UserAvatar from "./UserAvatar";
import UserMembershipLevel from "./UserMembershipLevel";
import {
  LogoPage,
  HeaderPage,
  DropdownPage,
  MenuIcon1,
  MinConteiner,
} from "./styles";

const Header = () => {
  return (
    <HeaderPage>
      <MinConteiner>
        <LogoPage>
          <MenuIcon1 src={LOGO_VIVEMAS_HEADER} alt="Logo ViveMas" />
        </LogoPage>
        <DropdownPage>
          <UserMembershipLevel />
          <SelectDepartment />
          <UserAvatar />
        </DropdownPage>
      </MinConteiner>
    </HeaderPage>
  );
};

export default Header;
