import { useEffect } from "react";
import { Link } from "react-router-dom";
import { searchClient } from "utils/contants";
import { Configure, Index, InstantSearch } from "react-instantsearch-dom";

import HomeCategories from "../HomeCategories/HomeCategories";
import { algoliaKeys } from "../../../../utils/contants";
import CustomSearch from "components/common/CustomSearch";
import { useViveSelector } from "hooks/reduxHooks";
import CustomCommerceHits from "../../../common/CustomCommerceHits";
import CustomBenefitHits from "components/common/CustomBenefitHits";
import { ARROW_LEF } from "../../../../assets/image";
import { useViveDispatch } from "../../../../hooks/reduxHooks";
import { _SET_SELECTED_CATEGORY_TAB } from "redux/actions/categoryAction";
import { useUserMembershipLevel } from "hooks/useMembershipLevel";
import { ContainerBenefitHits, ContenBenefit } from "components/common/styles";
import { pageView } from "utils/mixpanel";

import {
  Container,
  ContainerCommerceAlgolia,
  ContainerSearch,
  Content,
  IconsBack,
  ImageY,
  TitleSectionSearch,
} from "./styles";

const SearchAlgolia = () => {
  const dispatch = useViveDispatch();
  const { membershipLevel } = useUserMembershipLevel();
  const { id } = useViveSelector(
    (state) => state.categories.selectedTabCategory
  );
  const { id: departmantId } = useViveSelector(
    (state) => state.departments.selectedDepartment
  );
  const handleBack = () => {
    dispatch(
      _SET_SELECTED_CATEGORY_TAB({
        id: null,
        title: "",
      })
    );
  };

  useEffect(() => {
    pageView({
      PageType: "Search Algolia Web",
      Name: "Search Benefit Algolia Web",
      UserTime: new Date().toDateString(),
    });
  }, []);

  return (
    <Container>
      <Content>
        <InstantSearch
          searchClient={searchClient}
          indexName={algoliaKeys.indexList.benefits}
        >
          <ContainerSearch >
            <IconsBack>
              <Link to="/home" onClick={handleBack}>
                <ImageY src={ARROW_LEF} alt="Image arrow left" />
              </Link>
            </IconsBack>
            <CustomSearch />
          </ContainerSearch>
          <HomeCategories />
          <ContainerCommerceAlgolia >
            <TitleSectionSearch>COMERCIOS</TitleSectionSearch>

            <Index  indexName={algoliaKeys.indexList.commerces}>
              <Configure
                facetFilters={[
                  `categories.id:${id ? id : ""}`,
                  `benefits_departments.department_id:${
                    departmantId ? departmantId : 1
                  }`,
                  //`membership.level:${membershipLevel.level}`,
                ]}
                numericFilters={`membership.level: 0 TO ${membershipLevel.level}`}
                hitsPerPage={1000}
              />
              <CustomCommerceHits />
            </Index>
          </ContainerCommerceAlgolia>
          <ContainerBenefitHits>
            <TitleSectionSearch>BENEFICIOS</TitleSectionSearch>
            <ContenBenefit>
              <Index indexName={algoliaKeys.indexList.benefits}>
                <Configure
                  facetFilters={[
                    `category_id:${id ? id : ""}`,
                    `benefits_departments.department_id:${
                      departmantId ? departmantId : 1
                    }`,
                    //[`membership.level:${membershipLevel.level}`],
                  ]}
                  numericFilters={`membership.level: 0 TO ${membershipLevel.level}`}
                  hitsPerPage={1000}
                />
                <CustomBenefitHits />
              </Index>
            </ContenBenefit>
          </ContainerBenefitHits>
        </InstantSearch>
      </Content>
    </Container>
  );
};

export default SearchAlgolia;
