import { useEffect } from "react";
import { useViveSelector } from "hooks/reduxHooks";
import { IFeaturesItem } from "redux/redux.dto";

import { _GET_FEATURES } from "../../../../redux/actions/featureAction";
import HeaderSection from "../../../common/HeaderSection";
import { useViveDispatch } from "../../../../hooks/reduxHooks";
import { useUserMembershipLevel } from "../../../../hooks/useMembershipLevel";
import { TextEmpty } from "../HomeCategories/styles";

import {
  ContainerHomeFeatures,
  ContentSlide,
  RetailContainer,
  SliderContainer,
} from "./styles";
import Slide from "./Slide";

const HomeFeatures = () => {
  const { membershipLevel } = useUserMembershipLevel();
  const { features } = useViveSelector((state) => state.features);
  const { user } = useViveSelector((state) => state.user);
  const dispatch = useViveDispatch();

  useEffect(() => {
    if (membershipLevel.level || membershipLevel.level === 0) {
      dispatch(_GET_FEATURES(membershipLevel.level, user.department_id || "1"));
    }
  }, [dispatch, membershipLevel.level, user.department_id]);

  return (
    <ContainerHomeFeatures>
      <HeaderSection
        textLeft="destacados"
        textRight="Ver Destacados"
        screenView="/show-all-features"
      />
      {features.length === 0 && (
        <TextEmpty>no hay destacados para esta región.</TextEmpty>
      )}
      <SliderContainer>
        <RetailContainer>
          {features.length > 0
            ? features.map(({ id, path, benefit_id }: IFeaturesItem) => (
                <ContentSlide key={id}>
                  <Slide path={path} benefit_id={benefit_id} />
                </ContentSlide>
              ))
            : null}
        </RetailContainer>
      </SliderContainer>
    </ContainerHomeFeatures>
  );
};
export default HomeFeatures;
