import * as yup from "yup";
export const FormLogin = yup.object().shape({
  phone: yup
    .string()
    .matches(/^\w+$/, "No se aceptan letras")
    .min(10, "El número debe tener 10 dígitos")
    .required("*Escribe el número para continuar"),
});

export const validationSchema = yup.object().shape({
  first_name: yup
    .string()
    .required("*Nombre requerido")
    .min(3, "Nombre muy corto")
    .max(30, "Nombre muy largo"),
  second_name: yup
    .string()
    .min(3, "Nombre muy corto")
    .max(30, "Nombre muy largo")
      .nullable(),
  last_name: yup
    .string()
    .required("*Apellido requerido")
    .min(3, "Apellido muy corto")
    .max(30, "Apellido muy largo"),
  second_last_name: yup
    .string()
    .required("*Segundo Apellido requerido")
    .min(3, "*Segundo Apellido muy corto")
    .max(30, "*Segundo Apellido muy largo"),
  email: yup
    .string()
    .email("Correo electrónico inválido")
    .required("*Correo requerido"),
  identification_number: yup
    .string()
    .required("*Cédula requerida")
    .matches(/[0-9]+$/, "*Debe incluir sólo números")
    .min(7, "Cédula muy corta")
    .max(10, "Cédula muy larga"),
  nic: yup
    .string()
    .max(10, "*Máximo 10 números")
    .matches(/[0-9]+$/, "*Debe incluir sólo números")
    .required("*Nic es requerido"),
  landline: yup
    .string()
    .matches(/[0-9]+$/, "*Debe incluir sólo números")
    .max(10, "*Máximo 10 números").nullable(),
  customer_quality: yup
    .string()
    .ensure()
    .required("*Seleccione la calidad del cliente"),
  document_type: yup
    .string()
    .ensure()
    .required("*Seleccione su tipo de documento"),
  birthday_date: yup.string().ensure().required("*Fecha Nacimiento Requerido"),
  sex: yup.string().ensure().required("*Seleccione Sexo"),
});
