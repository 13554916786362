import styled from "styled-components";

export const OverallContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
  background-color: rgb(239, 239, 239);
  ${({ theme: { media } }) => media.tablet`
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
     width: 100%;
    height: 100vh;
   background-color: rgb(239,239,239);
  `};
  ${({ theme: { media } }) => media.desktop`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100vh;
    background-color: rgb(239,239,239);
  `};
`;
export const HeaderImage = styled.div`
  width: 100%;
  height: 141px;
  display: flex;
  flex-direction: column;
  ${({ theme: { media } }) => media.tablet`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
  `};
  ${({ theme: { media } }) => media.desktop`
    width: 1000px;
    height: auto;
    display: flex;
    flex-direction: column;
  `};
`;

export const ArrowLeft = styled.div`
  display: flex;
  justify-content: start;
  align-items: start;
  margin-left: 25px;
  padding: 4px 0;
  margin-top: 65px;
  position: absolute;
  object-fit: cover;
  z-index: 800;

  ${({ theme: { media } }) => media.tablet`
    display: none;
  `};
  ${({ theme: { media } }) => media.desktop`
    display: none;  
  `};
`;

export const TextIcon = styled("h2")`
  display: flex;
  justify-content: start;
  flex-direction: row;
  align-items: start;
  margin-left: -350px;
  margin-top: -160px;

  ${({ theme: { media } }) => media.desktop`
display: flex;
  justify-content: start;
  flex-direction: row;
  align-items: start;
  margin-left: 60px;
  margin-top: -40px;
  z-index:1;
  `};
`;
export const Vino = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  object-fit: cover;
  height: 160px;
  z-index: 10;
  position: relative;
  ${({ theme: { media } }) => media.tablet`
    display: flex;
    justify-content: start;
    align-items: center;
    width: 100%;
    height: 300px;
  `};
  ${({ theme: { media } }) => media.desktop`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 400px;
  `};
`;

export const ImageY = styled.img`
  width: 100%;
  height: 100%;
  filter: invert(100%);
`;
export const ImageCommerce = styled.img`
  border-radius: 100px;
  width: 60.28px;
  height: 60.28px;
  ${({ theme: { media } }) => media.tablet`
    border-Radius: 1000px;
    width: 150px;
      height: auto;
  `};
  ${({ theme: { media } }) => media.desktop`
      border-radius: 100px;
      width: 200px;
      height: 200px;
  `};
`;
export const Text = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  margin-top: 10px;
  margin-left: 14px;
  ${({ theme: { media } }) => media.tablet`
 justify-content: start;
  margin-top: 30px;
  margin-left: 32px;
  `};
  ${({ theme: { media } }) => media.desktop`
 display: flex;
  flex-direction: column;
  justify-content: start;
  margin-top:50px;
  margin-left: 32px;
  `};
`;
export const H6 = styled.h2`
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 12px;
  line-height: 15px;
  color: white;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  ${({ theme: { media } }) => media.tablet`
 font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 37px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  margin-right: -40px;
   color:black;
  `};
  ${({ theme: { media } }) => media.desktop`
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 49px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #000000;
  `};
`;
export const H7 = styled.h2`
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  color: white;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  ${({ theme: { media } }) => media.tablet`
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 16px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #000000;
  `};
  ${({ theme: { media } }) => media.desktop`
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #000000;
  `};
`;
export const Qualification = styled("h2")`
  border-radius: 100px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: right;
  position: absolute;
  ${({ theme: { media } }) => media.desktop`
  width: 103px;
  height: 40px;
  margin-top: -20px;
  margin-left: 820px;
  `};
`;
export const PunctualRating = styled("h2")`
  width: 40%;
  font-size: 20px;
  height: 20px;
  ${({ theme: { media } }) => media.desktop`

  `};
`;
export const ImageContent = styled("h2")`
  width: 20%;
  height: 16px;
  margin-top: 0;
  ${({ theme: { media } }) => media.tablet`
  width: 20%;
  height: 16px;
  margin-top: 0;
  `};
  ${({ theme: { media } }) => media.desktop`
  width: 30%;
  height: 26px;
  margin-top: -35px;
  `};
`;

export const ContainerSectionTwo = styled.div`
  width: 100vw;
  border-radius: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;

  ${({ theme: { media } }) => media.tablet`
    width: 100vw;
    border-radius: 20px;
      margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items: center;
    margin-top:40px;
  `};
  ${({ theme: { media } }) => media.desktop`
    width: 1000px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items: center;
    margin-top:40px;
  `};
`;

export const InformationConteinerOver = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  ${({ theme: { media } }) => media.tablet`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 760px;
    margin-right: -10px;
    padding:0 20px;
  `};
  ${({ theme: { media } }) => media.desktop`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    padding: 0;
  `};
`;
export const InformationConteinerOver2 = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 0;
  width: 100%;
  align-items: center;
  margin-left: 0;
  overflow-x: hidden;
  overflow-y: hidden;
  padding: 0 10px;
  & :hover {
    cursor: pointer;
  }

  ::-webkit-scrollbar {
    background: #e1e1e1;
    height: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background: #b9b9b9;
    height: 5px;
    border-radius: 30px;
  }
  ${({ theme: { media } }) => media.tablet`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 13px;
    margin-top: 0;
    width: 770px;
    overflow-x: scroll;
    overflow-y: hidden;
    margin-left: 10px;
    justify-content: start;
    & :hover {
      cursor: pointer;
    }

    ::-webkit-scrollbar {
      background: #e1e1e1;
      height: 5px;
    }

    ::-webkit-scrollbar-thumb {
      background: #b9b9b9;
      height: 5px;
      border-radius: 30px;
    }
  `};
  ${({ theme: { media } }) => media.desktop`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 13px;
    align-items: center;
    width: 1000px;
    padding-top: 20px;
    & :hover {
      cursor: pointer;
    }

    ::-webkit-scrollbar {
      background: #e1e1e1;
      height: 5px;
    }

    ::-webkit-scrollbar-thumb {
      background: #b9b9b9;
      height: 5px;
      border-radius: 30px;
    }
  `};
`;

export const ContainerTwoS = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-left: 15px;
  width: 100%;
  ${({ theme: { media } }) => media.tablet`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 40px;
  `};
  ${({ theme: { media } }) => media.desktop`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 20px 0;
    padding: 0;
  `};
`;
export const ContainerTreeS = styled.div`
  display: flex;
  flex-direction: column;
  margin: 40px 0;
  width: 350px;

  ${({ theme: { media } }) => media.tablet`
    display: flex;
    flex-direction: row;
    width: 252px;
    align-items: start;
    justify-content: start;
    margin-top: 0;
  `};
  ${({ theme: { media } }) => media.desktop`
    display: flex;
    flex-direction: row;
    width: 360px;
    align-items: flex-start;
    justify-content: center;
    margin-top: 10px;
  `};
`;

export const CategoryTabs = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  & :hover {
    cursor: pointer;
  }

  ${({ theme: { media } }) => media.tablet`
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: start;
    margin-bottom: 37px;
  `};
  ${({ theme: { media } }) => media.desktop`
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    margin-bottom: -10px;
      
  `};
`;
export const ButtonTwo = styled.div`
  box-sizing: border-box;
  width: 164px;
  height: 40px;
  border: 1px solid #8c8c8c;
  border-radius: 6px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  margin-left: 15px;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: #000000;
  padding-right: 20px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  ${({ theme: { media } }) => media.tablet`
  box-sizing: border-box;
  width: 252px;
  height: 70px;
    margin-left: 0;
  border-radius: 6px;
       line-height: 21px;
  
  `};
  ${({ theme: { media } }) => media.desktop`
    box-sizing: border-box;
    width: 352px;
    height: 80px;
    border: 1px solid #8C8C8C;
    border-radius: 6px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    margin-left: 0;
    font-size: 23px;
    line-height: 30px;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #000000;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
      
  `};
`;
export const Stelas = styled.div`
  width: 24.52px;
  height: 22.06px;

  ${({ theme: { media } }) => media.tablet`
 width: 38.76px;
height: 34.88px;
  `};
  ${({ theme: { media } }) => media.desktop`
  width: 49.24px;
height: 44.32px;
      
  `};
`;

export const ImageBackground = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 0 0 10px 10px;
  object-fit: cover;
  position: relative;
  ${({ theme: { media } }) => media.tablet`
    width: 100%;
    height: 100%;
    border-radius: 0 0 20px 20px;
    object-fit: cover;
  `};
  ${({ theme: { media } }) => media.desktop`
    width: 100%;
    height: 100%;
    border-radius: 0 0 20px 20px;
    object-fit: cover;
    margin: 0;
    padding: 0;
  `};
`;
