import {
  InfoBenefitDetailBottomItem,
  TextDetail,
  TextDetailInfo,
  InfoBenefitDetailBottom,
} from "./styles";

type BenefitDetailFooterProps = {
  detail: string;
  benefit_channel: string;
  terms: string;
};

const BenefitDetailFooter = ({
  detail,
  benefit_channel,
  terms,
}: BenefitDetailFooterProps) => {
  return (
    <InfoBenefitDetailBottom>
      <InfoBenefitDetailBottomItem>
        <TextDetail>¿Detalles?</TextDetail>
        <TextDetailInfo>{detail ? detail : ""}</TextDetailInfo>
      </InfoBenefitDetailBottomItem>
      <InfoBenefitDetailBottomItem>
        <TextDetail>¿Condiciones y Restricciones?</TextDetail>

        <TextDetailInfo>{terms ? terms : ""}</TextDetailInfo>
      </InfoBenefitDetailBottomItem>

      <InfoBenefitDetailBottomItem>
        <TextDetail>¿Cómo usar este cupón?</TextDetail>

        {benefit_channel === "WHATSAPP" && (
          <TextDetailInfo>
            Utiliza el botón de Whatsapp para acceder el beneficio y contactar
            al comercio.
          </TextDetailInfo>
        )}
        {benefit_channel === "IN_PLACE" && (
          <TextDetailInfo>
            Informa el código al comercio para acceder al beneficio. Renueva el
            código de ser necesario.
          </TextDetailInfo>
        )}
        {benefit_channel === "WEB" && (
          <TextDetailInfo>
            Utiliza el botón web para acceder al beneficio. De ser necesario
            ingresa el código del cupón en el momento indicado.
          </TextDetailInfo>
        )}
      </InfoBenefitDetailBottomItem>
    </InfoBenefitDetailBottom>
  );
};

export default BenefitDetailFooter;
