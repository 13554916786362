import { Spin } from "antd";
import { ICON_COL } from "assets/icons";
import {Monserrat12px500, Monserrat21px500} from "components/typography/typographt";
import styled from "styled-components";
import { colors } from "themes/light/colors";
import background from "../../../assets/Inicio de sesión.png";

export const OverContainer = styled.section`
  background-image: url(${background});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100vw;
  ${({ theme: { media } }) => media.tablet`
     align-items: center;
     background:#0177B6;
       height: 100vh;
  `};
  ${({ theme: { media } }) => media.desktop`
    align-items: center;
      background:#0177B6;
  `};
`;
export const LoginPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center; 
  & .simple-form {
    width: 465px;
    border-radius: 20px;
    padding: 89px 39px 66px 39px !important;
    ${({ theme: { media } }) => media.tablet`
    width: 465px;
    height: 486px;
    margin-top: 31px;
  `};
    ${({ theme: { media } }) => media.desktop`
      width: 425px;
    height: 426px;
      margin-top: 44px;
  `};
`;

export const ImageLogo = styled.img`
  width: 146px;
  height: 95px;
  object-fit: cover;
  ${({ theme: { media } }) => media.tablet`
   width: 166px;
  height: 105px;
  `};
  ${({ theme: { media } }) => media.desktop`
  width: 166px;
  height: 110px;
  `};
`;

export const ContainerImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 100px;
  width: 100%;
  height: 285px;
  ${({ theme: { media } }) => media.tablet`
      width: 206px;
      height: 135px;
       padding-top: 0;
  `};
  ${({ theme: { media } }) => media.desktop`
      width: 206px;
      height: 135px;
       padding-top: 0;
  `};
`;

export const ContainerForm = styled.div`
  width: 100%;
  height: 366px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  background: white;
  border-radius: 20px 20px 0 0;
  padding: 26px 23px;
  ${({ theme: { media } }) => media.tablet`
    width: 425px;
    height: 456px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
      padding: 66px 37px;
       border-radius: 20px 20px 20px 20px;
  `};
  ${({ theme: { media } }) => media.desktop`
    width: 445px;
    height: 456px;
    display: flex;
    flex-direction: column;
    align-items: center;
           border-radius: 20px 20px 20px 20px;
    justify-content: space-around;
    padding: 66px 37px;
  `};
`;

export const FormTitle = styled(Monserrat21px500)`
  color: #000000;
  width: 260px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ theme: { media } }) => media.tablet`
 width: 388px;
  font-size: 18px;
    `};
  ${({ theme: { media } }) => media.desktop`
 width: 368px;
font-size: 19px;
    `};
`;
export const ContenInput = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  width: 90%;
  height: 52px;
  margin-right: 5px;
  border-radius: 8px;
  border: 1px solid #cdcdcd;
  ${({ theme: { media } }) => media.tablet`
  width: 90%;
  height: 58px;
  border-radius: 8px;
    margin-right: 0;
  border: 1px solid #cdcdcd;
    `};
  ${({ theme: { media } }) => media.desktop`
  width: 90%;
  height: 60px;
    margin-right: 0;
  border-radius: 8px;
  border: 1px solid #cdcdcd;
  `};
`;

export const InputLeft = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 25%;
  padding-left: 10px;
  height: 100%;
  ${({ theme: { media } }) => media.tablet`
 display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 25%;
  padding-left: 10px;
  height: 100%;
  `};
  ${({ theme: { media } }) => media.desktop`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 25%;
    padding-left:10px ;
  height: 100%;
  `};
`;

export const ImageLeft = styled.img.attrs({
  src: ICON_COL,
})`
  width: 16px;
  height: 16px;
  margin-left: 10px;
  object-fit: cover;
  ${({ theme: { media } }) => media.tablet`
   width: 18px;
  height: 18px;
  `};
  ${({ theme: { media } }) => media.desktop`
   width: 20px;
  height: 20px;
  `};
`;
export const CountryCode = styled(Monserrat21px500)`
  margin: 0 10px;
  font-size: 16px;
  color: #000000;
  ${({ theme: { media } }) => media.tablet`
  font-size: 17px;
  `};
  ${({ theme: { media } }) => media.desktop`
  font-size: 18px;
  `};
`;

export const Separator = styled.div<{ height: string; width: number }>`
  width: 1px;
  background: #cdcdcd;
  height: 90%;
`;
export const LoginInput = styled.input.attrs({
  type: "tel",
})`
  width: 90%;
  height: 80%;
  padding: 0 10px;
  outline: none;
  border: none;
  font-size: 16px;
  font-weight: 500;
  color: #000000;
  &::placeholder {
    color: #cdcdcd;
  }
  &:focus {
    border-color: #cdcdcd;
  }
  ${({ theme: { media } }) => media.tablet`
    font-size: 18px;
  `};
  ${({ theme: { media } }) => media.desktop`
    font-size: 19px;
  `};
`;

export const LoginButton = styled.button`
  width: 90%;
  height: 58px;
  background: #006abf;
  border-radius: 8px;
  border: none;
  outline: none;
  font-size: 16px;
  line-height: 26px;
  font-weight: 600;
  color: #ffffff;
  cursor: pointer;
  ${({ theme: { media } }) => media.tablet`
    width: 90%;
    font-size: 18px;
    height: 58px;
    background: #006abf;
    border-radius: 8px;
  `};
  ${({ theme: { media } }) => media.desktop`
    width: 90%;
    height: 58px;
    font-size: 19px;
    background: #006abf;
    border-radius: 8px;
  `};
`;

export const TextError = styled.p`
  color: red;
  font-size: 15px;
  font-family: "Montserrat", sans-serif;
`;

export const Loader = styled(Spin).attrs({
  delay: 400,
  size: "large",
  tip: "Loading...",
})`
  color: ${colors.white};
`;

export const ContentTerms = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FragmentInput = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

//Term and conditions
export const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 13px;
`;
export const TextPreview = styled(Monserrat12px500)`
  font-size: 13px;
  line-height: 15px;
  color: black;
  margin-right: 2px;
`;
export const TextPrivacity = styled.span`
  text-decoration: underline;
  cursor:pointer;
  color: rgb(85, 26, 139);
`;
