import React from "react";
import { OverallContainer } from "./styles";

import HeaderProfile from "./HeaderProfile";
import FormInputsProfile from "./FormInputsProfile";

const CompleteProfile: React.FC = () => {
  return (
    <OverallContainer>
      <HeaderProfile title="Completar Perfil" />
      <FormInputsProfile />
    </OverallContainer>
  );
};

export default CompleteProfile;
