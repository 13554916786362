import { useState } from "react";
import ReactCodeInput from "react-code-input";
import { verificationOtp } from "api/authOtp";
import { PageHeader } from "antd";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import { _USER_INFO_OTP } from "redux/actions/userAction";
import { useViveDispatch } from "../../../hooks/reduxHooks";

import {
  Container,
  Verification,
  Icons,
  VerificationCodeText,
  ContainerVerify,
  VerifyCode,
  ImageY,
} from "./styles";
import { startSesion } from "utils/mixpanel";

const VerificationCode = () => {
  const dispatch = useViveDispatch();
  const navigate = useNavigate();
  const [otp, setOtp] = useState<string>("");

  const handleSubmit = async () => {
    try {
      if (!otp.trim())
        return toast.info("Debes ingresar tu código para continuar", {
          position: "top-center",
          autoClose: 3500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

      if (otp.length < 4)
        return toast.info("El código es de 4 digitos", {
          position: "top-center",
          autoClose: 3500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      const response = await verificationOtp({ code: otp });
      await dispatch(_USER_INFO_OTP(response.data));
      //startSesion function regitry in mixpanel event when the user session start
      startSesion();
      navigate("/home", { replace: true });
    } catch (err: any) {
      toast.error("Opps! El código no es válido o ha expirado", {
        position: "top-center",
        autoClose: 3500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setOtp((prev) => "");
      console.log("error try verification code", err);
    }
  };

  return (
    <Container>
      <PageHeader
        className="site-page-header"
        onBack={() => navigate(-1)}
        title="Verificación"
      />
      <Icons>
        <ImageY />
      </Icons>
      <Verification>
        Por favor ingresa el código enviado a tu célular.
      </Verification>
      <VerificationCodeText>
        El código enviado es para uso personal. Evite compartirlo con otras
        personas
      </VerificationCodeText>

      <ReactCodeInput
        type="tel"
        fields={4}
        inputMode="numeric"
        value={otp}
        onChange={(otp) => setOtp(otp)}
        name="VerificationCode"
        autoFocus
        inputStyle={{
          width: 40,
          height: 40,
          margin: "0 10px",
          fontSize: 22,
          borderRadius: 8,
          outline: "none",
          border: "1px solid gray",
          textAlign: "center",
        }}
      />
      <ContainerVerify>
        <VerifyCode onClick={handleSubmit}>Verificar código</VerifyCode>
      </ContainerVerify>
    </Container>
  );
};

export default VerificationCode;
