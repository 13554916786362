import mixpanel from "mixpanel-browser";

type PageViewProps = {
  PageType: string;
  Name: string;
  UserTime: string;
};

type UpdateUserProfile = {
  name: string;
  lastName: string;
  identification: string;
  document_type: string;
  nic: string;
  sex: string;
  email: string;
  birthday_date: string;
  role: string;
};

type PressBannerBenefitProps = {
  Name: string;
  Price: string;
  Type: string;
  Details: string;
  Department: string;
  Membership: {
    name: string;
  };
  commerce: {
    name: string;
    description: string;
    allow_pets: boolean;
    allow_minors: boolean;
    allow_consume_in_site: boolean;
  };
};
type ChangeLocation = {
  OldLocation: string;
  NewLocation: string;
};

type BenefitObtain = {
  title: string;
  detail: string;
  membership: {
    name: string;
  };
  commerce: {
    name: string;
    description: string;
    allow_pets: boolean;
    allow_minors: boolean;
    allow_consume_in_site: boolean;
  };
  benefit_channel: string;
  actual_price: number;
};

type BenefitObtainWhatsapp = {
  id: string;
  code: string;
  title: string;
  whatsapp: string;
};

type BenefitObtainWeb = {
  id: string;
  title: string;
  code: string;
  user_id: string;
  benefit_channel: string;
  source_code: string;
  url: string;
};

type IdentifyProps = {
  id: string;
  avatar: string;
  email: string;
  phone: string;
  first_name: string;
  last_name: string;
  terms_and_conditions: string;
  identification_number: string;
  membership_id: string;
  application_id: string;
  customer_quality: string;
  nic: string;
  department_id: string;
};

export const identify = (user: IdentifyProps, department_name: string) => {
  mixpanel.identify(`${user.id}`);
  mixpanel.people.set({
    $avatar: user.avatar,
    $email: user.email,
    $phone: user.phone,
    $first_name: user.first_name,
    $user_name: `${user.first_name} ${user.last_name}`,
    $last_name: user.last_name,
    $terms_and_conditions: user.terms_and_conditions,
    $identification_number: user.identification_number,
    $membership_id: user.membership_id,
    $application_id: user.application_id,
    $customer_quality: user.customer_quality,
    $nic: user.nic,
    $department_id: user.department_id,
    $departament: department_name || "Atlántico",
  });
};

export const pageView = (data: PageViewProps) => {
  mixpanel.track("PAGE_VIEW", { ...data });
};

export const updateUserProfile = (data: UpdateUserProfile) => {
  mixpanel.track("USER_PROFILE_UPDATE", {
    Name: `${data.name} ${data.lastName}`,
    IdentificationNumber: data.identification,
    IdentificationType: data.document_type,
    NIC: data.nic,
    Gender: data.sex,
    Email: data.email,
    BirthDate: data.birthday_date,
    Role: data.role,
  });
};

export const changeLocation = (data: ChangeLocation) => {
  mixpanel.track("CHANGE_LOCATION", { ...data });
};

export const benefitObtain = (rest: BenefitObtain, userDepartment: string) => {
  mixpanel.track("BENEFIT_OBTAIN", {
    Name: rest.title,
    Details: rest.detail,
    Department: userDepartment,
    Membership: rest.membership.name,
    Commerce: {
      CommerceName: rest.commerce.name,
      CommerceDescription: rest.commerce.description,
      CommerceAllowPets: rest.commerce.allow_pets,
      CommerceAllowMinors: rest.commerce.allow_minors,
      CommerceConsumeInSite: rest.commerce.allow_consume_in_site,
    },
    Type: rest.benefit_channel,
    Price: rest.actual_price,
  });
};

export const benefitObtainWhatsapp = (rest: BenefitObtainWhatsapp) => {
  mixpanel.track("BENEFIT_OBTAIN", {
    id: rest.id,
    code: rest.code,
    Name: rest.title,
    whatsapp: rest.whatsapp,
  });
};

export const benefitObtainWeb = (rest: BenefitObtainWeb) => {
  mixpanel.track("BENEFIT_OBTAIN", {
    id: rest.id,
    Name: rest.title,
    code: rest.code,
    user_id: rest.user_id,
    benefit_channel: rest.benefit_channel,
    source_code: rest.source_code,
    url: rest.url,
  });
};

export const benefitRenew = () => {
  mixpanel.track("BENEFIT_RENEW");
};

export const pressBannerBenefit = (rest: PressBannerBenefitProps) => {
  mixpanel.track("PRESS_BANNER_BENEFIT_WEB", {
    Name: rest.Name,
    Price: rest.Price,
    Type: rest.Type,
    Details: rest.Details,
    Department: rest.Department,
    Membership: {
      name: rest.Membership.name,
    },
    Commerce: {
      CommerceName: rest.commerce.name,
      CommerceDescription: rest.commerce.description,
      CommerceAllowPets: rest.commerce.allow_pets,
      CommerceAllowMinors: rest.commerce.allow_minors,
      CommerceConsumeInSite: rest.commerce.allow_consume_in_site,
    },
  });
};

export const startSesion = () => {
  console.log("sesion start");
  mixpanel.track("SESSION_START", { type: "Web" });
};
