import { Image, PressableShowCode, PressableText } from "./styles";

type PressableShowModalProps = {
  onClick: () => void;
};

const PressableShowModal = ({ onClick }: PressableShowModalProps) => {
  return (
    <PressableShowCode onClick={() => onClick()}>
      <Image />
      <PressableText>Mostrar Código De Beneficio </PressableText>
    </PressableShowCode>
  );
};

export default PressableShowModal;
