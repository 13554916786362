import { useNavigate } from "react-router-dom";
import {
  ContentHeaderSection,
  TextLeft,
  TextPressable,
  TextRight,
} from "./styles";

interface HeaderSectionProps {
  textLeft?: string;
  screenView?: string | undefined;
  textRight?: string;
}

const HeaderSection = ({
  textLeft,
  screenView,
  textRight,
}: HeaderSectionProps) => {
  const navigation = useNavigate();
  const handleScreen = (toScreen: string | undefined) => {
    navigation(`${toScreen}`);
  };
  return (
    <ContentHeaderSection>
      <TextLeft>{textLeft}</TextLeft>
      <TextPressable onClick={() => handleScreen(screenView)}>
        <TextRight>{textRight}</TextRight>
      </TextPressable>
    </ContentHeaderSection>
  );
};

export default HeaderSection;
