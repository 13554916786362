import { Fragment } from "react";
import { CHECK } from "assets/icons";
import { AllowItem, ConsumerIcon, TextInfoconsume } from "./styles";

interface IpropsAllows {
  title: string;
  allow: boolean;
}

const Allows = ({ title, allow }: IpropsAllows) => {
  return (
    <Fragment>
      {allow && (
        <AllowItem>
          <ConsumerIcon src={allow ? CHECK : null} />
          <TextInfoconsume>{title}</TextInfoconsume>
        </AllowItem>
      )}
    </Fragment>
  );
};

export default Allows;
