//LOGIN
export const SIGN_IN = "SIGN_IN";

//USER
export const USER_INFO_OTP = "USER_INFO_OTP";
export const USER_INFO_OTP_ERROR = "USER_INFO_OTP_ERROR";
export const VERIFY_USER_LOGGED = "VERIFY_USER_LOGGED";
export const UPDATE_STATE_USER_INFO = "UPDATE_STATE_USER_INFO";
export const USER_LOGOUT = "USER_LOGOUT";
export const GUEST_USER = "GUEST_USER";
export const USER_MEMBERSHIPS = "USER_MEMBERSHIPS";
export const DISMISS_MODAL_DOWNLOAD = "DISMISS_MODAL_DOWNLOAD";

//CATEGORIES
export const GET_CATEGORIES = "GET_CATEGORIES";
export const SET_SELECTED_CATEGORY_TAB = "SET_SELECTED_CATEGORY_TAB";

//TOKEN
export const SET_ID_TOKEN = "SET_ID_TOKEN";

//COMMERCES
export const GET_COMMERCES = "GET_COMMERCES";
export const GET_COMMERCES_BY_ID = "GET_COMMERCES_BY_ID";
export const SET_IS_LOADING_DETAIL_COMMERCE = "SET_IS_LOADING_DETAIL";
export const GET_BENEFITS_COMMMERCE_BY_ID = "GET_BENEFITS_COMMMERCE_BY_ID";
export const GET_SCORES_BENEFIT = "GET_SCORES_BENEFIT";
export const CLEAN_INFO_COMMERCE = "CLEAN_INFO_COMMERCE";
export const GET_ALL_COMMMERCES = "GET_ALL_COMMMERCES";
export const CLEAN_COMMMERCES = "CLEAN_COMMMERCES";

//BENEFITS
export const GET_BENEFITS = "GET_BENEFITS";
export const GET_BENEFITS_BY_ID = "GET_BENEFITS_BY_ID";
export const SET_IS_LOADING_DETAIL_BENEFIT = "SET_IS_LOADING_DETAIL";
export const GET_CODE_BENEFIT = "GET_CODE_BENEFIT";
export const GET_CODE_BENEFIT_ERROR = "GET_CODE_BENEFIT_ERROR";
export const GET_SCORE_COMMERCE_BY_ID = "GET_SCORE_COMMERCE_BY_ID";
export const CLEAM_CODE_BENEFIT = "CLEAM_CODE_BENEFIT";
export const CLEAM_ERROR_BENEFIT_CODE = "CLEAM_ERROR_BENEFIT_CODE";
export const CLEAM_INFO_BENEFIT = "CLEAM_INFO_BENEFIT";
export const CLEAN_BENEFITS = "CLEAN_BENEFITS";
export const SET_LOADER_BENEFIT_DETAIL = "SET_LOADER_BENEFIT_DETAIL";

//FEATURES
export const GET_FEATURES = "GET_FEATURES";
export const GET_ALL_FUEATURES = "GET_ALL_FUEATURES";

//DEPARTMENTS
export const GET_DEPARTMENTS = "GET_DEPARTMENTS";
export const SET_SELECTED_DEPARTMENT = "SET_SELECTED_DEPARTMENT";
export const SET_CURRENT_DEPARTMENT = "SET_CURRENT_DEPARTMENT";
export const SET_VISIBLE_DEPARTMENT_MANDATORY =
  "SET_VISIBLE_DEPARTMENT_MANDATORY";
