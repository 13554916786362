import styled from "styled-components";

//12PX
export const Monserrat12px500 = styled.p`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  line-height: 14.63px;
  font-size: 12px;
`;

export const Monserrat12px600 = styled.p`
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 600;
  line-height: 15px;
`;

export const Monserrat12px400 = styled.p`
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
`;

//10PX
export const Monserrat10px500 = styled.p`
  font-family: Montserrat;
  font-size: 10px;
  font-weight: 500;
  line-height: 12px;
  letter-spacing: 0.5px;
`;

export const Monserrat10px400 = styled.p`
  font-family: Montserrat;
  font-size: 10px;
  font-weight: 400;
  line-height: 12.19px;
  letter-spacing: 0.5px;
`;

//39PX
export const Monserrat39px700 = styled.p`
  font-family: Montserrat;
  font-size: 39px;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: 0.2px;
  p-align: center;
`;

//VIVEMAS WEBAPP
//21px
export const Monserrat21px500 = styled.h2`
  font-family: Montserrat;
  font-size: 21px;
  font-weight: 500;
  line-height: 26px;
`;

//20PX
export const Monserrat20x500 = styled.p`
  font-family: Montserrat;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
`;
