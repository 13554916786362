import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useViveDispatch } from "hooks/reduxHooks";

import {
  _CLEAN_CODE_BENEFIT,
  _CLEAN_ERROR_BENEFIT,
  _CLEAN_INFO_BENEFIT,
  _GET_BENEFITS_BY_ID,
  _GET_CODE_BENEFIT,
  _GET_SCORE_COMMERCE,
  _SET_LOADER_BENEFIT_DETAIL,
} from "redux/actions/benefitAction";
import { useViveSelector } from "../../../hooks/reduxHooks";
import HeaderDetail from "components/common/HeaderDetail";
import LoaderApp from "components/loader/LoaderApp";

import ContainerTwo from "./ContainerTwo";
import { OverallContainer } from "./styles";

const BenefitDetail = () => {
  const { benefitId } = useParams();
  const dispatch = useViveDispatch();

  const { selectedBenefit, isLoadingDetail, numberReviews } = useViveSelector(
    (state) => state.benefits
  );

  useEffect(() => {
    if (benefitId) {
      dispatch(_GET_BENEFITS_BY_ID(benefitId));
    }
    return () => {
      dispatch(_SET_LOADER_BENEFIT_DETAIL());
      dispatch(_CLEAN_CODE_BENEFIT());
      dispatch(_CLEAN_ERROR_BENEFIT());
      dispatch(_CLEAN_INFO_BENEFIT());
    };
  }, [benefitId, dispatch]);

  useEffect(() => {
    dispatch(_GET_SCORE_COMMERCE(selectedBenefit.commerce.id)).then(() => {});
  }, [dispatch, selectedBenefit.commerce.id]);

  useEffect(() => {
    if (benefitId && selectedBenefit.benefit_channel === "WHATSAPP") {
      dispatch(_GET_CODE_BENEFIT(benefitId));
    }
  }, [benefitId, dispatch, selectedBenefit.benefit_channel]);

  if (isLoadingDetail) return <LoaderApp />;
  console.log(numberReviews.score)
  return (
    <OverallContainer>
      <HeaderDetail
        banner_image={selectedBenefit.commerce.banner_image}
        description={selectedBenefit.commerce.description}
        image={selectedBenefit.commerce.image}
        name={selectedBenefit.commerce.name}
        score={numberReviews.score}
      />
      <ContainerTwo benefitId={benefitId!} />
    </OverallContainer>
  );
};

export default BenefitDetail;
