import styled from "styled-components";

export const ContainerIndexGlobal = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  background-color: hsl(0, 0, 0, 0.9);
  margin-top: 55px;
  ${({ theme: { media } }) => media.largeMobile`
     height: 100%;
  `};
  ${({ theme: { media } }) => media.tablet`
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    width: 100%;
    height: 100vh;
    background-color: hsl(0, 0, 0, 0.9);;
    padding: 0 20px;
    margin-top: 25px;
  `};
  ${({ theme: { media } }) => media.desktop`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: hsl(0, 0, 0, 0.9);
    margin-top:50px;
    padding: 0 20px;
  `};
`;

export const Footer = styled.div`
  display: none;
  ${({ theme: { media } }) => media.tablet`
    display: none;
  `};
  ${({ theme: { media } }) => media.desktop`
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
  `};
`;
