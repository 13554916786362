import "swiper/css";
import { PageHeader } from "antd";
import { HeaderCompleteProfile } from "./styles";
import { useNavigate } from "react-router-dom";
type HeaderProfileProps = {
  title: string;
};
const HeaderProfile = ({ title }: HeaderProfileProps) => {
  const navigate = useNavigate();
  return (
    <HeaderCompleteProfile>
      <PageHeader
        className="site-page-header"
        onBack={() => navigate(-1)}
        title={title || ""}
      />
    </HeaderCompleteProfile>
  );
};

export default HeaderProfile;
