import "swiper/css";
import { Link } from "react-router-dom";

import { ARROW_LEF } from "../../assets/image";
import { ICON_PRIME } from "assets/icons";

import { imageHandler } from "utils/imageHandlers";
import { S3_CREDENTIAL } from "utils/contants";
import { ImageY } from "components/pages/CommerceDetail/styles";

import { ContainerBack, ContainerBackArrow, ContainerHeader, ContentFooterLeft, ContentTitleLeft, HeaderFooter, HeaderImage2, ImageContent, ImageFooterLeft, ImageLeft, PunctualRating, Qualification2, SubTitleHeader, TitleHeader } from "./styles";

type HeaderDetailProps = {
  banner_image: any;
  name: string;
  image: any;
  description: string;
  score: number;
};
const HeaderDetail = ({
  banner_image,
  description,
  image,
  name,
  score,
}: HeaderDetailProps) => {
  return (
    <ContainerHeader>
      <HeaderImage2
        backImage={
          banner_image
            ? imageHandler(banner_image)
            : `${S3_CREDENTIAL.baseUrl}/placeholders/discount.png`
        }
      >
        <ContainerBackArrow>
          <Link to="/home">
            <ContainerBack>
              <ImageY src={ARROW_LEF} alt="Image arrow left" />
            </ContainerBack>
          </Link>
        </ContainerBackArrow>
        <HeaderFooter>
          <ContentFooterLeft>
            <ImageFooterLeft>
              <ImageLeft
                src={`${
                  image
                    ? imageHandler(image)
                    : `${S3_CREDENTIAL.baseUrl}/placeholders/commerce.png`
                }`}
                alt="Image commerce"
              />
            </ImageFooterLeft>
            <ContentTitleLeft>
              <TitleHeader>{name || ""}</TitleHeader>
              <SubTitleHeader>{description || ""}</SubTitleHeader>
            </ContentTitleLeft>
          </ContentFooterLeft>
          <Qualification2>
            <PunctualRating>{score ? Number(+score).toFixed(1) : 0}/5.0</PunctualRating>
            <ImageContent>
              <img
                style={{ height: "100%" }}
                src={ICON_PRIME}
                alt="icon prime score"
              />
            </ImageContent>
          </Qualification2>
        </HeaderFooter>
      </HeaderImage2>
    </ContainerHeader>
  );
};

export default HeaderDetail;
