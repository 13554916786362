import { ActionReturnProps } from "redux/redux.dto";
import { IBenefitsReducer } from "../interfaces";
import lodash from "lodash";
import {
  CLEAM_CODE_BENEFIT,
  CLEAM_ERROR_BENEFIT_CODE,
  CLEAM_INFO_BENEFIT,
  GET_CODE_BENEFIT_ERROR,
  GET_SCORE_COMMERCE_BY_ID,
  GET_BENEFITS,
  GET_BENEFITS_BY_ID,
  GET_CODE_BENEFIT,
  SET_IS_LOADING_DETAIL_BENEFIT,
  CLEAN_BENEFITS,
  SET_LOADER_BENEFIT_DETAIL,
} from "../types";

const INITIAL_STATE: IBenefitsReducer = {
  benefits: [],
  totalBenefits: 0,
  isLoading: true,
  selectedBenefit: {
    id: "",
    created_at: "",
    updated_at: "",
    deleted_at: "",
    actual_price: 0,
    image: "",
    benefit_channel: "",
    display_discount: 0,
    detail: "",
    discount: 0,
    discount_price: 0,
    expiry: "",
    how_to_use: "",
    quantity_per_user: 0,
    site: "",
    source_code: "",
    terms: "",
    title: "",
    whatsapp: "",
    url: "",
    category_id: "",
    commerce_id: "",
    membership_id: "",
    category: {
      id: "",
      created_at: "",
      updated_at: "",
      deleted_at: "",
      title: "",
      image: "",
    },
    commerce: {
      id: "",
      created_at: "",
      updated_at: "",
      deleted_at: "",
      allow_consume_in_site: false,
      allow_minors: false,
      allow_pets: false,
      description: null,
      direction: "",
      has_delivery: false,
      image: null,
      latitude: 0,
      longitude: 0,
      name: "",
      department_id: "",
      user_id: "",
      banner_image: "",
    },
    membership: {
      id: "",
      created_at: "",
      updated_at: "",
      deleted_at: "",
      name: "",
      slug: "",
      level: 0,
      duration: 0,
      status: "",
    },
  },
  isLoadingDetail: true,
  infoBeneditCode: {
    benefit_id: "",
    code: "",
    created_at: "",
    deleted_at: "",
    expiry: "",
    id: "",
    reclaimed: "",
    status: "",
    updated_at: "",
    user_id: "",
  },
  codeError: "",
  numberReviews: {
    id: "",
    created_at: "",
    updated_at: "",
    deleted_at: "",
    allow_consume_in_site: false,
    allow_minors: false,
    allow_pets: "",
    description: "",
    has_delivery: false,
    image: "",
    banner_image: "",
    name: "",
    user_id: "",
    schedules: [],
    score: 0,
  },
};

export const benefitsReducer = (
  state = INITIAL_STATE,
  action: ActionReturnProps
) => {
  switch (action.type) {
    case GET_BENEFITS:
      const union = [...state.benefits, action.payload.data].flat(10);
      const data = lodash.uniqBy(union, "id");
      return {
        ...state,
        benefits: data,
        totalBenefits: action.payload.total,
        isLoading: false,
      };
    case SET_LOADER_BENEFIT_DETAIL:
      return {
        ...state,
        isLoadingDetail: action.payload,
      };
    case CLEAN_BENEFITS:
      return {
        ...state,
        benefits: action.payload,
      };
    case GET_BENEFITS_BY_ID:
      return {
        ...state,
        selectedBenefit: action.payload,
        isLoadingDetail: false,
      };
    case SET_IS_LOADING_DETAIL_BENEFIT:
      return {
        ...state,
        isLoadingDetail: true,
      };
    case GET_CODE_BENEFIT:
      return {
        ...state,
        infoBeneditCode: action.payload,
      };
    case GET_CODE_BENEFIT_ERROR:
      return {
        ...state,
        codeError: action.payload,
      };
    case GET_SCORE_COMMERCE_BY_ID:
      return {
        ...state,
        numberReviews: action.payload,
      };
    case CLEAM_CODE_BENEFIT:
      return {
        ...state,
        infoBeneditCode: {
          benefit_id: "",
          code: "",
          created_at: "",
          deleted_at: "",
          expiry: "",
          id: "",
          reclaimed: "",
          status: "",
          updated_at: "",
          user_id: "",
        },
      };
    case CLEAM_ERROR_BENEFIT_CODE:
      return {
        ...state,
        codeError: "",
      };
    case CLEAM_INFO_BENEFIT:
      return {
        ...state,
        selectedBenefit: {
          id: "",
          created_at: "",
          updated_at: "",
          deleted_at: "",
          actual_price: "",
          image: "",
          benefit_channel: "",
          display_discount: 0,
          detail: "",
          discount: "",
          discount_price: "",
          expiry: "",
          how_to_use: "",
          quantity_per_user: 0,
          site: "",
          source_code: "",
          terms: "",
          title: "",
          whatsapp: "",
          url: "",
          category_id: "",
          commerce_id: "",
          membership_id: "",
          category: {
            id: "",
            created_at: "",
            updated_at: "",
            deleted_at: "",
            title: "",
            image: "",
          },
          commerce: {
            id: "",
            created_at: "",
            updated_at: "",
            deleted_at: "",
            allow_consume_in_site: false,
            allow_minors: false,
            allow_pets: false,
            description: null,
            direction: "",
            has_delivery: false,
            image: null,
            latitude: 0,
            longitude: 0,
            name: "",
            department_id: "",
            user_id: "",
          },
          membership: {
            id: "",
            created_at: "",
            updated_at: "",
            deleted_at: "",
            name: "",
            slug: "",
            level: 0,
            duration: 0,
            status: "",
          },
        },
      };
    default:
      return state;
  }
};
