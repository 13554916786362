import {
  CLEAN_COMMMERCES,
  CLEAN_INFO_COMMERCE,
  GET_ALL_COMMMERCES,
  GET_BENEFITS_COMMMERCE_BY_ID,
  GET_COMMERCES,
  GET_COMMERCES_BY_ID,
  GET_SCORES_BENEFIT,
} from "../types";
import {
  getCommerces,
  getCommerceById,
  getBenefitCommerceById,
  sendReviewCommerce,
  getScoresBenefit,
  getAllCommerces,
} from "../../api/commerce";
import { ActionReturnProps, ICommerceReducer } from "../interfaces";
import { ThunkAction } from "redux-thunk";
import { toast } from "react-toastify";

type GetAllCommerces = {
  department_id: string;
  membership_level: number;
};

export const _GET_COMMERCES =
  (
    membershipLevel: number,
    department_id: string
  ): ThunkAction<Promise<void>, ICommerceReducer, any, ActionReturnProps> =>
  async (dispatch, getState: Function) => {
    try {
      const token = getState().user.id_token;
      const response = await getCommerces(
        token,
        department_id,
        membershipLevel
      );
      if (response.status === 200) {
        dispatch({
          type: GET_COMMERCES,
          payload: response.data.data,
        });
      } else {
        dispatch({
          type: GET_COMMERCES,
          payload: [],
        });
      }
    } catch (error) {
      dispatch({
        type: GET_COMMERCES,
        payload: [],
      });
      console.log("error try get commeces", error);
    }
  };

export const _GET_ALL_COMMERCES =
  (props: GetAllCommerces) => async (dispatch: any, getState: Function) => {
    try {
      const token = getState().user.id_token;
      const response = await getAllCommerces(
        token,
        props.department_id,
        props.membership_level
      );
      if (response.status === 200) {
        dispatch({
          type: GET_ALL_COMMMERCES,
          payload: response.data.data,
        });
      } else {
        dispatch({
          type: GET_ALL_COMMMERCES,
          payload: [],
        });
      }
    } catch (error) {
      console.log(error);
      dispatch({
        type: GET_ALL_COMMMERCES,
        payload: [],
      });
    }
  };
export const _GET_COMMERCES_BY_ID =
  (
    id: string
  ): ThunkAction<Promise<void>, ICommerceReducer, any, ActionReturnProps> =>
  async (dispatch, getState: Function) => {
    try {
      const token = getState().user.id_token;
      const response = await getCommerceById(token, id);

      if (response.status === 200) {
        dispatch({
          type: GET_COMMERCES_BY_ID,
          payload: response.data,
        });
      }
    } catch (err) {
      console.log("error commerce by id", err);
    }
  };

export const _GET_BENEFIT_COMMMERCE_BY_ID =
  (
    id: string
  ): ThunkAction<Promise<void>, ICommerceReducer, any, ActionReturnProps> =>
  async (dispatch, getState: Function) => {
    try {
      const token = getState().user.id_token;
      const department_id = getState().departments.selectedDepartment.id;
      const response = await getBenefitCommerceById(
        token,
        id,
        department_id || 1
      );
      if (response.status === 200) {
        const { data } = response;
        const commerceBenefitFiltred = data.data.filter(
          (item: any) => item.status === "ACTIVE"
        );
        dispatch({
          type: GET_BENEFITS_COMMMERCE_BY_ID,
          payload: commerceBenefitFiltred,
        });
      } else {
        dispatch({
          type: GET_BENEFITS_COMMMERCE_BY_ID,
          payload: [],
        });
      }
    } catch (error) {
      console.log("error get benefits commerce id", error);
    }
  };

export const _SEND_REVIEW_COMMERCE =
  (data: any) => async (dispatch: any, getState: Function) => {
    try {
      const token = getState().user.id_token;
      const response = await sendReviewCommerce(token, data);
      if (response.status === 200 || response.status === 201) {
        toast.success("Review enviada con éxito", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error(
          "Review no enviado. \nNo puedes calificar un comercio sin haber usado un beneficio!",
          {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
    } catch (error) {
      toast.error(
        "Review no enviado. \nNo puedes calificar un comercio sin haber reclamado un beneficio!",
        {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      console.log("error try send review", error);
    }
  };

export const _GET_SCORES_BENEFIT =
  () => async (dispatch: any, getState: Function) => {
    try {
      const token = getState().user.id_token;
      const response = await getScoresBenefit(token);
      if (response.data.length > 0) {
        dispatch({
          type: GET_SCORES_BENEFIT,
          payload: response.data,
        });
      } else {
        dispatch({
          type: GET_SCORES_BENEFIT,
          payload: [],
        });
      }
    } catch (error) {
      console.log("error get scores benefit", error);
    }
  };

export const _CLEAN_INFO_COMMERCE = () => (dispatch: any) => {
  dispatch({
    type: CLEAN_INFO_COMMERCE,
  });
};

export const _CLEAN_COMMERCES = () => (dispatch: any) => {
  dispatch({
    type: CLEAN_COMMMERCES,
    payload: [],
  });
};
