import { useMemo } from "react";
import { Dropdown } from "antd";

import { useViveSelector } from "hooks/reduxHooks";
import { dictionaryIconMembership } from "../../../utils/contants";
import { UserMembership } from "redux/redux.dto";

import { HeaderMembership, IconMembership, TitleMembershipt } from "./styles";
import UserLevel from "./UserLevel";

const UserMembershipLevel = () => {
  const { user, userMemberships } = useViveSelector((state) => state.user);

  const currentMembership = useMemo(() => {
    if (userMemberships.length) {
      const value = userMemberships.find((el) => el.id === user.membership_id) as UserMembership;
      return {
        name: value.name,
        iconMembership: dictionaryIconMembership[value?.level],
      };
    }
    return {
      iconMembership: dictionaryIconMembership["0"],
      name: "Invitado",
    };
  }, [user.membership_id, userMemberships]);

  return (
    <Dropdown
      overlayStyle={{
        marginTop: 20,
      }}
      overlay={<UserLevel />}
      trigger={["click"]}
      placement="bottomRight"
    >
      <HeaderMembership>
        <IconMembership src={currentMembership.iconMembership} />
        <TitleMembershipt>{currentMembership.name}</TitleMembershipt>
      </HeaderMembership>
    </Dropdown>
  );
};

export default UserMembershipLevel;
