import { useEffect, useState } from "react";
import { Menu } from "antd";
import { ItemType } from "antd/lib/menu/hooks/useItems";
import { useViveSelector } from "hooks/reduxHooks";

const DepartmentTab = ({ selectDeparment }: any) => {
  const { departments } = useViveSelector((state) => state.departments);
  const [departmentTab, setdepartmentTab] = useState<ItemType[]>([]);

  useEffect(() => {
    if (departments.length > 0) {
      setdepartmentTab(
        departments.map((el, i) => {
          return {
            label: el.name,
            key: `${el.id}`,
          };
        })
      );
    }
  }, [departments]);

  const onClick = (e: any) => {
    const newDepartment = departments.find((el) => {
      if (el.id === e.key) {
        return el.name;
      }
    });
    selectDeparment(newDepartment?.id);
  };

  return <Menu onClick={onClick} items={departmentTab} />;
};

export default DepartmentTab;
