import { Fragment } from "react";

import {
  ButtonTwo,
  CategoryTabs,
  CategoryTabsTwo,
  Separt,
  IconTab,
  ImageContentIcons,
  ImageContentIconsInit,
} from "./styles";

type UserTabProps = {
  title: string;
  action: () => void;
  right?: boolean;
  icon: string | undefined;
};

const UserTab = ({ action, title, right = false, icon }: UserTabProps) => {
  return (
    <Fragment>
      {right ? (
        <CategoryTabsTwo onClick={action}>
          <ButtonTwo>
            <Separt>
            <ImageContentIconsInit>
              <IconTab
                src={icon}
                style={{ width: "100%", objectFit: "contain" }}
              />
              </ImageContentIconsInit>
            </Separt>

            {title}
          </ButtonTwo>
        </CategoryTabsTwo>
      ) : (
        <CategoryTabs onClick={action}>
          <ButtonTwo>
            <Separt>
              <ImageContentIcons>
              <IconTab
                src={icon}
                style={{ width: "100%", objectFit: "contain" }}
              />
              </ImageContentIcons>
              
            </Separt>
            {title}
          </ButtonTwo>
        </CategoryTabs>
      )}
    </Fragment>
  );
};

export default UserTab;
