import { Layout, Button } from "antd";
import styled from "styled-components";

const { Header } = Layout;

export const LogoPage = styled(Header)`
  display: none;
  ${({ theme: { media } }) => media.desktop`
    position: relative;
    display: flex;
    width: 50%;
    justify-content: flex-start;
    align-items: center;
    background-color: transparent;  
  `};
`;

export const DropdownPage = styled(Header)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 400px;
  background-color: transparent;
  ${({ theme: { media } }) => media.tablet`
  display: flex;
  justify-content: space-between;
  align-items: center;
width: 100%;
  background-color: transparent;
      
  `};
  ${({ theme: { media } }) => media.desktop`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 40%;
  background-color: transparent;
      
  `};
`;
export const HeaderPage = styled(Header)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  background-color: #0077b6;
  ${({ theme: { media } }) => media.tablet`
    width: 100%;
  `};
  ${({ theme: { media } }) => media.desktop`
    width: 100%;
  `};
`;
export const MinConteiner = styled(Header)`
  background-color: #0077b6;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 380px;
  ${({ theme: { media } }) => media.tablet`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 770px;
  `};
  ${({ theme: { media } }) => media.desktop`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
 width: 1200px;
  `};
`;

export const Dir = styled(Button)`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding-top: 12px;
  border: none;
  background-color: transparent;
  color: #ffffff;
  height: 100%;
  width: 80%;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  margin-right: 0;
  margin-left: 0;
  text-align: center;
  :hover {
    background-color: transparent;
    color: lightblue;
  }
  :focus {
    background-color: transparent;
    color: lightblue;
  }
  ${({ theme: { media } }) => media.tablet`
  margin-right: 0;
  margin-left: 0;
  width: auto;
      
  `};
  ${({ theme: { media } }) => media.desktop`
 margin-right: 0;
  margin-left: 0;
  width: auto;
      
  `};
`;

export const ContainerData = styled("div")`
  margin: 10px 0 0 15px;
  ${({ theme: { media } }) => media.tablet`
    margin: 10px 10px 0;
  `};

  ${({ theme: { media } }) => media.desktop`
    margin: 10px 10px 0 0;
  `};
`;

export const ContainerMenuGlobal = styled("div")`
  display: flex;
  flex-direction: column;
  height: 450px;
  width: 100%;
  margin-top: 20px;
  justify-content: space-between;
  align-items: center;
  background-color: white;

  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
  ${({ theme: { media } }) => media.largeMobile`
   width: 380px;
   margin-bottom:-160px;
     margin-left: -34px;
   
      
  `};
  ${({ theme: { media } }) => media.tablet`
  display: flex;
  flex-direction: row;
  height: 450px;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: white;
  margin-top: 0;
    margin-right: 15px;
  `};
  ${({ theme: { media } }) => media.desktop`
    display: flex;
    justify-content: center;
  margin-top: 0;
    align-items: center;
    flex-direction: row;
    height: 400px;
    padding-left: 0;
    margin-right: -200px;
    width: 950px;
    background-color: white;
  `};
`;
export const ContainerMenuGlobal2 = styled("div")`
  display: flex;
  flex-direction: column;
  height: 450px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  margin-top: 20px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
  ${({ theme: { media } }) => media.largeMobile`
   width: 380px;
   margin-bottom:-160px;
   margin-right: -44px;
   z-index:100;
      
  `};
  ${({ theme: { media } }) => media.tablet`
  display: flex;
  flex-direction: row;
  height: 430px;
   margin-top:0;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: white;
  padding-top: 60px;
    margin-right: 15px;
  `};
  ${({ theme: { media } }) => media.desktop`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
     margin-top:15px;
    height: 380px;
    padding-top: 100px;
    padding-left: 0;
    width: 950px;
    background-color: white;
  `};
`;
export const ContainerMenuPerfile = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  height: 100%;
  width: 95%;
  margin-top: 0;

  ${({ theme: { media } }) => media.tablet`
 display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 25%;
  margin-top: -100px;
  `};
  ${({ theme: { media } }) => media.desktop`
   display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 40%;
  margin-top: -100px;
  
  `};
`;
export const ContainerMenuPerfile2 = styled("div")`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding-bottom: 10px;

  ${({ theme: { media } }) => media.tablet`
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  height: 100%;
  width: 60%;
  padding-bottom: 100px;
    margin-left: 10px;
  `};
  ${({ theme: { media } }) => media.desktop`
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  height: 100%;
  width: 60%;
  padding-bottom: 100px;
    margin-left: -20px;
  `};
`;
export const InformationSeccion = styled("div")`
  display: flex;
  justify-content: space-around;
  margin-left: -160px;
  flex-direction: row;
  width: 60%;
  margin-bottom: 10px;
  margin-top: 10px;
  ${({ theme: { media } }) => media.tablet`
display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  width: 100%;
  margin-left: 0;
  margin-bottom: 10px;
  margin-top: 10px;
  `};
  ${({ theme: { media } }) => media.desktop`
    display: flex;
  justify-content: space-evenly;
  flex-direction: row;
   margin-left: -40px;
  width: 90%;
  margin-bottom: 30px;
    margin-top: 0;
  `};
`;

export const InformationSeccionTwo = styled("div")`
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  width: 80%;
  /* margin-bottom: 10px; */

  margin-left: -15px;
  ${({ theme: { media } }) => media.tablet`
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  width: 100%;
  margin-bottom: 10px;
  margin-left: 65px;
  `};
  ${({ theme: { media } }) => media.desktop`
    display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  width: 100%;
  margin-bottom: 10px;
  margin-left: 50px;
  `};
`;
export const Datos1 = styled("div")`
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  width: 26%;
  ${({ theme: { media } }) => media.tablet`
display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  width: 26%;
  `};
  ${({ theme: { media } }) => media.desktop`
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  width: 26%;
  `};
`;
export const CategoryTabs = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  width: 50%;
  cursor: pointer;
  height: auto;
  margin-right: 0;
  ${({ theme: { media } }) => media.tablet`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  width: 37%;
  height: auto;
  margin-right: 0;
  `};
  ${({ theme: { media } }) => media.desktop`
 display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  width: 65%;
  padding:5px;
    margin-right: 0;
      
  `};
`;

export const CategoryTabsTwo = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  width: 50%;
  cursor: pointer;
  height: auto;
  margin-right: 0;
  ${({ theme: { media } }) => media.tablet`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  width: 37%;
  height: auto;
  margin-right: 0;
  `};
  ${({ theme: { media } }) => media.desktop`
 display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  width: 65%;
    margin-right: 0;
      padding:5px;
        margin-left: -120px;
  `};
`;
export const ButtonTwo = styled.div`
  width: 100%;
  height: 50px;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 15px;
  text-align: center;
  color: black;
  margin-right: 0;
  padding: 5px;
  ${({ theme: { media } }) => media.tablet`
  font-size: 15px;
    line-height: 20px;
      height: 60px;
   padding: 5px;
   border: 1px solid #0077b6;
  border-radius: 9px;
  `};
  ${({ theme: { media } }) => media.desktop`
 width: 50%;
  height: 50px;
  margin-left: 60px;
    font-size: 15px;
   padding: 3px;
  line-height: 20px;
  border: 1px solid #0077b6;
  border-radius: 9px;
  `};
`;
export const ContainerMenuNivel = styled("div")`
  box-sizing: border-box;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  width: 100vw;
  ${({ theme: { media } }) => media.largeMobile`
   width: 100%;
   
      
  `};
  ${({ theme: { media } }) => media.tablet`
 display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  height: 400px;
  width: 50vw;
  padding-top: 10px;
  padding-bottom: 50px;
  `};
  ${({ theme: { media } }) => media.desktop`
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  height: 400px;
  width: 50vw;
  padding-top: 10px;
      
  `};
`;




export const Separt = styled("div")`
  padding: 0 10px 0 0;
  color: #0077b6;
  display: flex;
  justify-content: center;
  ${({ theme: { media } }) => media.tablet`
  padding: 0 0;
  color: #0077b6;
  display: flex;
  justify-content: center;
  `};
  ${({ theme: { media } }) => media.desktop`
    padding: 0 5px;
    width: 35px; 
    height: 28px; 
    margin: 0 5px 0 3px;
  `};
`;
export const IconTab = styled.img`
  width: 50%;
  object-fit: cover;
  ${({ theme: { media } }) => media.tablet`

  `};
  ${({ theme: { media } }) => media.desktop`
    width: 100%;
  height: 100%;
  `};
`;

export const ImageContentIcons = styled.div`
  width: 22px;
  object-fit: contain;
  ${({ theme: { media } }) => media.tablet`
  width:27px;
  height: 24.5px;
  object-fit: contain;
  `};
  ${({ theme: { media } }) => media.desktop`
  width:29px;
  height: 24.5px;
  object-fit: contain;
  `};
`;

export const ImageContentIconsInit = styled.div`
  width: 22px;
  object-fit: contain;
  ${({ theme: { media } }) => media.tablet`
  width:27px;
  height: 24.5px;
  object-fit: contain;
  `};
  ${({ theme: { media } }) => media.desktop`
  width:26px;
  height: 27px;
  object-fit: contain;
  `};
`;

export const Coronas = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  padding-left: 0;
  ${({ theme: { media } }) => media.tablet`
 display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  height: 100px;
  padding-left: 0;
  `};
  ${({ theme: { media } }) => media.desktop`
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    height: 100px;
    padding-left: 0;
      
  `};
`;
export const Barra = styled.div`
  width: 85%;
  padding: 10px 0;
  text-align: center;
  display: flex;
  align-self: center;
  ${({ theme: { media } }) => media.tablet`
    width: 85%;
    padding: 0 0;
  `};
  ${({ theme: { media } }) => media.desktop`
    width: 80%;
    padding: 0 0;

  `};
`;
export const Platino = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;
export const Oro = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;
export const PlataCorona = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;
export const TamamiCoronas = styled.div`
  width: 34px;
  height: 34px;

  ${({ theme: { media } }) => media.tablet`
  width: 49px;
  height: 34px;
       
    `};
  ${({ theme: { media } }) => media.desktop`
  width: 61px;
  height: 42px;
       
    `};
`;

export const ImagePrime = styled.img`
  width: 100%;
  height: auto;
`;

export const ContainerMenuNivel2 = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-evenly;
  width: 90%;
  padding-right: 0;
  margin-top: -40px;
  ${({ theme: { media } }) => media.tablet`
    display: flex;
    flex-direction: column;
    height: 400px;
    justify-content: space-evenly;
    width: 48vw;
    padding-right: 20px;
    margin-top: -60px;  
  `};
  ${({ theme: { media } }) => media.desktop`
    display: flex;
    flex-direction: column;
    height: 400px;
    justify-content: space-evenly;
    width: 40vw;
    padding-right: 40px;
    margin-top: -60px;  
  `};
`;
export const TextMembership = styled.h2`
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: #000000;
  ${({ theme: { media } }) => media.tablet`
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: #000000;
    padding-bottom: 20px;
  `};
  ${({ theme: { media } }) => media.desktop`
    display: flex;
    flex-direction: row;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    padding-bottom: 0;
  `};
`;
export const Texto2 = styled.p`
  display: flex;
  flex-direction: column;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12.5px;
  line-height: 18px;
  color: #000000;
  ${({ theme: { media } }) => media.desktop`
    display: flex;
    flex-direction: column;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 22px;
    color: #000000;
    padding-left: 50px;
    padding-right: 30px;
    `};
`;
export const Texto8 = styled.div`
  display: flex;
  flex-direction: column;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 18px;
  color: #000000;
  padding-left: 20px;
  margin-right: 10px;
  ${({ theme: { media } }) => media.tablet`
  display: flex;
  flex-direction: column;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  color: #000000;
  padding-left: 10px;
  margin-right: -80px;
    `};
  ${({ theme: { media } }) => media.desktop`
    display: flex;
  flex-direction: column;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
  padding-left: 50px;
  padding-right: 0;
    margin-right: 0;
    `};
`;

export const TextMonth = styled.h2`
  display: flex;
  flex-direction: row;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 24px;
  text-align: center;
  color: #000000;
  justify-content: center;

  ${({ theme: { media } }) => media.tablet`
     display: flex;
    flex-direction: row;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #000000;
    justify-content: center;
  `};
  ${({ theme: { media } }) => media.desktop`
     display: flex;
    flex-direction: row;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    color: #000000;
    justify-content: center;
  `};
`;
export const Texto6 = styled.div`
  display: flex;
  flex-direction: column;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  color: #000000;
  max-width: 250px;
  min-width: 260px; 
  overflow-x: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
  ${({ theme: { media } }) => media.tablet`
      display: flex;
  flex-direction: column;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 22px;
  color: #000000;
      
  `};
  ${({ theme: { media } }) => media.desktop`
      display: flex;
  flex-direction: column;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
      
  `};
`;

export const UserMembershipInfo = styled.div`
  width: 100%;
  height: 50%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ContainerAvatar = styled.div`
  width: 150px;
  border-radius: 100px;
  margin-right: 5px;
  ${({ theme: { media } }) => media.tablet`
    width: 240px;
    height: 100px;
    border-radius: 100px;
  `};
  ${({ theme: { media } }) => media.desktop`
    width: 240px;
    height: 100px;
    border-radius: 100px;
  `};
`;
export const ContainerAvatarHeader = styled.div`
  width: 51.59px;
  height: 36.59px;
  object-fit: cover;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ theme: { media } }) => media.tablet`
      width: 41.59px;
    height: 38.59px;
    border-radius: 100px;
  `};
  ${({ theme: { media } }) => media.desktop`
    width: 41.59px;
    height: 38.59px;
    border-radius: 100px;
  `};
`;

export const UserAvatar = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 100px;
`;

export const IconPerfile = styled("div")`
  width: 120px;
  height: auto;
  padding: 0 0 10px 0;
  margin-left: 15px;
  ${({ theme: { media } }) => media.tablet`
    width: 140px;
  height: 170px;
  padding: 0 0 30px 0;
  margin-left: 25px;
      
  `};
  ${({ theme: { media } }) => media.desktop`
    width: 140px;
  height: 170px;
  padding: 0 0 30px 0;
  margin-left: 0;
      
  `};
`;
export const UserImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 100px;
  object-fit: cover;
`;
export const UpdateButton = styled.div`
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 15px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  margin-top: 5px;
  margin-left: 18px;
  width: 130px;
  height: 35px;
  background: #006abf;
  border-radius: 6px;
  ${({ theme: { media } }) => media.tablet`
    width: 140px;
  height: 35px;
      font-size: 17px;  
  `};
  ${({ theme: { media } }) => media.desktop`
  width: 140px;
  height: 40px;  
  margin-left: 0;
    margin-top: 10px;
  background: #006ABF;
  border-radius: 6px;
  font-size: 18px;
  line-height: 15px;
  `};
`;

export const Name = styled("div")`
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  ${({ theme: { media } }) => media.tablet`
    font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
      
  `};
  ${({ theme: { media } }) => media.desktop`
    font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
      
  `};
`;
export const Content = styled("div")`
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  text-align: start;
  margin-left: 0;
  margin-top: 5px;
  line-height: 24px;
  color: #000000;

  ${({ theme: { media } }) => media.tablet`
    font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  color: #000000;
    margin-top: 0;
        margin-left: 0;
  `};
  ${({ theme: { media } }) => media.desktop`
    font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  color: #000000;
          margin-top: 0;
  `};
`;

export const MenuIcon1 = styled.img`
  width: 90px;
  height: 55px;
`;

export const HeaderMembership = styled.div`
  width: 90px;
  height: 30px;
  display: flex;
  flex-direction: column;
  ${({ theme: { media } }) => media.tablet`
    width: 90px;
  height: 30px;
  display: flex;
  flex-direction: column;
  `};
  ${({ theme: { media } }) => media.desktop`
    width: 90px;
    height: 100%;
    display: flex;
      align-items: center;
    flex-direction: column;
    & :hover {
      cursor: pointer;
    }
  `};
`;

export const TitleMembershipt = styled.span`
  padding: 0;
  margin-top: -18px;
  height: 10px;
  color: white;
  width: 25px;
  margin-left: -10px;
  text-transform: capitalize;
  ${({ theme: { media } }) => media.tablet`
    margin-left: 0;
  `};
  ${({ theme: { media } }) => media.desktop`
    margin-left: 0;
  `};
`;

export const IconMembership = styled.img`
  width: 25px;
  height: 25px;
  margin-top: -8px;

  ${({ theme: { media } }) => media.tablet`
    margin-left: 10px;
  `};
  ${({ theme: { media } }) => media.desktop`
    margin-top: 8px;
     margin-left: 10px;
  `};
`;
