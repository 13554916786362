import { useEffect } from "react";
import { ThemeProvider } from "styled-components";
import { GlobalStyle } from "themes/global";
import lightTheme from "themes/light";
import { RootStack } from "router";
import mixpanel from "mixpanel-browser";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import {
  _GET_USER_MEMBERSHIPS,
  _VERIFY_USER_LOGGED,
} from "redux/actions/userAction";
import { ID_TOKEN_STOTAGE } from "utils/contants";
import { SecureRequest } from "utils/secureRequest";
import { _SIGN_IN } from "./redux/actions/userAction";
import { useViveDispatch, useViveSelector } from "./hooks/reduxHooks";
import LoaderApp from "components/loader/LoaderApp";

mixpanel.init("8ede7c3689a3d637ee480a8b998cf153");
/** this token is for mixpanel test 3b8b37be9012fb664b71e724ffa791b4*/
//mixpanel.init("3b8b37be9012fb664b71e724ffa791b4");
const LOGGED_IN_BLACKLIST = ["/", "/signin", "/verification-code", "/*"];

const App = () => {
  const dispatch = useViveDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { isLoading, id_token } = useViveSelector((state) => state.user);

  const verifyUser = async () => {
    try {
      const valueToken = localStorage.getItem(ID_TOKEN_STOTAGE);
      if (!valueToken) {
        console.log("no token");
        dispatch(_SIGN_IN());
        return;
      }
      let id_token = JSON.parse(valueToken);
      const request = await SecureRequest(id_token).get("/auth/profile");
      await dispatch(_VERIFY_USER_LOGGED(request.data, id_token));
      if (LOGGED_IN_BLACKLIST.includes(location.pathname))
        navigate("/home", { replace: true });
    } catch (error: any) {
      console.log("error catch try verify auth", error);
      if (error.message === "Network Error") {
        toast.info("Opps! Hubo problemas con tu conexión", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      if (
        error.message === "Request failed with status code 401" ||
        error.status === 401
      ) {
        console.log("FALLO VERIFICAR TOKEN");
        localStorage.removeItem(ID_TOKEN_STOTAGE);
        dispatch(_SIGN_IN());
      }
    }
  };

  useEffect(() => {
    verifyUser().then((r) => {});
    if (id_token) dispatch(_GET_USER_MEMBERSHIPS());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id_token]);

  if (isLoading) return <LoaderApp />;

  return (
    <ThemeProvider theme={lightTheme}>
      <GlobalStyle />
      <RootStack />
      <ToastContainer />
    </ThemeProvider>
  );
};

export default App;
