import { combineReducers } from "redux";
import { categoriesReducer } from "./categoryReducer";
import { featureReducer } from "./featureReducer";
import { userReducer } from "./userReducer";
import { commercesReducer } from "./commerceReducer";
import { departmentsReducer } from "./departmentReducer";
import { benefitsReducer } from "./benefitReducer";

export const rootReducers = combineReducers({
  user: userReducer,
  categories: categoriesReducer,
  features: featureReducer,
  commerces: commercesReducer,
  departments: departmentsReducer,
  benefits: benefitsReducer,
});
