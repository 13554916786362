import React from "react";
import {
  ContainerModal,
  ContentCodeBenefitUniDrogas,
  BodyCodeBenefitUniDrogas,
  ImageCheck,
  SubTitle,
  TextUniDrogas,
  PressableCloseUniDrogas,
  TextPressUniDrogas,
} from "./styles";

import { ICON_CHECK_UNIDROGAS } from "assets/icons";
import { ModalBackground } from "../styles";
import { createPortal } from "react-dom";

interface InformationModalUnidrogasProps {
  onClose: () => void;
}

const InformationModalUnidrogas = (props: InformationModalUnidrogasProps) => {
  return createPortal(
    <ModalBackground>
      <ContainerModal>
        <ContentCodeBenefitUniDrogas>
          <BodyCodeBenefitUniDrogas>
            <ImageCheck src={ICON_CHECK_UNIDROGAS} />
            <SubTitle>¡Redimiste el cupón exitosamente!</SubTitle>
            <TextUniDrogas>
              En unos minutos te llegará un mensaje de texto el cual te indicará
              el código a usar en el establecimiento{" "}
            </TextUniDrogas>
          </BodyCodeBenefitUniDrogas>
          <PressableCloseUniDrogas onClick={props.onClose}>
            <TextPressUniDrogas>Entendido</TextPressUniDrogas>
          </PressableCloseUniDrogas>
        </ContentCodeBenefitUniDrogas>
      </ContainerModal>
    </ModalBackground>,
    document.getElementById("modal-benefit-detail") as HTMLElement
  );
};

export default InformationModalUnidrogas;
