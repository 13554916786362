import { SecureRequest } from "utils/secureRequest";

export const getCategories = async (
  token: string,
  department_id: string,
  membership_level: number
) => {
  return await SecureRequest(token).get(
    `/categories?where[$$benefits.benefits_departments.department_id$$]=${department_id}&where[$$benefits.benefits_departments.benefit.status$$]=ACTIVE&where[$$benefits.membership.level$$][$lte]=${membership_level}`
  );
};
