import styled from "styled-components";
import { Monserrat20x500 } from "components/typography/typographt";
import { ICON_ALERT } from "../../../../assets/icons";

export const ContainerAlert = styled.div`
  display: flex;
  margin-top: 18px;
  flex-direction: row;
  align-items: center;
  width: 370px;
  height: 78px;
  align-content: center;
  justify-content: center;
  margin-bottom: -30px;
  padding: 0 6px;
  ${({ theme: { media } }) => media.tablet`
    justify-content: center;
    margin-top: 20px;
    width: 770px;
    padding: 0;
    margin-bottom: 0;
  `};
  ${({ theme: { media } }) => media.desktop`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 1000px;
    padding: 0;
    margin-bottom: 0;
    height: 78px;
    justify-content: center;
    margin-top: 33px; 
    margin-left: 10px;
  `};
`;

export const ContainerInfoAlert = styled.div`
  width: 307px;
  height: 88px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  ${({ theme: { media } }) => media.tablet`
     width: 450px;
      background: #ffffff;
  box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.1);
  border-radius: 100px;
    padding: 0 20px;
  `};
  ${({ theme: { media } }) => media.desktop`
  width: 430px;
   background: #ffffff;
  box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.1);
  border-radius: 100px;
        padding: 0 30px;
  `};
`;

export const TextAlert = styled(Monserrat20x500)`
  color: #ff9b04;
  font-size: 12px;
  line-height: 15px;

  ${({ theme: { media } }) => media.tablet`
     font-size: 15px;
  `};
  ${({ theme: { media } }) => media.desktop`
  font-size: 13px;
  line-height: 22px;
    padding: 10px 15px;
  `};
`;

export const ImageAlert = styled.img.attrs({
  src: ICON_ALERT,
})`
  width: 16px;
  height: 15.82px;
  margin-right: 8px;
  ${({ theme: { media } }) => media.tablet`
    width: 35.5px;
    height: 35.09px;
  `};
  ${({ theme: { media } }) => media.desktop`
    width: 35.5px;
    height: 35.09px;
  `};
`;
