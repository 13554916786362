import { Monserrat12px500 } from "components/typography/typographt";
import styled from "styled-components";

export const ContainerHomeCategories = styled.div`
  display: flex;
  flex-direction: row;
  width: 370px;
  height: 70px;
  margin: 10px 0 10px 17px;

  ${({ theme: { media } }) => media.tablet`
    width: 770px;
    height: 76px;
    justify-content: start;
    margin: 10px 0 25px 5px;
    padding: 0 10px 0 10px;
    overflow-x: clip;
  `};
  ${({ theme: { media } }) => media.desktop`
    width: 1000px;
    height: 85px;
    justify-content: start;
    margin: 10px 0 10px;  
    overflow: hidden;
    margin-bottom: 25px;
  `};
`;

export const CategoryTabs = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  height: 100%;
  position: relative;
  margin-right: 10px;
  width: 100%;
  ${({ theme: { media } }) => media.tablet`
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 80px; 
    margin-right: 20px;
      
  `};
  ${({ theme: { media } }) => media.desktop`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    height: 80px; 
    width: 100%;
    max-width:900px
    margin-right: 20px;
  `};
`;

export const ContainerCommerceHits = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: start;
  align-items: center;
  overflow-y: hidden;
  overflow-x: scroll;
  ${({ theme: { media } }) => media.tablet`
    width: 100%;
    height: 85px;
    display: flex;
    overflow-y: hidden;
    overflow-x: scroll;
  `};
  ${({ theme: { media } }) => media.desktop`
    width: 100%;
    height: 100%;
    display: flex;
    overflow-y: hidden;
    overflow-x: visible;
  `};

  & :hover {
    cursor: pointer;
  }

  ::-webkit-scrollbar {
    background: #e1e1e1;
    height: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background: #b9b9b9;
    height: 5px;
    border-radius: 30px;
  }
`;

export const TextEmpty = styled(Monserrat12px500)`
  font-size: 1rem;
  text-transform: capitalize;
`