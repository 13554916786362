import {
  CLEAM_CODE_BENEFIT,
  GET_BENEFITS_BY_ID,
  GET_CODE_BENEFIT_ERROR,
  GET_SCORE_COMMERCE_BY_ID,
  GET_CODE_BENEFIT,
  CLEAM_ERROR_BENEFIT_CODE,
  CLEAM_INFO_BENEFIT,
  SET_LOADER_BENEFIT_DETAIL,
  GET_BENEFITS,
  CLEAN_BENEFITS,
} from "../types";

import {
  getBenefits,
  getBenefitsById,
  getCodeBenefit,
  reclaimBenefit,
} from "../../api/benefits";
import { ThunkAction } from "redux-thunk";
import { ActionReturnProps, IBenefitsReducer } from "../interfaces";
import { getCommerceById } from "../../api/commerce";
import { toast } from "react-toastify";

export const _GET_BENEFITS =
  (
    page: number,
    param: boolean,
    membershipLevel: number,
    department_id: string
  ): ThunkAction<Promise<void>, IBenefitsReducer, any, ActionReturnProps> =>
  async (dispatch, getState: Function) => {
    try {
      const token = getState().user.id_token;
      // const department_id = getState().departments.selectedDepartment.id;
      const response = await getBenefits({
        token,
        department_id,
        numberPage: page,
        membership_level: membershipLevel,
      });
      if (response?.status === 200) {
        dispatch({
          type: GET_BENEFITS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: GET_BENEFITS,
          payload: [],
        });
      }
      // dispatch(_CHANGE_LOADER(param));
    } catch (error) {
      dispatch({
        type: GET_BENEFITS,
        payload: [],
      });
      // dispatch(_CHANGE_LOADER(param));
      console.log("error getBenefits", error);
    }
  };

export const _GET_BENEFITS_BY_ID =
  (
    id: string
  ): ThunkAction<Promise<void>, IBenefitsReducer, any, ActionReturnProps> =>
  async (dispatch: any, getState: Function) => {
    try {
      const token = getState().user.id_token;
      const response = await getBenefitsById({ token, id });
      if (response.status === 200) {
        const { data } = response;
        dispatch({
          type: GET_BENEFITS_BY_ID,
          payload: data,
        });
      } else {
        dispatch({
          type: GET_BENEFITS_BY_ID,
          payload: {},
        });
      }
    } catch (err: any) {
      window.history.back();
      toast(err.message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      console.log("error benefit by id", err);
    }
  };

export const _GET_CODE_BENEFIT =
  (
    id: string
  ): ThunkAction<Promise<void>, IBenefitsReducer, any, ActionReturnProps> =>
  async (dispatch: any, getState: Function) => {
    try {
      const token = getState().user.id_token;
      let response = await getCodeBenefit({ token, id });
      if (response.data) {
        dispatch({
          type: GET_CODE_BENEFIT,
          payload: response.data,
        });
      } else {
        dispatch({
          type: GET_CODE_BENEFIT,
          payload: {},
        });
      }
    } catch (error) {
      console.log("error get code benefit", error);
      dispatch({
        type: GET_CODE_BENEFIT_ERROR,
        payload:
          "Disfruta en Vive Mas del resto de beneficios que tenemos para ti",
      });
    }
  };
export const _GET_SCORE_COMMERCE =
  (idCommerce: string) => async (dispatch: any, getState: Function) => {
    try {
      const token = getState().user.id_token;
      const response = await getCommerceById(token, idCommerce);

      if (response.status === 200) {
        dispatch({
          type: GET_SCORE_COMMERCE_BY_ID,
          payload: response.data,
        });
      }
    } catch (error) {
      console.log("error get score benefit commerce");
    }
  };

export const _RECLAIM_BENEFIT =
  (codeBenefit: string) => async (dispatch: any, getState: Function) => {
    try {
      const token = getState().user.id_token;
      const response = await reclaimBenefit({ token, codeBenefit });
      if (response.status === 200) {
        dispatch();
      } else {
      }
    } catch (error) {
      dispatch();

      console.log("error get code benefit", error);
    }
  };
export const _CLEAN_CODE_BENEFIT = () => (dispatch: any) => {
  dispatch({
    type: CLEAM_CODE_BENEFIT,
  });
};
export const _CLEAN_ERROR_BENEFIT = () => (dispatch: any) => {
  dispatch({
    type: CLEAM_ERROR_BENEFIT_CODE,
    payload: "",
  });
};
export const _CLEAN_INFO_BENEFIT = () => (dispatch: any) => {
  dispatch({
    type: CLEAM_INFO_BENEFIT,
  });
};

export const _SET_LOADER_BENEFIT_DETAIL = () => (dispatch: any) => {
  dispatch({
    type: SET_LOADER_BENEFIT_DETAIL,
    payload: true,
  });
};

export const _CLEAN_BENEFITS = () => (dispatch: any) => {
  dispatch({
    type: CLEAN_BENEFITS,
    payload: [],
  });
};
