import { useState } from "react";
import { toast } from "react-toastify";

import { ICON_SELECT_DEPARTMENT } from "assets/icons";
import { CustomMap } from "components/common/CustomMap";
import { useViveDispatch, useViveSelector } from "hooks/reduxHooks";
import { DepartmentItems } from "redux/interfaces";
import { _UPDATE_USER_DEPARTMENT } from "redux/actions/departmentsAction";
import { SET_VISIBLE_DEPARTMENT_MANDATORY } from "redux/types";
import { CustomOption } from "components/common/CustomOption";

import {
  BodySelectDepartment,
  ContainerModalDepartment,
  HeaderModalDepartment,
  IconSelectDepartment,
  ModalBackground,
  TitleHeaderDepartment,
} from "./styles";

export default function ModalSelectDepartment() {
  const [loading, setLoading] = useState(false);
  const dispatch = useViveDispatch();

  const { departments } = useViveSelector((state) => state.departments);
  const { user } = useViveSelector((state) => state.user);

  const handleSelectDepartmentMandatory = async (id: string) => {
    try {
      setLoading(true);
      const current = departments.find(
        (department) => department.id === id ?? "1"
      );
      const data = { ...user, department_id: current?.id };
      await dispatch(_UPDATE_USER_DEPARTMENT(data));
      dispatch({ type: SET_VISIBLE_DEPARTMENT_MANDATORY, payload: false });
      setLoading(false);
    } catch (error) {
      console.error("error on handleSelectDepartmentMandatory", error);
      dispatch({ type: SET_VISIBLE_DEPARTMENT_MANDATORY, payload: false });
      toast.error("Opps!, No pudimos cambiar tu departamento", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return (
    <ModalBackground>
      <ContainerModalDepartment>
        <HeaderModalDepartment>
          <TitleHeaderDepartment>
            Seleccione nuevo departamento
          </TitleHeaderDepartment>
          <IconSelectDepartment src={ICON_SELECT_DEPARTMENT} />
        </HeaderModalDepartment>
        <BodySelectDepartment>
          <CustomMap
            data={departments}
            renderItem={({ id, name }: DepartmentItems) => (
              <CustomOption
                key={id}
                id={id}
                name={name}
                loading={loading}
                handleSelect={handleSelectDepartmentMandatory}
              />
            )}
          />
        </BodySelectDepartment>
      </ContainerModalDepartment>
    </ModalBackground>
  );
}
