import { Layout } from "antd";
import styled from "styled-components";

export const WrapperLayout = styled(Layout)`
  min-height: 100vh;
  background-color: #efefef;
  & .ant-layout {
    background-color: #efefef;
  }
  & .layout-content {
    min-height: 100vh;
  }
`;
