import { useViveSelector } from "hooks/reduxHooks";
import { CategoriesItem } from "redux/redux.dto";
import { S3_CREDENTIAL } from "utils/contants";

import { ButtonTwo, Image, TitleCategory } from "./style";

const Category: React.FC<CategoriesItem> = ({ image, title }) => {
  const { title: titleSelect } = useViveSelector(
    (state) => state.categories.selectedTabCategory
  );

  const url = `${S3_CREDENTIAL.baseUrl}/${
    image ? image : "/placeholders/gallery.png"
  }`;

  return (
    <ButtonTwo isSelectedCategory={titleSelect === title}>
      <Image src={url} alt={title} />
      <TitleCategory isSelectedCategory={titleSelect === title}>
        {title}
      </TitleCategory>
    </ButtonTwo>
  );
};

export default Category;
