import { Dispatch, SetStateAction } from "react";
import { createPortal } from "react-dom";

import {
  BodyModal,
  ContainerModal,
  HeaderCloseModal,
  HeaderModal,
  Img,
  ModalBackground,
  TitleHeaderModal,
} from "./styles";

type ModalBenefitDetailProps = {
  children: JSX.Element | JSX.Element[];
  title: string;
  handleClose: Dispatch<SetStateAction<boolean>>;
};

const ModalBenefitDetail = ({
  children,
  title,
  handleClose,
}: ModalBenefitDetailProps) => {
  return createPortal(
    <ModalBackground>
      <ContainerModal>
        <HeaderModal onClick={() => handleClose(false)}>
          <TitleHeaderModal />
          <TitleHeaderModal>{title}</TitleHeaderModal>
          <HeaderCloseModal>
            <Img />
          </HeaderCloseModal>
        </HeaderModal>
        <BodyModal>{children}</BodyModal>
      </ContainerModal>
    </ModalBackground>,
    document.getElementById("modal-benefit-detail") as HTMLElement
  );
};

export default ModalBenefitDetail;
