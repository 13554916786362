import { useMemo } from "react";
import { Slider } from "antd";

import { useViveSelector } from "../../../hooks/reduxHooks";
import { dictionaryProgessBar, S3_CREDENTIAL } from "../../../utils/contants";
import { PRIME_ORO, PRIME_BRONCE, PRIME_PLATA } from "../../../assets/image";
import { PRIME } from "assets/icons";
import {PayBillButton} from "components/common/PayBillButton";

import {
  Barra,
  ContainerAvatar,
  ContainerMenuGlobal,
  ContainerMenuNivel,
  ContainerMenuNivel2,
  Coronas,
  ImagePrime,
  Name,
  Oro,
  PlataCorona,
  Platino,
  TamamiCoronas,
  TextMonth,
  TextMembership,
  Texto2,
  UserAvatar,
  UserMembershipInfo,

} from "./styles";

const UserLevel = () => {
  const { user, userMemberships } = useViveSelector((state) => state.user);
  const { first_name } = user;

  const value = useMemo(() => {
    if (user.id && userMemberships.length > 0) {
      return userMemberships.find((el) => el.id === user.membership_id);
    }
    return { name: "No disponible" };
  }, [userMemberships, user.membership_id, user.id]);

  const currentLevel = useMemo(() => {
    if (user.id && userMemberships.length > 0) {
      const value = userMemberships.find((el) => el.id === user.membership_id);
      return dictionaryProgessBar[value?.level || "0"];
    }
    return dictionaryProgessBar["0"];
  }, [userMemberships, user.id, user.membership_id]);

  return (
    <ContainerMenuGlobal>
      <ContainerMenuNivel>
        <Coronas>
          <Platino>
            <TamamiCoronas>
              <ImagePrime src={PRIME} />
            </TamamiCoronas>
            <TextMonth>Básico</TextMonth>
          </Platino>
          <Platino>
            <TamamiCoronas>
              <ImagePrime src={PRIME_BRONCE} />
            </TamamiCoronas>
            <TextMonth>Bronce</TextMonth>
          </Platino>
          <PlataCorona>
            <TamamiCoronas>
              <ImagePrime src={PRIME_PLATA} />
            </TamamiCoronas>
            <TextMonth>Plata</TextMonth>
          </PlataCorona>
          <Oro>
            <TamamiCoronas>
              <ImagePrime src={PRIME_ORO} />
            </TamamiCoronas>
            <TextMonth>Oro</TextMonth>
          </Oro>
        </Coronas>
        <Barra>
          <Slider
            disabled
            value={currentLevel}
            tipFormatter={null}
            style={{ width: "100%", height: "auto" }}
          />
        </Barra>
        <Coronas>
          <PlataCorona>
            <TextMonth>0 Facturas</TextMonth>
          </PlataCorona>
          <PlataCorona>
            <TextMonth>3 Facturas</TextMonth>
          </PlataCorona>
          <Oro>
            <TextMonth>6 Facturas</TextMonth>
          </Oro>
          <Platino>
            <TextMonth>9 Facturas</TextMonth>
          </Platino>
        </Coronas>

        <PayBillButton/>

      </ContainerMenuNivel>
      <ContainerMenuNivel2>
        <TextMembership>
          Tu membresía depende de cuantas facturas consecutivas llevas pagando a
          tiempo.
        </TextMembership>
        <UserMembershipInfo>
          <ContainerAvatar>
            <UserAvatar
              alt="user avatar"
              src={`${S3_CREDENTIAL.baseUrl}/${
                user.avatar ? user.avatar : "placeholders/user.png"
              }`}
            />
          </ContainerAvatar>
          <Texto2>
            <Name>{first_name},</Name>
            {`Tu membresía actual es ${value?.name}. Disfruta de los beneficos de esta membresia.`}
          </Texto2>
        </UserMembershipInfo>
      </ContainerMenuNivel2>
    </ContainerMenuGlobal>
  );
};

export default UserLevel;
