import { SecureRequest } from "../utils/secureRequest";
import {
  getBenefitsByIdProps,
  getBenefitsProps,
  getCodeBenefitProps,
  reclaimBenefitProps,
} from "./api.dto";

export const getBenefits = async ({
  token,
  department_id,
  numberPage,
  membership_level,
}: getBenefitsProps) => {
  try {
    return await SecureRequest(token).get(
      `/benefits?where[$$benefits_departments.department_id$$]=${department_id}&where[status]=ACTIVE&where[expiry][$gt]=${new Date().toISOString()}&pagination[page]=${numberPage}&pagination[perPage]=10&where[$$membership.level$$][$lte]=${membership_level}`
    );
  } catch (error) {
    console.log(error);
  }
};
export const getBenefitsById = async ({ token, id }: getBenefitsByIdProps) => {
  return await SecureRequest(token).get(`/benefits/${id}`);
};
export const getCodeBenefit = async ({ token, id }: getCodeBenefitProps) => {
  return await SecureRequest(token).post(`/users-benefits/obtain/${id}`);
};
export const reclaimBenefit = async ({
  token,
  codeBenefit,
}: reclaimBenefitProps) => {
  return await SecureRequest(token).post(
    `/users-benefits/reclaim/${codeBenefit}`
  );
};
