import { useState } from "react";
import { useNavigate } from "react-router-dom";

export function UserPendingData() {
  const [pendingData, setPendingData] = useState(false);
  const navigate = useNavigate();

  const handleNavigatePendingData = () => {
    navigate("/complete-profile");
  };

  return { pendingData, handleNavigatePendingData, setPendingData };
}
