import styled from "styled-components";
import { colors } from "themes/dark/colors";
import { LOCK } from "../../../assets/icons";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100vw;
  margin-top: 80px;
  background-color: ${colors.white};
  ${({ theme: { media } }) => media.tablet`
  height: 100vh;  
  margin-top: 0;
  `};
  ${({ theme: { media } }) => media.desktop`
 
  `};
`;

export const Verification = styled.div`
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  width: 90%;
  line-height: 24px;
  text-align: center;
  color: #000000;
  ${({ theme: { media } }) => media.tablet`
  font-size: 18px;
    width: 100%;
  `};
  ${({ theme: { media } }) => media.desktop`
  font-size: 20px;
      width: 100%;
  `};
`;

export const VerificationCodeText = styled.div`
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #000000;
  padding: 38px 0 40px;
  width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 30px;
  ${({ theme: { media } }) => media.tablet`
  font-size: 16px;
    width: 320px;
      margin-left: 0;
  `};
  ${({ theme: { media } }) => media.desktop`
  font-size: 20px;
    width: 360px;
      margin-left: 0;
  `};
`;

export const Icons = styled.div`
  width: 95px;
  height: 74px;
  margin: 20px 0;
  object-fit: cover;
  ${({ theme: { media } }) => media.tablet`
  width: 95px;
  height: 74px;
  margin: 20px 0;
  `};
  ${({ theme: { media } }) => media.desktop`
    width: 95px;
  height: 74px;
  margin: 20px 0;
  `};
`;

export const ContainerVerify = styled.div`
  width: 253px;
  height: 83px;
  padding-top: 40px;
  ${({ theme: { media } }) => media.tablet`
   height: 75px;
     width: 263px;
  `};
  ${({ theme: { media } }) => media.desktop`
   height: 85px;
  `};
`;

export const VerifyCode = styled.button`
  width: 100%;
  height: 100%;
  background-color: ${colors.primaryColor};
  border-radius: 6px;
  outline: none;
  font-weight: 700;
  border: none;
  line-height: 18px;
  font-size: 15px;
  cursor: pointer;
  color: ${colors.white};
`;

export const ImageY = styled("img").attrs({
  src: LOCK,
  alt: "Image lock verification code",
})`
  width: 100%;
  height: 100%;
  ${({ theme: { media } }) => media.desktop`
  `};
`;
