import { SecureRequest } from "utils/secureRequest";

export const getFeature = async (
  token: string,
  department_id: string,
  membership_level: number
) => {
  return await SecureRequest(token).get(
    `/banners?pagination[perPage]=10&pagination[page]=1&where[$$benefit.benefits_departments.department_id$$]=${department_id}&where[$$benefit.expiry$$][$gt]=${new Date().toISOString()}&where[$$benefit.status$$]=ACTIVE&where[$$benefit.membership.level$$][$lte]=${membership_level}`
  );
};

export const getAllFeatures = async (token: string, department_id: string, membership_level: number) => {
  return await SecureRequest(token).get(
    `/banners?where[$$benefit.benefits_departments.department_id$$]=${department_id}&where[$$benefit.expiry$$][$gt]=${new Date().toISOString()}&where[$$benefit.status$$]=ACTIVE&where[$$benefit.membership.level$$][$lte]=${membership_level}`
  );
};
