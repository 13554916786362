import { useState } from "react";
import { Dropdown, Avatar } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { S3_CREDENTIAL } from "utils/contants";

import { useViveSelector } from "../../../hooks/reduxHooks";

import UserMenu from "./UserMenu";
import UserPendingData from "./UserPendingData";
import { ContainerAvatarHeader } from "./styles";

const UserAvatar = () => {
  const { user } = useViveSelector((state) => state.user);
  const [isVisible, setIsVisible] = useState<boolean>(false);
  return (
    <Dropdown
      overlayStyle={{ left: "0px", right: "10px" }}
      overlay={
        user.status === "PENDING_DATA" ? (
          <UserPendingData />
        ) : (
          <UserMenu setIsVisible={setIsVisible} />
        )
      }
      placement="bottomLeft"
      visible={isVisible}
      trigger={["click"]}
      onVisibleChange={() => setIsVisible(!isVisible)}
    >
      <ContainerAvatarHeader>
        <Avatar
          style={{ cursor: "pointer", width: "100%", height: "100%" }}
          size="large"
          src={`${S3_CREDENTIAL.baseUrl}/${
            user.avatar ? user.avatar : "placeholders/user.png"
          }`}
          icon={<UserOutlined />}
        />
      </ContainerAvatarHeader>
    </Dropdown>
  );
};

export default UserAvatar;
