import { dictionaryIcon, dictionaryNames } from "utils/contants";

import {
  ContainerRight,
  ContentFooter,
  ContentTextBenefit,
  Discount,
  IconText,
  Text,
  TextActualPrice,
  TextBenefitCategory,
  TextBenenifitDiscount,
  TextOldPrice,
} from "./style";

type DescriptionBenefitProps = {
  title: string;
  benefit_channel: string;
  discount_price: number;
  discount: number;
  display_discount: number;
  actual_price: number;
};

const BenefitDescription = ({
  title,
  benefit_channel,
  discount_price,
  actual_price,
  discount,
  display_discount,
}: DescriptionBenefitProps) => {
  return (
    <ContainerRight>
      <Text>{title}</Text>
      <ContentTextBenefit>
        <IconText
          src={
            dictionaryIcon[benefit_channel]
              ? dictionaryIcon[benefit_channel]
              : dictionaryIcon["no_available"]
          }
        />
        <TextBenefitCategory>
          {dictionaryIcon[benefit_channel]
            ? dictionaryNames[benefit_channel]
            : "NO DISPONIBLE"}
        </TextBenefitCategory>
      </ContentTextBenefit>
      <ContentFooter>
        <TextActualPrice>
          {discount_price ? `$${discount_price}` : null}
        </TextActualPrice>
        <TextOldPrice>{actual_price ? `$${actual_price}` : null}</TextOldPrice>
        {discount && discount > 0 ? (
          <Discount>
            <TextBenenifitDiscount>
              {discount?.toFixed()}%
            </TextBenenifitDiscount>
          </Discount>
        ) : null}
        {!actual_price && !discount_price && display_discount > 0 ? (
          <Discount>
            <TextBenenifitDiscount>
              {display_discount?.toFixed()}%
            </TextBenenifitDiscount>
          </Discount>
        ) : null}
      </ContentFooter>
    </ContainerRight>
  );
};

export default BenefitDescription;
