import { CLOSE_MODAL } from "assets/icons";
import styled from "styled-components";
import { Monserrat20x500 } from "../typography/typographt";

export const ModalBackground = styled.div`
  align-items: center;
  background: rgba(32, 35, 41, 0.9);
  bottom: -10px;
  color: white;
  display: flex;
  justify-content: center;
  left: -10px;
  position: fixed;
  right: -10px;
  top: -10px;
  z-index: 100;
`;

export const ContainerModal = styled.div`
  width: 23rem;
  height: 20rem;
  background-color: #fff;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const HeaderModal = styled.div`
  width: 100%;
  height: 90px;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  background-color: #006abf;
  cursor: pointer;
`;

export const TitleHeaderModal = styled(Monserrat20x500)`
  font-size: 1.5rem;
  line-height: 3rem;
  margin: 0;
`;

export const HeaderCloseModal = styled.div`
  width: auto;
  height: auto;
  padding: 7px;
  display: flex;
  /* align-self: flex-end; */
  background-color: #cbcbcb;
  border-radius: 100px;
  margin: 5px 19px 0 0;
  align-items: center;
  cursor: pointer;
`;

export const Img = styled.img.attrs({
  src: CLOSE_MODAL,
  alt: "Icon Close Modal",
})`
  width: 20px;
  filter: contrast(5%);
`;

export const IconClose = styled.img.attrs({
  src: CLOSE_MODAL,
  alt: "Icon Close Modal",
})`
  width: 100%;
  height: 100%;
  filter: contrast(5%);
`;

export const BodyModal = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const TextPendingData = styled.h2`
  color: #000;
  text-align: center;
  font-size: 17px;
`;

export const ButtonPendingData = styled.button`
  width: 60%;
  height: 50px;
  border-radius: 1rem;
  border: none;
  outline: none;
  display: flex;
  font-size: 17px;
  font-weight: 500;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
  background-color: #006abf;
  cursor: pointer;
`;

export const HeaderModalPendingData = styled.div`
  width: 100%;
  height: 90px;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
  cursor: pointer;
`;

// STYLES FOR MODAL SELECT DEPARTMENT

export const ContainerModalDepartment = styled(ContainerModal)`
  width: 80%;
  height: 50%;

  ${({ theme: { media } }) => media.tablet`
    width: 50%;
    height: 60%;
  `};

  ${({ theme: { media } }) => media.desktop`
    width: 30%;
    height: 50%;
  `};
`;

export const IconSelectDepartment = styled.img`
  width: 100px;
  height: 100px;
  object-fit: contain;
`;

export const HeaderModalDepartment = styled(HeaderModal)`
  background-color: white;
  flex-direction: column;
  width: 100%;
  height: 150px;
`;

export const TitleHeaderDepartment = styled(TitleHeaderModal)`
  color: ${({ theme }) => theme.colors.black};
  text-align: center;
  font-size: 17px;
  font-weight: 600;
  text-transform: capitalize;
  ${({ theme: { media } }) => media.tablet`
     font-size: 20px;
  `};
  ${({ theme: { media } }) => media.desktop`
     font-size: 20px;
  `};
`;

export const SubTitleHeaderDepartment = styled(TitleHeaderDepartment)`
  color: ${({ theme }) => theme.colors.themeApp.colorPlaceholder};
  font-size: 15px;
  margin-left: -220px;
`;

export const BodySelectDepartment = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`;
