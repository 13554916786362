import { connectSearchBox } from "react-instantsearch-dom";
import { CustomInputSearch } from "./styles";

interface AlgoliaSearchProps {
  currentRefinement: string;
  isSearchStalled: boolean;
  placeholder?: string;
  refine: (...args: any) => any;
}

const CustomSearch = ({
  currentRefinement,
  placeholder = "Buscar...",
  refine,
}: AlgoliaSearchProps) => {
  return (
    <CustomInputSearch
      type="text"
      placeholder={placeholder}
      onChange={({ target }) => refine(target.value)}
      value={currentRefinement}
    />
  );
};

export default connectSearchBox(CustomSearch);
