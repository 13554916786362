import { useState } from "react";
import { toast } from "react-toastify";
import {
  _GET_CODE_BENEFIT,
  _RECLAIM_BENEFIT,
} from "redux/actions/benefitAction";
import { DepartmentItems } from "redux/redux.dto";
import {
  benefitObtain,
  benefitObtainWeb,
  benefitObtainWhatsapp,
  benefitRenew,
  pageView,
  pressBannerBenefit,
} from "utils/mixpanel";
import { useViveSelector, useViveDispatch } from "./reduxHooks";
import { getCodeBenefit as GetBenefitCode } from "api/benefits";
import { GET_CODE_BENEFIT } from "redux/types";

export const useBenefitDetail = () => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [showModalUnidrogas, setShowModalUnidrogas] = useState({
    messageResponse: "",
    showModalSucces: false,
    showModalError: false,
  });

  const { departments } = useViveSelector((state) => state.departments);
  const { user, id_token } = useViveSelector((state) => state.user);
  const { selectedBenefit, infoBeneditCode } = useViveSelector(
    (state) => state.benefits
  );
  const { whatsapp, title, url, benefit_channel, source_code } =
    selectedBenefit;
  const { first_name, last_name } = user;

  const dispatch = useViveDispatch();

  const handleOpenWhatsapp = () => {
    const data = `https://api.whatsapp.com/send?text=Hola mi nombre es *${
      first_name || "vive"
    }* *${
      last_name || "vivemas"
    }*. Deseo redimir el beneficio *${title}*, de Vive Mas. Mi código es: ${
      infoBeneditCode.code
    }&phone=57${whatsapp}`;
    if (!whatsapp)
      return toast.info(
        "Opss!. \nNo se ha encontrado ningún número de WhatsApp",
        {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    if (!infoBeneditCode.code)
      return toast.info("Opss!. \nEste beneficio no cuenta con código", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

    benefitObtainWhatsapp({
      code: infoBeneditCode.code,
      id: selectedBenefit.id,
      title,
      whatsapp,
    });
    window.open(data, "_blank");
  };

  const handlePressBannerBenefit = (pressBanner: string) => {
    if (selectedBenefit.id && pressBanner) {
      const userDepartment = departments.find(
        (depa) => depa.id === user.department_id ?? "1"
      ) as DepartmentItems;
      pressBannerBenefit({
        Name: selectedBenefit.title,
        Department: userDepartment.name,
        commerce: {
          allow_consume_in_site: selectedBenefit.commerce.allow_consume_in_site,
          allow_minors: selectedBenefit.commerce.allow_minors,
          allow_pets: selectedBenefit.commerce.allow_pets,
          description: selectedBenefit.commerce.direction,
          name: selectedBenefit.commerce.name,
        },
        Details: selectedBenefit.detail,
        Membership: {
          name: selectedBenefit.membership.name,
        },
        Price: String(selectedBenefit.actual_price),
        Type: selectedBenefit.benefit_channel,
      });
    }
  };

  const handleOpenWeb = async () => {
    try {
      if (!url)
        return toast.info("No hay un enlace el cual abrir", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      if (infoBeneditCode.code) {
        benefitObtainWeb({
          benefit_channel,
          code: infoBeneditCode.code,
          id: selectedBenefit.id,
          source_code,
          title,
          url,
          user_id: user.id,
        });
        await navigator.clipboard.writeText(
          `${infoBeneditCode.code.toLowerCase()}`
        );
        window.open(`${url}`, "_blank");
      } else {
        benefitObtainWeb({
          benefit_channel,
          code: "No Code",
          id: selectedBenefit.id,
          source_code,
          title,
          url,
          user_id: user.id,
        });
        window.open(`${url.trim()}`, "_blank");
      }
    } catch (error) {
      toast.error("Opss!. \nNo se pudo abrir el enlace", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      console.log("error try to reclaim cupon type web", error);
    }
  };

  const handleReloadCodeBenefit = async (benefit_id: string) => {
    let userDepartment = departments.find(
      (department) => department.id === user.department_id
    ) as DepartmentItems;
    await dispatch(_RECLAIM_BENEFIT(infoBeneditCode.code));
    await dispatch(_GET_CODE_BENEFIT(benefit_id));
    benefitRenew();
    benefitObtain(
      {
        actual_price: selectedBenefit.actual_price ?? 0,
        benefit_channel: selectedBenefit.benefit_channel,
        commerce: {
          allow_consume_in_site: selectedBenefit.commerce.allow_consume_in_site,
          allow_minors: selectedBenefit.commerce.allow_minors,
          allow_pets: selectedBenefit.commerce.allow_pets,
          description: selectedBenefit.commerce.direction,
          name: selectedBenefit.commerce.name,
        },
        detail: selectedBenefit.detail,
        membership: selectedBenefit.membership,
        title: selectedBenefit.title,
      },
      userDepartment.name
    );
  };

  const getCodeBenefit = (benefit_id: string) => {
    setShowModal(true);
    if (!infoBeneditCode.code) dispatch(_GET_CODE_BENEFIT(benefit_id));
    if (selectedBenefit.id && departments.length > 0) {
      let userDepartment = departments.find(
        (department) => department.id === user.department_id
      ) as DepartmentItems;
      benefitObtain(selectedBenefit, userDepartment?.name || "Atlántico");
      pageView({
        PageType: "Benefit Detail",
        Name: selectedBenefit.title,
        UserTime: new Date().toDateString(),
      });
    }
  };

  //MY CHANGES
  async function handlePressUnidrogas(benefit_id: string) {
    try {
      setIsLoading(true);
      const res = await GetBenefitCode({ token: id_token, id: benefit_id });
      if (res.data.code) {
        dispatch({
          type: GET_CODE_BENEFIT,
          payload: res.data,
        });
        setShowModalUnidrogas({
          ...showModalUnidrogas,
          showModalSucces: true,
        });
        let userDepartment = departments.find(
          (department) => department.id === user.department_id ?? "1"
        ) as DepartmentItems;
        benefitObtain(
          {
            actual_price: selectedBenefit.actual_price ?? 0,
            benefit_channel: selectedBenefit.benefit_channel,
            commerce: {
              allow_consume_in_site:
                selectedBenefit.commerce.allow_consume_in_site,
              allow_minors: selectedBenefit.commerce.allow_minors,
              allow_pets: selectedBenefit.commerce.allow_pets,
              description: selectedBenefit.commerce.direction,
              name: selectedBenefit.commerce.name,
            },
            detail: selectedBenefit.detail,
            membership: selectedBenefit.membership,
            title: selectedBenefit.title,
          },
          userDepartment.name
        );
      } else {
        dispatch({
          type: GET_CODE_BENEFIT,
          payload: {},
        });
        setShowModalUnidrogas({
          ...showModalUnidrogas,
          showModalSucces: false,
          showModalError: true,
        });
      }
      console.log(res)
    } catch (error: any) {
      setShowModalUnidrogas({
        ...showModalUnidrogas,
        messageResponse:
          error?.response?.data?.message ??
          "Lo sentimos, ya reclamaste tu cupón.",
        showModalSucces: false,
        showModalError: true,
      });
    } finally {
      setIsLoading(false);
    }
  }

  const handleCloseModalUnidrogas = () => {
    setShowModalUnidrogas({
      ...showModalUnidrogas,
      showModalSucces: false,
      showModalError: false,
    });
  };

  const handleCloseModalError = () => {
    setShowModalUnidrogas({
      ...showModalUnidrogas,
      showModalError: false,
      showModalSucces: false,
    });
  };

  return {
    handleOpenWhatsapp,
    handleOpenWeb,
    handleReloadCodeBenefit,
    setShowModal,
    getCodeBenefit,
    handlePressBannerBenefit,
    showModal,
    //my changes
    handlePressUnidrogas,
    showModalUnidrogas,
    handleCloseModalUnidrogas,
    handleCloseModalError,
    isLoading,
  };
};
