import Commerce from "components/Commerce/Commerce";
import { connectHits } from "react-instantsearch-dom";
import { CommerceItems } from "redux/redux.dto";

import { ContainerCommerceHits, TextEmpty } from "./styles";

const CustomCommerceHits = ({ hits }: any) => {
  return (
    <ContainerCommerceHits >
      {hits.length > 0 ? (
        hits.map((el: CommerceItems) => (
          <div key={el.id}>
            <Commerce {...el} />
          </div>
        ))
      ) : (
        <TextEmpty>No hay comercios disponibles</TextEmpty>
      )}
    </ContainerCommerceHits>
  );
};

export default connectHits(CustomCommerceHits);
