import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #e5e5e5;
  justify-content: flex-start;
  align-items: center;
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  place-items: center;
  ${({ theme: { media } }) => media.tablet`
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  `};
  ${({ theme: { media } }) => media.desktop`
    width: 1000px;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  `};
`;

//All features items
export const ContentImage = styled.div`
  width: 300px;
  height: 130px;
  border-radius: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  ${({ theme: { media } }) => media.tablet`
    width: 350px;
    height: 150px;
    margin: 0 5px 10px;
  `};
  ${({ theme: { media } }) => media.desktop`
    width: 350px;
    height: 150px;
    margin: 0 5px 10px;
  `};
`;

export const CardImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
`;
