import styled from "styled-components";
import { Monserrat10px400 } from "../../typography/typographt";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #e5e5e5;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 20px;
`;
export const Content = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  ${({ theme: { media } }) => media.tablet`
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
  `};
  ${({ theme: { media } }) => media.desktop`
    width: 1000px;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  `};
`;

export const EmptyAllCommerces = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
`;

//item
export const BusinessTabsdesktop = styled.div`
  display: flex;
  border-radius: 15px;
  margin: 8px 15px;
  justify-content: center;
  align-items: center;
  min-width: 300px;
  height: 103px;
  background-color: #ffffff;

  ${({ theme: { media } }) => media.tablet`
    display: flex;
    border-radius: 15px;
    margin: 10px 15px;
    justify-content: center;
    align-items: center;
    max-width: 335px;
    height: 113px;
    background-color: #ffffff;
  `};
  ${({ theme: { media } }) => media.desktop`
    display: flex;
    flex-wrap: wrap;
    colums: 3;
    justify-content: space-between;
    align-items: center;
    border-radius: 15px;
    max-width: 300px;
    height: 115px; 
    background-color: #ffffff; 
  `};
`;

export const CommerceLeft = styled.div`
  display: flex;
  width: 80px;
  margin-left: 10px;
  ${({ theme: { media } }) => media.tablet`
    width: 80px;
    margin-left: 0;
  `};
  ${({ theme: { media } }) => media.desktop``};
`;

export const ContentImage = styled.div`
  width: 60px;
  height: 60px;
  margin-left: 10px;
  ${({ theme: { media } }) => media.desktop`
    width: 60px;
    height: 60px;
    margin-left: 10px;
  `};
  ${({ theme: { media } }) => media.desktop`
    width: 60px;
    height: 60px;
  `};
`;

export const CommerceCenter = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: start;
  margin-top: 5px;
  height: 70%;
  max-width: 150px;
  ${({ theme: { media } }) => media.tablet`
    margin-top: 5px;
    height: 70%;
    margin-left: 0;
    max-width: 130px;
  `};
  ${({ theme: { media } }) => media.desktop`
    margin: 0;
    height: 100%;
    width: 98px;
  `};
`;

export const CommerceRigth = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 90px;
  justify-content: center;
  align-items: center;
  margin-right: 0.5rem;
  ${({ theme: { media } }) => media.tablet`
    display: flex;
    flex-direction: column;
    height: 100%;
    max-width: 90px;
    justify-content: center;
    align-items: center;
  `};
  ${({ theme: { media } }) => media.desktop`
    display: flex;
    flex-direction: column;
    height: 100%;
    max-width: 90px;
    justify-content: center;
    align-items: center;
  `};
`;
export const CommerceTitle = styled.h2`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 11px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #000000;
  margin-top: 12px;
  margin-bottom: -18px;
  ${({ theme: { media } }) => media.tablet`
    font-size: 11px;
    margin-bottom: -18px;
    line-height: 11px;
  `};
  ${({ theme: { media } }) => media.desktop`
    font-size: 8.5px;
    margin-bottom: -18px;
    line-height: 12px;

  `};
`;

export const CommerceText = styled.p`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: normal;
  text-transform: capitalize;
  font-size: 10px;
  line-height: 10px;
  letter-spacing: 0.02em;
  color: #000000;
  width: 100px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-top: 30px;
  ${({ theme: { media } }) => media.tablet`
     font-size: 10px;
  line-height: 10px;
  `};
  ${({ theme: { media } }) => media.desktop`
     font-size: 12px;
  line-height: 10px;
 
  `};
`;

export const Image3 = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
export const Condition = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: 4px;
  font-size: 12px;
  ${({ theme: { media } }) => media.tablet`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-right: 4px;
    font-size: 11px;   
  `};
  ${({ theme: { media } }) => media.desktop`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-right: 4px;
    font-size: 12px;
  `};
`;

export const CardReviewTextStatus = styled(Monserrat10px400)`
  margin: 3px 0;
  color: #000;
`;
export const Icon = styled.img<{
  width?: number | string;
  height?: number;
  marginRight?: number | "auto";
  marginLeft?: number | "auto";
}>`
  width: ${(props) => props.width || 20}px;
  height: ${(props) => props.height || 20}px;
  object-fit: contain;
  margin-right: 3px;
`;

export const CardReview = styled.div`
  width: 40px;
  height: 14px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
`;

export const Qualification = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: right;
  margin-right: 10px;
  font-size: 10px;
  color: #000;
  ${({ theme: { media } }) => media.tablet`
  display: flex;
  flex-direction: row;
  justify-content: right;
  margin-right: 10px;
        font-size: 11px;
        color: #000;
  `};
  ${({ theme: { media } }) => media.desktop`
  display: flex;
  flex-direction: row;
  justify-content: right;
  margin-right: 10px;
        font-size: 12px;
        color: #000;
  `};
`;

export const ImagenReview = styled.img`
  width: 11px;
  height: 10px;
`;
