import { css } from "styled-components";
import { mediaSizes } from "types/theme";

interface ScreenSizes {
  tablet: Function;
  largeMobile: Function;
  desktop: Function;
  largeDesktop: Function;
  wrap: Function;
  giantDesktop: Function;
  hugeDesktop: Function;
}

export const sizes: mediaSizes = {
  mobile: 360,
  largeMobile: 540,
  tablet: 768,
  desktop: 1024,
  largeDesktop: 1280,
  wrap: 1365,
  giantDesktop: 1600,
  hugeDesktop: 1920,
};

export const media = Object.keys(sizes).reduce((acc: any, label: string) => {
  acc[label] = (...args: any) => css`
    @media (min-width: ${sizes[label]}px) {
      ${css(args)}
    }
  `;

  return acc;
}, {}) as ScreenSizes;
