import { ContainerFirmOnes, IfrimeFirm } from "./styles";
import { firmOnes } from "../../utils/contants";

function IFrimeFirmOnes() {
  return (
    <ContainerFirmOnes>
      <IfrimeFirm src={firmOnes.urlFirmOnes} />
    </ContainerFirmOnes>
  );
}

export default IFrimeFirmOnes;
