export const imageHandler = (key: string, width?: number, height?: number) => {
  return `https://dnfhqmc2wswkq.cloudfront.net/${window.btoa(
    JSON.stringify({
      bucket: "vivesmas-prod-app-origin",
      key: key,
      edits: {
        resize: {
          width,
          height,
          fit: "cover",
        },
      },
    })
  )}`;
};
