import { Fragment } from "react";
import { Navigate } from "react-router-dom";
import { useViveSelector } from "../hooks/reduxHooks";

type PrivateRouteProps = {
  children: JSX.Element | JSX.Element[];
};

export function PrivateRoute({ children }: PrivateRouteProps) {
  const { isAuth } = useViveSelector((state) => state.user);
  if (!isAuth) return <Navigate to="/signin" />;
  return <Fragment>{children}</Fragment>;
}
