import { Fragment } from "react";
import { S3_CREDENTIAL } from "utils/contants";
import { Link } from "react-router-dom";

import { useViveSelector } from "../../../hooks/reduxHooks";

import {
  ContainerMenuGlobal2,
  ContainerMenuPerfile,
  Content,
  IconPerfile,
  UserImage,
  UpdateButton,
} from "./styles";

const UserPendingData = () => {
  const { user } = useViveSelector((st) => st.user);
  return (
    <Fragment>
      <ContainerMenuGlobal2>
        <ContainerMenuPerfile>
          <IconPerfile>
            <UserImage
              src={`${S3_CREDENTIAL.baseUrl}/${
                user.avatar ? user.avatar : "placeholders/user.png"
              }`}
              alt="user image profile"
            />
          </IconPerfile>
          <Content>
            Actualiza tus datos para disfrutar de los mejores beneficios.
          </Content>
          <Link to={`/complete-profile`}>
            <UpdateButton>Actualizar</UpdateButton>
          </Link>
        </ContainerMenuPerfile>
      </ContainerMenuGlobal2>
    </Fragment>
  );
};

export default UserPendingData;
