import styled from "styled-components";

export const ButtonTwo = styled.div<{ isSelectedCategory: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ isSelectedCategory }) =>
    isSelectedCategory ? "#006ABF" : "#fff"};
  border-radius: 8px;
  padding: 0 9px;
  width: max-content;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.28);
  ${({ theme: { media }, isSelectedCategory }) => media.tablet`
    background-color: ${isSelectedCategory ? "#006ABF" : "#fff"};
    border-radius: 8px;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: space-evenly;
    padding: 5px;
  `};
  ${({ theme: { media }, isSelectedCategory }) => media.desktop`
    background-color: ${isSelectedCategory ? "#006ABF" : "#fff"};
    border-radius: 8px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 4px 8px;
  `};
`;

export const Image = styled.img`
  width: 35px;
  height: 35px;
  object-fit: cover;
  border-radius: 50px;

  ${({ theme: { media } }) => media.tablet`
    width: 30px;
    height: 30px;
    object-fit: cover;
    border-radius: 100px;
  `};
  ${({ theme: { media } }) => media.desktop`
    width: 38px;
    height:38px;
    object-fit: cover;
    border-radius: 100px;
  `};
`;
export const TitleCategory = styled.h3<{ isSelectedCategory: boolean }>`
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  text-transform: capitalize;
  margin: 12px;
  color: ${({ isSelectedCategory }) => (isSelectedCategory ? "#fff" : "black")};
  ${({ theme: { media } }) => media.tablet`
    font-size: 12px;
    line-height: 14px;
  `};
  ${({ theme: { media } }) => media.desktop`
    font-size: 14px;
    line-height: 15px;
  `};
`;
