import { useNavigate } from "react-router-dom";

import { IFeaturesItem } from "redux/redux.dto";
import { S3_CREDENTIAL } from "utils/contants";
import { imageHandler } from "../../../utils/imageHandlers";

import { CardImage, ContentImage } from "./styles";

function AllFeatureItem({ path, benefit_id }: IFeaturesItem) {
  const navigate = useNavigate();

  const handleClick = (benefit_id: string) => {
    if (benefit_id) {
      navigate(`/benefit-detail/${benefit_id}`);
    }
  };

  return (
    <ContentImage onClick={() => handleClick(benefit_id)}>
      <CardImage
        src={
          path
            ? imageHandler(path)
            : `${S3_CREDENTIAL.baseUrl}/placeholders/commerce_banner.png`
        }
      />
    </ContentImage>
  );
}

export default AllFeatureItem;
