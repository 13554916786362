import { ColorsMap } from "types/theme";

// Core
const white = "#ffffff";
const offWhite = "#FAFAFA";
const black = "#000000";
const grey = "#7f7f7f";
const gray3b = "#63686d";
const gray4 = "#7A7E83";
const gray10 = "#FAFAFA";
const primaryColor = "#0077B6";

const ViveMasTheme = {
  primary: "#006ABF",
  secundary: "#36A8E0",
  white: "#FFFFFF",
  black: "#000",
  alert: "#FF9B04",
  home: "#f5f5f5",
  colorPlaceholder: "#8C8C8C",
  login: "#0177B6",
  loginCircle: "#36A8E0",
  loginBorderInput: "#CDCDCD",
  header: "#006ABF",
  textInput: "#000",
  subTitleCommerce: "#000",
  tintColor: "#000",
  colorInputFile: "#b3b3b3",
  selected: "#8fe3ff",
  colorAlertError: "#ff0000",
  colorEditInfo: "#006abd",
  logOut: "#565656",
};
export const colors: ColorsMap = {
  primaryColor,
  black,
  white,
  offWhite,
  grey,
  gray3b,
  gray4,
  gray10,
  themeApp: ViveMasTheme,
};
