import { useEffect } from "react";
import HeaderSection from "components/common/HeaderSection";

import { useViveSelector, useViveDispatch } from "../../../../hooks/reduxHooks";
import { _CLEAN_COMMERCES, _GET_COMMERCES } from "redux/actions/commerceAction";
import Commerce from "components/Commerce/Commerce";
import { useUserMembershipLevel } from "../../../../hooks/useMembershipLevel";
import { CommerceItems } from "redux/redux.dto";
import { TextEmpty } from "../HomeCategories/styles";

import {
  ContainerHomeCommerces,
  RetailContainer,
  BusinessTabsOver,
} from "./styles";

function HomeCommerce() {
  const { membershipLevel } = useUserMembershipLevel();
  const dispatch = useViveDispatch();
  const { commerces } = useViveSelector((state) => state.commerces);
  const { user } = useViveSelector((state) => state.user);

  useEffect(() => {
    if (user.department_id) {
      dispatch(_CLEAN_COMMERCES());
    }
  }, [user.department_id, dispatch]);

  useEffect(() => {
    if (membershipLevel.level || membershipLevel.level === 0) {
      dispatch(
        _GET_COMMERCES(membershipLevel.level, user.department_id || "1")
      );
    }
  }, [user.department_id, dispatch, membershipLevel.level]);

  return (
    <ContainerHomeCommerces>
      <HeaderSection
        textLeft="comercios"
        textRight="Ver Más"
        screenView="/show-all-commerces"
      />
      <BusinessTabsOver>
        <RetailContainer>
          {commerces && commerces.length > 0 ? (
            commerces.map((el: CommerceItems) => (
              <Commerce key={el.id} {...el} />
            ))
          ) : (
            <TextEmpty>
              No hay comercios disponibles para esta región!
            </TextEmpty>
          )}
        </RetailContainer>
      </BusinessTabsOver>
    </ContainerHomeCommerces>
  );
}

export default HomeCommerce;
