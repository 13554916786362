import { vivemasApi } from "../utils/request";
import { loginProps, verificationProps } from "./api.dto";

export const loginOtp = ({ phone }: loginProps) => {
  return vivemasApi.post("/auth/otp", {
    phone,
  });
};

export const verificationOtp = ({ code }: verificationProps) => {
  return vivemasApi.post("/auth/otp-verification", {
    activation_code: code,
  });
};
