import { Fragment } from "react";
import { Link } from "react-router-dom";

import { useViveSelector } from "../../../../hooks/reduxHooks";

import {
  ContainerAlert,
  ContainerInfoAlert,
  ImageAlert,
  TextAlert,
} from "./styles";

const AlertCompleteProfile = () => {
  const { user } = useViveSelector((s) => s.user);

  return (
    <Fragment>
      {user.status === "PENDING_DATA" && (
        <ContainerAlert>
          <Link to="/complete-profile">
            <ContainerInfoAlert>
              <ImageAlert />
              <TextAlert>
                Te recomendamos completar tus datos para disfrutar de todos
                nuestros beneficios
              </TextAlert>
            </ContainerInfoAlert>
          </Link>
        </ContainerAlert>
      )}
    </Fragment>
  );
};

export default AlertCompleteProfile;
