import { Fragment } from "react";
import { DatePicker, Select, Spin } from "antd";
import CustomInput from "components/common/CustomInput";
import { useViveSelector } from "../../../hooks/reduxHooks";
import moment from "moment";
import { S3_CREDENTIAL } from "utils/contants";

import {
  CompleteInformation,
  ContainerInput,
  CustomSelect,
  TextCompleteInfo,
  TextError,
  RadiusBorder,
} from "../Profile/styles";
import {
  ContainerImage,
  ContainerImagUser,
  EditProfile,
  ImgPencil,
  ImgUserProfile,
  Label,
  UploadImage,
} from "./styles";

type InputsEditProfileProps = {
  handleBlur: Function;
  handleChange: Function;
  values: any;
  errors: any;
  touched: any;
  setFieldValue: Function;
  handleSubmit: () => void;
  isLoading: boolean;
  setNewImage: any;
  newImage: any;
};

const InputsEditProfile = ({
  errors,
  handleBlur,
  handleChange,
  handleSubmit,
  isLoading,
  setFieldValue,
  touched,
  values,
  setNewImage,
  newImage,
}: InputsEditProfileProps) => {
  const { user } = useViveSelector((state) => state.user);
  const url = `${S3_CREDENTIAL.baseUrl}/${
    user.avatar ? user.avatar : "placeholders/user.png"
  }`;

  const handleChangeImage = ({ target }: any) => {
    const { files } = target;
    if (files && files[0]) {
      setNewImage(files[0]);
    }
  };
  return (
    <Fragment>
      <ContainerImage>
        <ContainerImagUser>
          <ImgUserProfile
            src={newImage.name ? URL.createObjectURL(newImage) : url}
            alt={user.first_name}
          />
        </ContainerImagUser>
        <Label>
          <EditProfile>
            <ImgPencil />
          </EditProfile>
          <UploadImage onChange={handleChangeImage} />
        </Label>
      </ContainerImage>

      <ContainerInput>
        <CustomInput
          placeHolder="Número De Documento"
          onBlur={handleBlur("identification_number")}
          type="text"
          onChangeText={handleChange("identification_number")}
          value={values.identification_number}
          editable={user.identification_number ? true : false}
          maxLength={10}
        />
        {errors.identification_number && touched.identification_number && (
          <TextError>{errors.identification_number}</TextError>
        )}
      </ContainerInput>
      <ContainerInput>
        <RadiusBorder>
          <CustomSelect
            placeholder="Documento De Identidad"
            value={values.document_type}
            onChange={handleChange("document_type")}
            onBlur={handleBlur("document_type")}
            disabled={user.document_type ? true : false}
          >
            <Select.Option value="1">Cédula Ciudadania</Select.Option>
            <Select.Option value="2">Nit</Select.Option>
            <Select.Option value="3">Tarjeta Identidad</Select.Option>
            <Select.Option value="4">Registro Civil</Select.Option>
            <Select.Option value="5">Cédula Extrangeria</Select.Option>
            <Select.Option value="6">Cédula Europea</Select.Option>
            <Select.Option value="7">Tarjeta Extrangeria</Select.Option>
            <Select.Option value="8">Pasaporte</Select.Option>
            <Select.Option value="9">Visa</Select.Option>
            <Select.Option value="10">
              Número Único Identificación Personal
            </Select.Option>
            <Select.Option value="11">
              Número Identidad Extranjero
            </Select.Option>
            <Select.Option value="12">Otro</Select.Option>
          </CustomSelect>
        </RadiusBorder>
        {errors.document_type && touched.document_type && (
          <TextError>{errors.document_type}</TextError>
        )}
      </ContainerInput>
      <ContainerInput>
        <CustomInput
          placeHolder="Nombre"
          onBlur={handleBlur("first_name")}
          type="text"
          onChangeText={handleChange("first_name")}
          value={values.first_name}
        />
        {errors.first_name && touched.first_name && (
          <TextError>{errors.first_name}</TextError>
        )}
      </ContainerInput>
      <ContainerInput>
        <CustomInput
          placeHolder="Segundo Nombre"
          onBlur={handleBlur("second_name")}
          type="text"
          onChangeText={handleChange("second_name")}
          value={values.second_name}
        />
        {errors.second_name && touched.second_name && (
          <TextError>{errors.second_name}</TextError>
        )}
      </ContainerInput>
      <ContainerInput>
        <CustomInput
          placeHolder="Apellido"
          onBlur={handleBlur("last_name")}
          type="text"
          onChangeText={handleChange("last_name")}
          value={values.last_name}
        />
        {errors.last_name && touched.last_name && (
          <TextError>{errors.last_name}</TextError>
        )}
      </ContainerInput>
      <ContainerInput>
        <CustomInput
          placeHolder="Segundo Apellido"
          onBlur={handleBlur("second_last_name")}
          type="text"
          onChangeText={handleChange("second_last_name")}
          value={values.second_last_name}
        />
        {errors.second_last_name && touched.second_last_name && (
          <TextError>{errors.second_last_name}</TextError>
        )}
      </ContainerInput>
      <ContainerInput>
        <CustomInput
          placeHolder="Email"
          onBlur={handleBlur("email")}
          type="email"
          onChangeText={handleChange("email")}
          value={values.email}
        />
        {errors.email && touched.email && <TextError>{errors.email}</TextError>}
      </ContainerInput>
      <ContainerInput>
        <CustomInput
          placeHolder="NIC"
          onBlur={handleBlur("nic")}
          type="text"
          onChangeText={handleChange("nic")}
          value={values.nic}
        />
        {errors.nic && touched.nic && <TextError>{errors.nic}</TextError>}
      </ContainerInput>
      <ContainerInput>
        <CustomInput
          placeHolder="Teléfono Fijo (065) 000 0000"
          onBlur={handleBlur("landline")}
          type="text"
          onChangeText={handleChange("landline")}
          value={values.landline}
        />
        {errors.landline && touched.landline && (
          <TextError>{errors.landline}</TextError>
        )}
      </ContainerInput>
      <ContainerInput>
        <CustomSelect
          size="large"
          placeholder="Seleccione Sexo"
          style={{ width: "100%" }}
          value={values.sex}
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option!.children as unknown as string)
              .toLowerCase()
              .includes(input.toLowerCase())
          }
          onChange={handleChange("sex")}
          onBlur={handleBlur("sex")}
        >
          <Select.Option value="M">Masculino</Select.Option>
          <Select.Option value="F">Femenino</Select.Option>
          <Select.Option value="OT">Otro</Select.Option>
        </CustomSelect>
        {errors.sex && touched.sex && <TextError>{errors.sex}</TextError>}
      </ContainerInput>
      <ContainerInput>
        <Select
          value={values.customer_quality}
          onChange={handleChange("customer_quality")}
          onBlur={handleBlur("customer_quality")}
          size="large"
          placeholder="Calidad Del Cliente"
          style={{ width: "100%" }}
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option!.children as unknown as string)
              .toLowerCase()
              .includes(input.toLowerCase())
          }
        >
          <Select.Option value="P">Propietario</Select.Option>
          <Select.Option value="A">Arrendador</Select.Option>
          <Select.Option value="E">Encargado</Select.Option>
          <Select.Option value="PA">Pagador</Select.Option>
        </Select>
        {errors.customer_quality && touched.customer_quality && (
          <TextError>{errors.customer_quality}</TextError>
        )}
      </ContainerInput>
      <ContainerInput>
        <DatePicker
          format="DD/MM/YYYY"
          value={values.birthday_date ? moment(values.birthday_date) : null}
          onChange={(e) => setFieldValue("birthday_date", e)}
          onBlur={handleBlur("birthday_date")}
          placeholder="Fecha De Nacimiento"
          size="large"
          style={{ width: "100%" }}
        />
        {errors.birthday_date && touched.birthday_date && (
          <TextError>{errors.birthday_date}</TextError>
        )}
      </ContainerInput>
      <CompleteInformation onClick={handleSubmit}>
        {isLoading ? (
          <Spin size="large" />
        ) : (
          <TextCompleteInfo>Actualizar Datos</TextCompleteInfo>
        )}
      </CompleteInformation>
    </Fragment>
  );
};

export default InputsEditProfile;
