import { Modal, Rate } from "antd";
import { useState } from "react";
import { toast } from "react-toastify";
import {
  _GET_COMMERCES_BY_ID,
  _SEND_REVIEW_COMMERCE,
} from "redux/actions/commerceAction";
import styled from "styled-components";
import { useViveSelector, useViveDispatch } from "../../../hooks/reduxHooks";

type ModalReviewProps = {
  isVisble: boolean;
  calcel: () => void;
};

const ModalReview = ({ isVisble, calcel }: ModalReviewProps) => {
  const [review, setReview] = useState<number>(1);
  const [comentaryReview, setComentaryReview] = useState<string>("");
  const { user } = useViveSelector((state) => state.user);
  const { id } = useViveSelector((state) => state.commerces.selectedCommerce);
  const dispatch = useViveDispatch();

  const handleSubmit = async () => {
    if (!comentaryReview.trim())
      return toast.info("Para enviar tu review debes ingresar un comentario", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

    const infoReview = {
      user_id: user.id,
      comment: comentaryReview,
      score: review,
      commerce_id: id,
    };
    calcel();
    await dispatch(_SEND_REVIEW_COMMERCE(infoReview));
    dispatch(_GET_COMMERCES_BY_ID(id));
    setComentaryReview("");
    setReview(1);
  };

  return (
    <ContainerModal
      visible={isVisble}
      onCancel={calcel}
      onOk={handleSubmit}
      title="Deja tus calificaciones"
      okText="Enviar Review"
      cancelText="Cancelar"
      width={400}
    >
      <ContainerRate>
        <CustomRate
          defaultValue={1}
          className="customRate"
          value={review}
          onChange={(e) => setReview(e)}
        />
      </ContainerRate>
      <br />
      <ModalFooter>
        <span>Escribe un comentario</span>
        <br />
        <InputModal
          type="text"
          value={comentaryReview}
          onChange={({ target }) => setComentaryReview(target.value)}
        ></InputModal>
      </ModalFooter>
    </ContainerModal>
  );
};

export default ModalReview;

const ModalFooter = styled.div`
  width: 100%;
  height: auto;
  margin-top: 20px;
`;

const ContainerModal = styled(Modal)``;

const InputModal = styled.input`
  width: 100%;
  height: 50px;
  outline: none;
  border: 1px solid gray;
  border-radius: 8px;
  margin: 12px 0;
  padding: 0 12px;
  font-size: 18px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
`;

const ContainerRate = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const CustomRate = styled(Rate)`
  width: 100%;
  height: 100%;
  font-size: 47px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
