import {PayButtonContainer, PayBill, Text } from "./styles"
import PayIcon from "../../assets/icons/PayIcon.svg"

export const PayBillButton = () => {
    return  (
        <PayButtonContainer>
            <a href="https://qr.link/sV4UWd">
            <PayBill>
                <img src={PayIcon} alt="tarjeta" />
                <Text>
                    Paga tu factura
                </Text>
            </PayBill>
            </a>
        </PayButtonContainer>
    )
}

