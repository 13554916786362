import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { validationSchema } from "utils/ValidationSchemas";
import { Modal } from "antd";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { _UPDATE_STATE_USER_INFO } from "redux/actions/userAction";

import { useViveSelector, useViveDispatch } from "../../../hooks/reduxHooks";
import { updateInfoUser, uploadImageUser } from "../../../api/user";
import HeaderProfile from "../Profile/HeaderProfile";

import InputsEditProfile from "./InputsEditProfile";
import { Container, Content } from "./styles";
import { pageView, updateUserProfile } from "utils/mixpanel";

const EditProfile = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [newImage, setNewImage] = useState<any>({});
  const { user, id_token } = useViveSelector((state) => state.user);
  const dispatch = useViveDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    pageView({
      PageType: "Edit Profile Web",
      Name: "Edit Personal Information Web",
      UserTime: new Date().toDateString(),
    });
    return () => {
      setNewImage({});
    };
  }, []);

  const handleUpdateProfile = async (rest: any) => {
    try {
      setIsLoading(true);
      let imgRes: any = {};
      if (newImage.name) {
        const img = await uploadImageUser(id_token, newImage);
        imgRes = img;
        if (!img?.data?.path)
          return toast.error("falló al subir la imagén", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });

        toast.success("Imagén subida correctamente", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      updateUserProfile({
        name: rest.first_name,
        lastName: rest.last_name,
        identification: rest.identification_number,
        email: rest.email,
        nic: rest.nic,
        document_type: rest.document_type,
        birthday_date: rest.birthday_date,
        sex: rest.sex,
        role: user.role,
      });
      const response = await updateInfoUser({
        ...rest,
        avatar: imgRes.data?.path,
        id: user.id,
        id_token,
      });

      if (response.status === 200) {
        dispatch(_UPDATE_STATE_USER_INFO(response.data));
        Modal.success({
          content: "Datos actualizados con éxito!",
          onOk: () => navigate(-1),
        });
      } else {
        setIsLoading(false);
        Modal.error({
          content: "Opps!, no se pudo actualizar tus datos.",
        });
      }
    } catch (err: any) {
      console.log("err on handleUpdateProfile", err);
      Modal.error({
        content:
          err.response.data.message || "Opps! no se pudo enviar tus datos.",
      });
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <Container>
      <Content>
        <HeaderProfile title="Editar Perfil" />
        <Formik
          validateOnMount
          enableReinitialize
          validationSchema={validationSchema}
          initialValues={{
            identification_number: user.identification_number,
            first_name: user.first_name,
            second_name: user.second_name,
            email: user.email,
            nic: user.nic,
            customer_quality: user.customer_quality,
            document_type: user.document_type,
            last_name: user.last_name,
            second_last_name: user.second_last_name,
            landline: user.landline,
            birthday_date: user.birthday_date,
            sex: user.sex,
            avatar: user.avatar,
          }}
          onSubmit={async (values) => handleUpdateProfile(values)}
        >
          {({
            errors,
            values,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            touched,
          }) => (
            <InputsEditProfile
              errors={errors}
              handleBlur={handleBlur}
              handleChange={handleChange}
              handleSubmit={handleSubmit}
              touched={touched}
              values={values}
              setFieldValue={setFieldValue}
              isLoading={isLoading}
              setNewImage={setNewImage}
              newImage={newImage}
            />
          )}
        </Formik>
      </Content>
    </Container>
  );
};

export default EditProfile;
