import { useNavigate } from "react-router-dom";
import { S3_CREDENTIAL } from "utils/contants";
import { imageHandler } from "utils/imageHandlers";
import { Image2, MenuImage } from "./styles";
import { useViveSelector } from "../../../../hooks/reduxHooks";
import { UserPendingData } from "../../../../hooks/userPendingData";
import ModalUserPendingData from "components/modals/UserPendingData";

const Slide = ({ path, benefit_id }: any) => {
  const { user } = useViveSelector((state) => state.user);
  const navigate = useNavigate();
  const { handleNavigatePendingData, pendingData, setPendingData } =
    UserPendingData();

  const seeDetails = (benefitId: string) => {
    if (user.status === "PENDING_DATA") {
      return setPendingData(true);
    }
    if (benefitId)
      navigate(`/benefit-detail/${benefitId}`, {
        state: {
          data: "pressBannerBenefit",
        },
      });
  };

  if (pendingData) {
    return (
      <ModalUserPendingData
        handleClose={setPendingData}
        handleNavigatePendingData={handleNavigatePendingData}
      />
    );
  }

  return (
    <MenuImage onClick={() => seeDetails(benefit_id)}>
      <Image2
        src={`
            ${
              path
                ? imageHandler(path)
                : `${S3_CREDENTIAL.baseUrl}/placeholders/commerce_banner.png`
            }
          
          `}
      />
    </MenuImage>
  );
};

export default Slide;
