import { ICON_EDIT_PROFILE } from "assets/icons";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
  background-color: #efefef;
  padding-bottom: 40px;
  padding-top: 40px;
  ${({ theme: { media } }) => media.tablet`
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
     width: 100%;
    height: 100vh;
     background-color: #EFEFEF;
  `};
  ${({ theme: { media } }) => media.desktop`
    /* display: flex; */
    flex-direction: column;
    justify-content: center;
    align-items: center;
     width: 100%;
    height: 100%;
     background-color: #EFEFEF;

     
  `};
`;

export const Content = styled.div`
  width: 90%;
  padding: 29px 27px 37px 27px;
  height: auto;
  background: #ffffff;
  border-radius: 20px;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.2);
  ${({ theme: { media } }) => media.tablet`
    width: 65%;
    padding: 29px 37px 37px 37px;
    height: auto;
    background: #FFFFFF;
         box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.2);
    border-radius: 20px;  
  `};
  ${({ theme: { media } }) => media.desktop`
    width: 450px;
    padding: 29px 37px 37px 37px;
    height: auto;
    background: #fff;
     box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.2);
    border-radius: 20px;  
  `};
`;

export const ContainerImage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  height: 130px;
  margin-bottom: 30px;
`;
export const UploadImage = styled.input.attrs({
  type: "file",
  accept: "image/png, image/jpeg",
})`
  cursor: pointer;
  border-radius: 100px;
  display: none;
`;

export const EditProfile = styled.div`
  width: 45px;
  height: 45px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #006abf;
`;

export const ImgPencil = styled.img.attrs({
  src: ICON_EDIT_PROFILE,
  alt: "Icon edit profile",
})`
  width: 65%;
  height: 65%;
  object-fit: contain;
`;

export const ImgUserProfile = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 100px;
  object-fit: cover;
`;

export const ContainerImagUser = styled.div`
  width: 130px;
  height: 100%;
  border-radius: 100px;
  border: 1px dashed gray;
`;
export const Label = styled.label``;
