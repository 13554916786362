import { useMemo, useState } from "react";
import { useViveSelector } from "./reduxHooks";
import { UserMembership, UserMembershipAlternate } from "../redux/redux.dto";

export const useUserMembershipLevel = () => {
  const { user, userMemberships } = useViveSelector((state) => state.user);
  const [membershipLevel, setMembershipLevel] = useState<
    UserMembership | UserMembershipAlternate
  >({
    id: "",
    name: "",
    level: 0,
  });

  useMemo(() => {
    if (user.id && userMemberships.length > 0) {
      const currentLevel = userMemberships.find(
        (el) => el.id === user.membership_id
      ) as UserMembership;
      return setMembershipLevel(currentLevel);
    }
    setMembershipLevel({ id: "2", name: "Básico", level: 0 });
  }, [user.id, user.membership_id, userMemberships]);

  return {
    membershipLevel,
  };
};
