import { CLOSE_MODAL } from "assets/icons";
import {
  Monserrat12px500,
  Monserrat20x500,
} from "components/typography/typographt";
import styled from "styled-components";
import { BACKGROUND_BANNER, BACKGROUND_BANNER2 } from "assets/image";

export const ContainerBanner = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 4.5rem;
  margin-bottom: 1rem;
  background-image: url(${BACKGROUND_BANNER});
  object-fit: contain;
  background-color: transparent;
  ${({ theme: { media } }) => media.tablet`
    display: none;
  `};
  ${({ theme: { media } }) => media.desktop`
    display: none;
  `};
`;

export const Content = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  padding: 1rem 0 0 0;
  justify-content: space-between;
  align-items: flex-end;
  background-image: url(${BACKGROUND_BANNER2});
  background-repeat: round;
`;

export const ContentBody = styled.div`
  margin: 0 15px;
`;
export const ContentBodyTitle = styled(Monserrat12px500)`
  color: #006abf;
  font-weight: bold;
  font-size: 16.5px;
  line-height: 22px;
  margin: 3px 0;
`;

export const ContentBodySubTitle = styled(Monserrat12px500)`
  color: #006abf;
  font-size: 13px;
  line-height: 14px;
`;

export const ContentImg = styled.img`
  width: 65px;
  height: 100%;
  object-fit: contain;
  margin: 0 6px;
`;

export const HeaderModal = styled.div`
  width: 100%;
  height: 90px;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  background-color: #006abf;
  cursor: pointer;
`;

export const TitleHeaderModal2 = styled(Monserrat20x500)`
  font-size: 1.2rem;
  line-height: 3rem;
  margin: 0;
`;

export const ContentClose = styled.div`
  display: flex;
  width: 60px;
  background-color: #006abf;
  border-radius: 100px;
  margin: 0 6px;
  align-self: center;
`;

export const Img = styled.img.attrs({
  src: CLOSE_MODAL,
  alt: "Icon Close Modal",
})`
  width: 100%;
  height: 100%;
  padding: 10px;
  filter: invert(100%);
`;

export const GoDownloadApp = styled.button`
  width: auto;
  height: 100%;
  padding: 9px;
  border: none;
  border-radius: 138px;
  margin: 5px 0 8px 0;
  background-color: #006abf;
  color: white;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  line-height: 14.63px;
  font-size: 12px;
`;
