import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import LoaderApp from "components/loader/LoaderApp";
import HeaderDetail from "components/common/HeaderDetail";

import { useViveDispatch, useViveSelector } from "../../../hooks/reduxHooks";
import {
  _CLEAN_INFO_COMMERCE,
  _GET_BENEFIT_COMMMERCE_BY_ID,
  _GET_COMMERCES_BY_ID,
  _GET_SCORES_BENEFIT,
} from "../../../redux/actions/commerceAction";

import ContentCommerce from "./ContentCommerce";
import { OverallContainer } from "./styles";
import { pageView } from "utils/mixpanel";

const CommerceDetail: React.FC = () => {
  const { id } = useParams();
  const dispatch = useViveDispatch();
  const { isLoadingDetailCommerce } = useViveSelector(
    (state) => state.commerces
  );
  const { selectedCommerce } = useViveSelector((state) => state.commerces);

  useEffect(() => {
    dispatch(_GET_SCORES_BENEFIT());
    dispatch(_GET_BENEFIT_COMMMERCE_BY_ID(id!));
    dispatch(_GET_COMMERCES_BY_ID(id!));
    return () => {
      dispatch(_CLEAN_INFO_COMMERCE());
    };
  }, [id, dispatch]);

  useEffect(() => {
    if (selectedCommerce.id) {
      pageView({
        PageType: "Commerce Detail Web",
        Name: selectedCommerce.name,
        UserTime: new Date().toDateString(),
      });
    }
  }, [selectedCommerce]);

  if (isLoadingDetailCommerce) return <LoaderApp />;
  return (
    <OverallContainer>
      <HeaderDetail
        banner_image={selectedCommerce.banner_image}
        description={selectedCommerce.description}
        image={selectedCommerce.image}
        name={selectedCommerce.name}
        score={selectedCommerce.score}
      />
      <ContentCommerce />
    </OverallContainer>
  );
};

export default CommerceDetail;
