import { getMemberships, login, setDeleteAccount } from "api/user";
import {
  SIGN_IN,
  UPDATE_STATE_USER_INFO,
  USER_INFO_OTP,
  USER_INFO_OTP_ERROR,
  USER_LOGOUT,
  USER_MEMBERSHIPS,
  VERIFY_USER_LOGGED,
} from "../types";
import { ID_TOKEN_STOTAGE } from "utils/contants";
import { toast } from "react-toastify";
import { ThunkAction } from "redux-thunk";
import { ActionReturnProps, IUserReducer } from "../redux.dto";

export const _USER_LOGIN = (data: any) => async (dispatch: any) => {
  const response = await login(data);
  dispatch({
    type: _USER_LOGIN,
    payload: response.data,
  });
};

export const _USER_INFO_OTP = (data: any) => async (dispatch: any) => {
  try {
    localStorage.setItem(ID_TOKEN_STOTAGE, JSON.stringify(data.access_token));
    dispatch({
      type: USER_INFO_OTP,
      payload: data,
    });
  } catch (err) {
    console.log("error user info otp", err);
    dispatch({
      type: USER_INFO_OTP_ERROR,
    });
  }
};

export const _SIGN_IN = () => {
  return {
    type: SIGN_IN,
  };
};

export const _VERIFY_USER_LOGGED =
  (data: object, token: string) => async (dispatch: any) => {
    try {
      const info = {
        user: data,
        id_token: token,
      };
      dispatch({
        type: VERIFY_USER_LOGGED,
        payload: info,
      });
    } catch (error) {
      console.log("error try loggued user");
    }
  };

export const _UPDATE_STATE_USER_INFO = (data: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: UPDATE_STATE_USER_INFO,
      payload: data,
    });
  } catch (err) {
    console.log("error update state user info", err);
  }
};

export const _USER_LOGOUT = () => async (dispatch: any) => {
  localStorage.removeItem(ID_TOKEN_STOTAGE);
  dispatch({
    type: USER_LOGOUT,
  });
};

export const _USER_DELETE_ACCOUNT =
  (useId: string) => async (dispatch: any, getState: Function) => {
    try {
      const token = getState().user.id_token;
      const response = await setDeleteAccount(token, useId);
      if (response?.status === 200) {
        toast("Cuenta Eliminada. Tu cuenta está en proceso de eliminación", {
          position: "top-center",
          autoClose: 3500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      console.log("error try to delete account");
    }
  };

export const _GET_USER_MEMBERSHIPS =
  (): ThunkAction<Promise<void>, IUserReducer, any, ActionReturnProps> =>
  async (dispatch, getState: Function) => {
    try {
      const token = getState().user.id_token;
      const res = await getMemberships(token);
      const { data } = res;
      dispatch({
        type: USER_MEMBERSHIPS,
        payload: data.data,
      });
    } catch (error) {
      console.log("error get memberships", error);
    }
  };
