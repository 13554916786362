import styled from "styled-components";

import {
  Monserrat10px400,
  Monserrat39px700,
} from "components/typography/typographt";

type BenefitGenericProps = {
  code: string;
};
const BenefitGeneric = ({ code }: BenefitGenericProps) => {
  return <TextCodeType>{code || ""}</TextCodeType>;
};

export default BenefitGeneric;

export const TextCode = styled(Monserrat10px400)`
  color: black;
`;
export const TextCodeType = styled(Monserrat39px700)`
  font-size: 30px;
  color: black;
  text-transform: uppercase;
`;
