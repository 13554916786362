import { Fragment } from "react";

interface Props<T> extends React.HTMLAttributes<HTMLDivElement> {
  data: T[];
  renderItem: (item: T) => JSX.Element;
}

export function CustomMap<T>(props: Props<T>) {
  const { data, renderItem } = props;
  return (
    <Fragment>
      {data.map((item: T) => {
        return renderItem(item);
      })}
    </Fragment>
  );
}
