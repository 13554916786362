import { useEffect, Fragment } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { Orbit } from "@uiball/loaders";
import "swiper/css";

import { useViveSelector } from "hooks/reduxHooks";
import Benefit from "components/Benefit/Benefit";
import { ICON_WHATS, ICON_WORLD } from "assets/icons";
import { useSocket } from "hooks/useSocket";
import { _GET_CODE_BENEFIT } from "redux/actions/benefitAction";
import { useViveDispatch } from "../../../hooks/reduxHooks";
import { useBenefitDetail } from "hooks/useBenefitDetail";
import { imageHandler } from "utils/imageHandlers";
import ModalBenefitDetail from "components/modals/ModalBenefitDetail";

import BenefitWebStatic from "./BenefitWebStatic";
import BenefitWebListCode from "./BenefitWebListCode";
import BenefitGeneric from "./BenefitGeneric";
import BenefitInplaceListCode from "./BenefitInplaceListCode";
import PressableShowModal from "components/common/PressableShowCode";

import BenefitDetailFooter from "./BenefitDetailFooter";
import InformationModalUnidrogas from "../../modals/Unidrogas/InformationModalUnidrogas";
import ErrorModalUnidrogas from "../../modals/Unidrogas/ErrorModalUnidrogas";

import {
  ContainerSectionTwo,
  ButtonFive,
  ContainerSeccionTree,
  ContentImg,
  ImageHeaderCode,
  ContenFooter,
  TextBenefitError,
  ContentBenefit,
  ButtonOpenUrl,
  IconsWhats,
  ButtonWhatsText,
  ButtonWhats,
  ButtonUnidrogas,
  ContentError,
  TitleMessageError,
  ButtonSeeOtherBenefits,
} from "./styles";

type props = {
  benefitId: string;
};

const ContainerTwo = ({ benefitId }: props) => {
  const { selectedBenefit, infoBeneditCode, codeError } = useViveSelector(
    (state) => state.benefits
  );
  const { id_token } = useViveSelector((st) => st.user);
  const { detail, benefit_channel, terms, source_code } = selectedBenefit;
  const dispatch = useViveDispatch();
  const state = useLocation().state as any;
  const subject = useSocket(id_token);
  const {
    handleOpenWhatsapp,
    handleOpenWeb,
    handleReloadCodeBenefit,
    getCodeBenefit,
    setShowModal,
    handlePressBannerBenefit,
    handlePressUnidrogas,
    handleCloseModalUnidrogas,
    handleCloseModalError,
    showModal,
    showModalUnidrogas,
    isLoading,
  } = useBenefitDetail();

  useEffect(() => {
    if (state?.data && selectedBenefit.id) handlePressBannerBenefit(state.data);
  }, [selectedBenefit.id, state?.data]);

  const handleBack = () => window.history.back();

  useEffect(() => {
    const event = subject.subscribe((data: any) => {
      const values = ["WEB", "IN_PLACE", "WHATSAPP"];
      if (values.includes(benefit_channel) && source_code !== "LIST_CODE") {
        if (infoBeneditCode.id === data.id) {
          toast.success(
            "Beneficio Reclamado. El beneficio ha sido reclamado exitosamente",
            {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
          dispatch(_GET_CODE_BENEFIT(benefitId!));
        }
      }
    });

    return () => {
      event.unsubscribe();
    };
  }, [dispatch, infoBeneditCode, benefitId, subject]);

  return (
    <ContainerSectionTwo>
      <ButtonFive>
        <ContentBenefit>
          <Benefit {...selectedBenefit} shadow />
        </ContentBenefit>
        <ContentImg>
          <ImageHeaderCode
            src={`${
              selectedBenefit.image
                ? imageHandler(selectedBenefit.image)
                : "placeholders/discount.png"
            }`}
            alt={selectedBenefit.commerce.name}
          />
        </ContentImg>
        <ContainerSeccionTree>
          {codeError ? (
            <ContentError>
              <TitleMessageError>
                Lo sentimos, este beneficio se encuentra agotado
              </TitleMessageError>
              <TextBenefitError>{codeError}</TextBenefitError>
              <ButtonSeeOtherBenefits onClick={handleBack}>
                Ver otros beneficios
              </ButtonSeeOtherBenefits>
            </ContentError>
          ) : (
            <Fragment>
              {/* web */}
              {benefit_channel === "WEB" && source_code === "STATIC" && (
                <Fragment>
                  <PressableShowModal
                    onClick={() => getCodeBenefit(benefitId)}
                  />
                  {showModal && (
                    <ModalBenefitDetail
                      title="Código"
                      handleClose={() => setShowModal(false)}
                    >
                      <BenefitWebStatic
                        code={infoBeneditCode.code}
                        handleOpenWeb={handleOpenWeb}
                      />
                    </ModalBenefitDetail>
                  )}
                </Fragment>
              )}
              {benefit_channel === "WEB" && source_code === "NO_CODE" && (
                <ButtonOpenUrl onClick={handleOpenWeb}>
                  <IconsWhats src={ICON_WORLD} />
                  <ButtonWhatsText>Usar Cupón</ButtonWhatsText>
                </ButtonOpenUrl>
              )}
              {benefit_channel === "WEB" && source_code === "LIST_CODE" && (
                <Fragment>
                  <PressableShowModal
                    onClick={() => getCodeBenefit(benefitId)}
                  />
                  {showModal && (
                    <ModalBenefitDetail
                      title="Código"
                      handleClose={() => setShowModal(false)}
                    >
                      <BenefitWebListCode
                        code={infoBeneditCode.code}
                        handleOpenWeb={handleOpenWeb}
                        handleReloadCodeBenefit={() =>
                          handleReloadCodeBenefit(benefitId!)
                        }
                      />
                    </ModalBenefitDetail>
                  )}
                </Fragment>
              )}

              {/* in_place */}
              {benefit_channel === "IN_PLACE" && source_code === "LIST_CODE" && (
                <Fragment>
                  <PressableShowModal
                    onClick={() => getCodeBenefit(benefitId)}
                  />
                  {showModal && (
                    <ModalBenefitDetail
                      title="Código"
                      handleClose={() => setShowModal(false)}
                    >
                      <BenefitInplaceListCode
                        code={infoBeneditCode.code}
                        handleReloadCodeBenefit={() =>
                          handleReloadCodeBenefit(benefitId!)
                        }
                      />
                    </ModalBenefitDetail>
                  )}
                </Fragment>
              )}
              {benefit_channel === "IN_PLACE" && source_code === "AUTO_GEN" && (
                <Fragment>
                  <PressableShowModal
                    onClick={() => getCodeBenefit(benefitId)}
                  />
                  {showModal && (
                    <ModalBenefitDetail
                      title="Código"
                      handleClose={() => setShowModal(false)}
                    >
                      <BenefitGeneric code={infoBeneditCode.code} />
                    </ModalBenefitDetail>
                  )}
                </Fragment>
              )}
              {benefit_channel === "IN_PLACE" && source_code === "STATIC" && (
                <Fragment>
                  <PressableShowModal
                    onClick={() => getCodeBenefit(benefitId)}
                  />
                  {showModal && (
                    <ModalBenefitDetail
                      title="Código"
                      handleClose={() => setShowModal(false)}
                    >
                      <BenefitGeneric code={infoBeneditCode.code} />
                    </ModalBenefitDetail>
                  )}
                </Fragment>
              )}

              {/* unidrogas */}
              {benefit_channel === "IN_PLACE" && source_code === "UNI_DROGAS" && (
                <Fragment>
                  <ButtonUnidrogas
                    disabled={isLoading}
                    onClick={() => handlePressUnidrogas(benefitId)}
                  >
                    {isLoading ? (
                      <Orbit size={35} color="#f6f6f6" />
                    ) : (
                      "Obtener beneficio"
                    )}
                  </ButtonUnidrogas>
                  {showModalUnidrogas.showModalSucces && (
                    <InformationModalUnidrogas
                      onClose={handleCloseModalUnidrogas}
                    />
                  )}
                  {showModalUnidrogas.showModalError && (
                    <ErrorModalUnidrogas
                      message={"Ya has reclamado este beneficio"}
                      onClose={handleCloseModalError}
                    />
                  )}
                </Fragment>
              )}

              {/* whatsapp */}
              {benefit_channel === "WHATSAPP" && source_code === "AUTO_GEN" && (
                <ButtonWhats onClick={handleOpenWhatsapp}>
                  <IconsWhats src={ICON_WHATS} />
                  <ButtonWhatsText>Utilizar Cupón</ButtonWhatsText>
                </ButtonWhats>
              )}
            </Fragment>
          )}
        </ContainerSeccionTree>
        <ContenFooter>
          <BenefitDetailFooter
            benefit_channel={benefit_channel}
            detail={detail}
            terms={terms}
          />
        </ContenFooter>
      </ButtonFive>
    </ContainerSectionTwo>
  );
};

export default ContainerTwo;
