import React from "react";

import { S3_CREDENTIAL } from "utils/contants";
import { BenefitItems } from "../../redux/redux.dto";

import BenefitDescription from "./BenefitDescription";
import { useNavigate } from "react-router-dom";
import ModalUserPendingData from "components/modals/UserPendingData";
import { useViveSelector } from "../../hooks/reduxHooks";
import { UserPendingData } from "../../hooks/userPendingData";
import {
  CardBenefit,
  ContainerSeparator,
  ContentImage,
  ImageBenefit,
  Separator,
} from "./style";

const Benefit: React.FC<BenefitItems> = ({
  id,
  title,
  benefit_channel,
  commerce,
  discount_price,
  discount,
  display_discount,
  actual_price,
  shadow,
}) => {
  const { user } = useViveSelector((state) => state.user);
  const navigate = useNavigate();
  const { handleNavigatePendingData, pendingData, setPendingData } =
    UserPendingData();

  const seeDetails = () => {
    if (user.status === "PENDING_DATA") {
      return setPendingData(true);
    }
    navigate(`/benefit-detail/${id}`);
  };

  if (pendingData) {
    return (
      <ModalUserPendingData
        handleClose={setPendingData}
        handleNavigatePendingData={handleNavigatePendingData}
      />
    );
  }

  return (
    <CardBenefit onClick={seeDetails} shadow={shadow}>
      <ContentImage>
        <ImageBenefit
          src={
            commerce?.image
              ? `${S3_CREDENTIAL.baseUrl}/${commerce?.image}`
              : `${S3_CREDENTIAL.baseUrl}/placeholders/discount.png`
          }
        />
      </ContentImage>
      <ContainerSeparator>
        <Separator />
      </ContainerSeparator>
      <BenefitDescription
        actual_price={actual_price}
        discount_price={discount_price}
        discount={discount}
        display_discount={display_discount}
        title={title}
        benefit_channel={benefit_channel}
      />
    </CardBenefit>
  );
};

export default Benefit;
