import { useState } from "react";
import { useViveSelector } from "hooks/reduxHooks";
import Allows from "components/common/Allow";
import Benefit from "components/Benefit/Benefit";
import "swiper/css";

import treestelas from "../../../assets/image/treestelas.svg";
import {
  ContainerSectionTwo,
  InformationConteinerOver,
  ContainerTwoS,
  ContainerTreeS,
  CategoryTabs,
  ButtonTwo,
  InformationConteinerOver2,
  Stelas,
} from "./styles";
import ModalReview from "./ModalReview";

const ContentCommerce = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { selectedCommerce, benefitsCommerce } = useViveSelector(
    (state) => state.commerces
  );
  const { allow_consume_in_site, has_delivery, allow_minors, allow_pets } =
    selectedCommerce;
  return (
    <ContainerSectionTwo>
      <InformationConteinerOver>
        <ContainerTreeS>
          <CategoryTabs>
            <ButtonTwo
              onClick={() => {
                setIsOpen(!isOpen);
              }}
            >
              <Stelas>
                <img
                  src={treestelas}
                  style={{ width: "100%", height: "100%" }}
                  alt="--"
                />
              </Stelas>
              Calificar
            </ButtonTwo>
            <>
              {isOpen && (
                <ModalReview
                  calcel={() => setIsOpen(false)}
                  isVisble={isOpen}
                />
              )}
            </>
          </CategoryTabs>
        </ContainerTreeS>
        <ContainerTwoS>
          <Allows title="Consumo en el lugar" allow={allow_consume_in_site} />
          <Allows title="Domicilios" allow={has_delivery} />
          <Allows title="Mascotas" allow={allow_pets} />
          <Allows title="Menores" allow={allow_minors} />
        </ContainerTwoS>
      </InformationConteinerOver>
      <InformationConteinerOver2>
        {benefitsCommerce.map((el) => (
          <Benefit key={el.id} {...el} />
        ))}
      </InformationConteinerOver2>
    </ContainerSectionTwo>
  );
};

export default ContentCommerce;
