import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";

import { useViveSelector, useViveDispatch } from "../../../hooks/reduxHooks";
import { identify, pageView } from "utils/mixpanel";
import { DepartmentItems } from "redux/redux.dto";
import { useUserMembershipLevel } from "hooks/useMembershipLevel";

import HomeCategories from "./HomeCategories/HomeCategories";
import HomeFeatures from "./HomeFeatures/HomeFeatures";
import AlertCompleteProfile from "./HomeAlert/HomeAlert";
import HomeCommerce from "./HomeCommerce/HomeCommerce";
import SearchPage from "./Search /Search";
import HomeBenefits from "./HomeBenefits/HomeBenefits";
import PayBanner from "components/pages/Homepage/PayBanner";
import { _CLEAN_BENEFITS, _GET_BENEFITS } from "redux/actions/benefitAction";

import { ContainerIndexGlobal, Footer } from "./styles";
import ModalDownloadApp from "components/modals/ModalDownloadApp";
import ModalSelectDepartment from "components/modals/ModalSelectDepartment";

function Index() {
  const { user, showModalDownload } = useViveSelector((state) => state.user);
  const { departments, isVisibleSelectDepartmentMandatory } = useViveSelector(
    (state) => state.departments
  );
  const { benefits } = useViveSelector((state) => state.benefits);

  const [page, setPage] = useState<number>(1);
  const { membershipLevel } = useUserMembershipLevel();
  const dispatch = useViveDispatch();
  useEffect(() => {
    if (user.department_id) {
      setPage(1);
      dispatch(_CLEAN_BENEFITS());
    }
  }, [dispatch, user.department_id]);

  useEffect(() => {
    if (membershipLevel.level || membershipLevel.level === 0) {
      dispatch(
        _GET_BENEFITS(
          page,
          false,
          membershipLevel.level,
          user.department_id || "1"
        )
      );
    }
  }, [membershipLevel.level, page, dispatch, user.department_id]);

  useEffect(() => {
    if (user.id && departments.length > 0) {
      const useDepartment = departments.find(
        (department) => department.id === user.department_id
      ) as DepartmentItems;
      identify(
        {
          avatar: user.avatar || "No available",
          application_id: user.application_id,
          customer_quality: user.customer_quality,
          department_id: user.department_id,
          email: user.email,
          first_name: user.first_name,
          id: user.id,
          identification_number: user.identification_number,
          last_name: user.last_name,
          membership_id: user.membership_id,
          nic: user.nic,
          phone: user.phone,
          terms_and_conditions: user.terms_and_conditions,
        },
        useDepartment?.name || "Atlántico"
      );
      pageView({
        PageType: "Home App Web",
        Name: "Home Web",
        UserTime: new Date().toDateString(),
      });
    }
  }, [user.id, departments]);

  return (
    <ContainerIndexGlobal>
      {showModalDownload ? <ModalDownloadApp /> : null}
      {isVisibleSelectDepartmentMandatory ? <ModalSelectDepartment /> : null}
      <AlertCompleteProfile />
      <PayBanner />
      <SearchPage />
      <HomeCategories />
      <HomeFeatures />
      <HomeCommerce />
      <InfiniteScroll
        dataLength={benefits.length}
        hasMore
        next={() => {
          if (benefits.length < 10 * page) return;
          if (user.id) setPage(page + 1);
        }}
        loader={<div />}
      >
        <HomeBenefits />
      </InfiniteScroll>
      <Footer>ViveMas &#169; 2022 Todos los Derechos Reservados.</Footer>
    </ContainerIndexGlobal>
  );
}

export default Index;
