import Benefit from "components/Benefit/Benefit";

import { useViveSelector } from "hooks/reduxHooks";
import { BenefitItems } from "redux/redux.dto";
import HeaderSection from "components/common/HeaderSection";

import { Container, ContentBenefits } from "./styles";
import { TextEmpty } from "../HomeCategories/styles";

const HomeBenefits = () => {
  const { benefits } = useViveSelector((state) => state.benefits);

  return (
    <Container>
      <HeaderSection textLeft="beneficios" />
      <ContentBenefits>
        {benefits.length > 0 ? (
          benefits.map((el: BenefitItems) => <Benefit key={el.id} {...el} />)
        ) : (
          <TextEmpty>No Hay Beneficios Disponibles Para Esta Región</TextEmpty>
        )}
      </ContentBenefits>
    </Container>
  );
};

export default HomeBenefits;
