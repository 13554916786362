import { Fragment } from "react";
import { ICON_WORLD } from "assets/icons";

import { TextCodeType } from "./BenefitGeneric";
import { ButtonOpenUrl, ButtonWhatsText, IconsWhats } from "./styles";

type InfoBeneditCode = {
  code: string;
  handleOpenWeb: () => void;
};
const BenefitWebStatic = ({ code, handleOpenWeb }: InfoBeneditCode) => {
  return (
    <Fragment>
      <TextCodeType>{code || ""}</TextCodeType>
      <ButtonOpenUrl onClick={handleOpenWeb}>
        <IconsWhats src={ICON_WORLD} />
        <ButtonWhatsText>Usar Cupón</ButtonWhatsText>
      </ButtonOpenUrl>
    </Fragment>
  );
};

export default BenefitWebStatic;
