import { DISMISS_MODAL_DOWNLOAD } from "./../types/index";
import { IUserReducer } from "redux/redux.dto";
import {
  USER_INFO_OTP,
  SET_ID_TOKEN,
  USER_INFO_OTP_ERROR,
  VERIFY_USER_LOGGED,
  SIGN_IN,
  UPDATE_STATE_USER_INFO,
  USER_LOGOUT,
  GUEST_USER,
  USER_MEMBERSHIPS,
} from "../types";

const InitialState: IUserReducer = {
  user: {
    application_id: "",
    created_at: "",
    deleted_at: "",
    email: "",
    first_name: "",
    id: "",
    identification_number: "",
    last_name: "",
    membership_id: "",
    password: "",
    phone: "",
    role: "",
    avatar: null,
    status: "",
    updated_at: "",
    department_id: "",
    terms_and_conditions: "",
    customer_quality: "",
    nic: "",
    birthday_date: "",
    document_type: "",
    landline: "",
    second_last_name: "",
    second_name: "",
    sex: "",
  },
  isAuth: false,
  id_token: "",
  isLoading: true,
  isGuest: false,
  userMemberships: [],
  showModalDownload: true,
};
export const userReducer = (state = InitialState, action: any) => {
  switch (action.type) {
    case USER_INFO_OTP:
      const { user, access_token } = action.payload;
      return {
        ...state,
        user: user,
        id_token: access_token,
        isAuth: true,
      };
    case "SET_NEW_DEPARTMENT":
      return {
        ...state,
        user: action.payload,
      };
    case DISMISS_MODAL_DOWNLOAD:
      return {
        ...state,
        showModalDownload: action.payload,
      };
    case USER_MEMBERSHIPS:
      return {
        ...state,
        userMemberships: action.payload,
      };
    case SET_ID_TOKEN:
      return {
        ...state,
        id_token: action.payload,
        isAuth: true,
      };
    case SIGN_IN:
      return {
        ...state,
        isLoading: false,
        isAuth: false,
      };
    case VERIFY_USER_LOGGED:
      return {
        ...state,
        user: action.payload.user,
        id_token: action.payload.id_token,
        isLoading: false,
        isAuth: true,
      };
    case USER_INFO_OTP_ERROR:
      return {
        ...state,
      };
    case UPDATE_STATE_USER_INFO:
      return {
        ...state,
        user: action.payload,
        isAuth: true,
      };
    case USER_LOGOUT:
      return {
        ...state,
        isAuth: false,
        user: {
          application_id: "",
          created_at: "",
          deleted_at: "",
          email: "",
          first_name: "",
          id: "",
          identification_number: "",
          last_name: "",
          membership_id: "",
          password: "",
          phone: "",
          role: "",
          avatar: null,
          status: "",
          updated_at: "",
          department_id: "",
          terms_and_conditions: "",
          customer_quality: "",
          nic: "",
          birthday_date: "",
          document_type: "",
          landline: "",
          second_last_name: "",
          second_name: "",
          sex: "",
        },
        isGuest: false,
      };
    case GUEST_USER:
      return {
        ...state,
        isGuest: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
};
