import axios from "axios";
import { API_URL } from "./contants";

export const vivemasApi = axios.create({
  headers: {
    "X-Requested-With": "XMLHttpRequest",
    "Content-Type": "application/json",
    application_id: "1",
  },
  baseURL: `${API_URL.prod}`,
  // baseURL: `${API_URL.test}`,
});
