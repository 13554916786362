import { ICON_COPY_CODE, ICON_WORLD, ICON_RELOAD } from "assets/icons";

import {
  ButtonOpenUrlWebListcode,
  ButtonsWebWebListcode,
  ButtonWhatsTextWebListcode,
  Container,
  ContentButtonWeb,
  ContentCopyCode,
  IconRealoadCode,
  IconsWhatsWebListcode,
  PressableCopyCode,
  ReloadCodeWebListcode,
  TextCodeType,
  TextReloadCodeWebListcode,
} from "./styles";

type BenefitWebListCodeProps = {
  code: string;
  handleOpenWeb: () => void;
  handleReloadCodeBenefit: () => void;
};
const BenefitWebListCode = ({
  code,
  handleOpenWeb,
  handleReloadCodeBenefit,
}: BenefitWebListCodeProps) => {
  return (
    <Container>
      <ContentCopyCode>
        <TextCodeType>{code || ""}</TextCodeType>
        <PressableCopyCode onClick={handleOpenWeb}>
          <IconRealoadCode src={ICON_COPY_CODE} />
        </PressableCopyCode>
      </ContentCopyCode>
      <ContentButtonWeb>
        <ButtonOpenUrlWebListcode onClick={handleOpenWeb}>
          <IconsWhatsWebListcode src={ICON_WORLD} />
          <ButtonWhatsTextWebListcode>Usar Cupón</ButtonWhatsTextWebListcode>
        </ButtonOpenUrlWebListcode>
        <ButtonsWebWebListcode>
          <ReloadCodeWebListcode onClick={handleReloadCodeBenefit}>
            <IconRealoadCode src={ICON_RELOAD} />
            <TextReloadCodeWebListcode>Nuevo Código</TextReloadCodeWebListcode>
          </ReloadCodeWebListcode>
        </ButtonsWebWebListcode>
      </ContentButtonWeb>
    </Container>
  );
};

export default BenefitWebListCode;
