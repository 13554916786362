import styled from "styled-components";

export const ContainerHomeCommerces = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 370px;
  ${({ theme: { media } }) => media.tablet`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 770px;
    margin: 0 0 50px ;
    padding: 0;
    margin-top: 20px;
  `};
  ${({ theme: { media } }) => media.desktop`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 1000px;
    margin-top: 40px;
    
  `};
`;

export const RetailContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  gap: 0 15px;
  overflow-x: scroll;
  overflow-y: hidden;
  height: 120px;
  margin-bottom: 15px;
  ${({ theme: { media } }) => media.tablet`
    align-items: flex-start;
    width: 100%;
    overflow-y: hidden;
  `};
  ${({ theme: { media } }) => media.desktop`
    align-items: center;
    width: 100%;
    overflow-y: hidden;
    & :hover {
      cursor: pointer;
    }

    ::-webkit-scrollbar {
      background: #e1e1e1;
      height: 5px;
    }

    ::-webkit-scrollbar-thumb {
      background: #b9b9b9;
      height: 5px;
      border-radius: 30px;
    }
  `};
`;

export const BusinessTabsOver = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  ${({ theme: { media } }) => media.tablet`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  `};
  ${({ theme: { media } }) => media.desktop`
    display: flex;
    width: 100%;
  `};
`;

export const BusinessTabsdesktop = styled.div`
  display: flex;
  flex-direction: column;
  width: 100px;
  cursor: pointer;
  ${({ theme: { media } }) => media.tablet`
    display: flex;
    flex-direction: column;
    margin-right: 20px;
  `};
  ${({ theme: { media } }) => media.desktop`
    display: flex;
    flex-direction: column;
    margin-right: 15px;
  `};
`;

export const CommerceLeft = styled.div`
  display: flex;
  width: 70px;
  height: 70px;
  margin-left: 6px;
  ${({ theme: { media } }) => media.tablet`
    width: 70px;
    height: 70px;
  `};
  ${({ theme: { media } }) => media.desktop`
    margin-left: 0;
  `};
`;

export const CommerceCenter = styled.div`
  width: 100%;
  height: auto;
  text-align: center;
  margin-top: 7px;
  ${({ theme: { media } }) => media.tablet`
    margin-top: 7px;
  `};
  ${({ theme: { media } }) => media.desktop`
    margin-top: 10px;
  `};
`;

export const CommerceTitle = styled.h2`
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #000000;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  max-width: 80px;
  text-overflow: ellipsis;
  ${({ theme: { media } }) => media.tablet`
    font-size: 11px;
  
    line-height: 11px;
  `};
  ${({ theme: { media } }) => media.desktop`
    font-size: 11px;
    line-height: 12px;
 
  `};
`;

export const Image3 = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  ${({ theme: { media } }) => media.desktop`
    width: 60px;
    height: 60px;
    object-fit: cover;  
  `};
  ${({ theme: { media } }) => media.desktop`
    width: 75px;
    height:75px;
    object-fit: cover;  
  `};
`;
