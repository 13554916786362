import { Select } from "antd";
import styled from "styled-components";
import { Monserrat20x500 } from "../../typography/typographt";

export const OverallContainer = styled("title")`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
  background-color: #e1e1e1;
  padding-bottom: 40px;
  ${({ theme: { media } }) => media.tablet`
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
     width: 100%;
    height: 100vh;
    background-color: #e1e1e1;
  `};
  ${({ theme: { media } }) => media.desktop`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
     width: 100%;
    height: 100%;
  background-color: #e1e1e1;
  `};
`;
export const HeaderCompleteProfile = styled("h2")`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ theme: { media } }) => media.desktop`
    width: 100%;
    display: flex;
    padding-right: 10px;
    justify-content: space-around;
    margin-bottom: 33px;
    flex-direction: row;  
  `};
`;

export const ContainerInputsProfile = styled("div")`
  width: 90%;
  padding: 29px 27px 37px 27px;
  height: auto;
  background: #ffffff;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  ${({ theme: { media } }) => media.tablet`
    width: 65%;
    padding: 29px 37px 37px 37px;
    height: auto;
    background: #FFFFFF;
    border-radius: 20px;  
         box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.2);
  `};
  ${({ theme: { media } }) => media.desktop`
    width: 450px;
    padding: 29px 37px 37px 37px;
    height: auto;
    background: #fff;
     box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.2);
    border-radius: 20px;  
  `};
`;

export const ContainerInput = styled.div`
  width: 100%;
  height: 50px;
  border-radius: 12px;
  margin-bottom: 20px;
`;

export const TextError = styled(Monserrat20x500)`
  color: red;
  font-size: 11px;
  letter-spacing: 0.5px;
  line-height: 15px;
  ${({ theme: { media } }) => media.tablet`
   color: red;
    font-size: 15px;
    letter-spacing: 0.5px;
    line-height: 15px;
  `};
  ${({ theme: { media } }) => media.desktop`
    color: red;
    font-size: 12px;
    letter-spacing: 0.5px;
    line-height: 15px;  
  `};
`;
export const RadiusBorder = styled(Monserrat20x500)`
  border-radius: 10px;
  ${({ theme: { media } }) => media.tablet`
  
  `};
  ${({ theme: { media } }) => media.desktop`
 
  `};
`;

export const CustomSelect = styled(Select).attrs({
  showSearch: true,
  optionFilterProp: "children",
  size: "large",
  filterOption: (input: string, option: any) =>
    (option!.children as unknown as string)
      .toLowerCase()
      .includes(input.toLowerCase()),
})`
  width: 100%;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;

  ${({ theme: { media } }) => media.tablet`
     font-size: 18px;
  `};
  ${({ theme: { media } }) => media.desktop`
   font-size: 18px;
  
  `};
`;

export const CompleteInformation = styled.button.attrs({
  type: "button",
})`
  width: 100%;
  height: 60px;
  background-color: #006abf;
  border: none;
  border-radius: 12px;
  cursor: pointer;
`;
export const TextCompleteInfo = styled.p`
  color: white;
  font-size: 18px;
  font-weight: 600;
`;
