import { media } from "themes/media-queries";
import { ThemeType } from "types/theme";

import { colors } from "./colors";

export const appTheme: ThemeType = {
  colors,
  media,
  isDark: false,
};

export default appTheme;
