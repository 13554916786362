import styled from "styled-components";

export const Container = styled.div`
  width: 370px;
  height: 100%;
  overflow-y: hidden;
  ${({ theme: { media } }) => media.tablet`
    width: 100%; 
    height: 100%;
  `};
  ${({ theme: { media } }) => media.desktop`
    width: 1000px;
    height: 100%;
  `};
`;

export const ContentBenefits = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 25px;
  padding: 0 7px;
  overflow: hidden;
  ${({ theme: { media } }) => media.tablet`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;  
    margin-top: 25px;
    padding: 0 7px;
  `};
  ${({ theme: { media } }) => media.desktop`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    margin-top: 25px; 
    padding: 0 7px;
  `};
`;
