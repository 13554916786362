import styled from "styled-components";

export const ContainerModal = styled.div`
  background-color: #fff;
  width: 400px;
  height: 350px;
  flex-direction: column;
  border-radius: 20px;
`;

export const ContentCodeBenefitUniDrogas = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  height: 100%;
  padding: 0 20px 20px 20px;
`;

export const BodyCodeBenefitUniDrogas = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  align-self: center;
  height: 100%;
  width: 100%;
  text-align: center;
  padding: 0 0 20px 0;
  flex-direction: column;
`;

export const ImageCheck = styled.img`
  height: 70px;
  width: 70px;
`;

export const SubTitle = styled.text`
  text-align: center;
  color: black;
  font-size: 18px;
  font-weight: 700;
`;

export const TextUniDrogas = styled.text`
  color: black;
  font-size: 14px;
  font-weight: 500;
`;

export const PressableCloseUniDrogas = styled.div`
  padding: 16px 36px;
  display: flex;
  cursor: default;
  margin-bottom: 5px;
  width: 130px;
  height: 45px;
  border-radius: 26px;
  background-color: #006abf;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const TextPressUniDrogas = styled.text`
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  text-align: center;
`;
