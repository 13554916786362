import { getAllFeatures, getFeature } from "api/features";
import { GET_ALL_FUEATURES, GET_FEATURES } from "redux/types";

type GetAllFeaturesProps = {
  department_id: string;
  membership_level: number;
};

export const _GET_FEATURES =
  (membership_level: number, department_id: string) =>
  async (dispatch: any, getState: Function) => {
    try {
      const token = getState().user.id_token;
      const response = await getFeature(token, department_id, membership_level);
      if (response.status === 200) {
        const { data } = response;
        dispatch({
          type: GET_FEATURES,
          payload: data.data,
        });
      } else {
        dispatch({
          type: GET_FEATURES,
          payload: [],
        });
      }
    } catch (err) {
      dispatch({
        type: GET_FEATURES,
        payload: [],
      });
      console.log("error get banners", err);
    }
  };

export const _GET_ALL_FUEATURES =
  (props: GetAllFeaturesProps) => async (dispatch: any, getState: Function) => {
    try {
      const token = getState().user.id_token;
      const response = await getAllFeatures(
        token,
        props.department_id,
        props.membership_level
      );
      if (response.status === 200) {
        const { data } = response;
        dispatch({
          type: GET_ALL_FUEATURES,
          payload: data.data,
        });
      } else {
        dispatch({
          type: GET_ALL_FUEATURES,
          payload: [],
        });
      }
    } catch (err) {
      dispatch({
        type: GET_ALL_FUEATURES,
        payload: [],
      });
      console.log("error get banners", err);
    }
  };
