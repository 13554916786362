import React from "react";
import { Checkbox } from "antd";
import { Formik } from "formik";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { CheckboxChangeEvent } from "antd/lib/checkbox";

import { LOGO_VIVEMAS } from "assets/icons";
import { FormLogin } from "utils/ValidationSchemas";
import { loginOtp } from "api/authOtp";

import TermsAndConditions from "./TermsConditions";
import {
  OverContainer,
  ImageLogo,
  ContainerImage,
  ContainerForm,
  FormTitle,
  ContenInput,
  CountryCode,
  ImageLeft,
  InputLeft,
  LoginButton,
  LoginInput,
  Separator,
  TextError,
  Loader,
  ContentTerms,
  FragmentInput,
  LoginPageWrapper,
} from "./styles";
import { useViveSelector } from "hooks/reduxHooks";
import ModalDownloadApp from "components/modals/ModalDownloadApp";

const Index = () => {
  const { showModalDownload } = useViveSelector((state) => state.user);
  const [loading, setLoading] = React.useState<boolean>(false);
  const navigate = useNavigate();
  return (
    <OverContainer>
      {showModalDownload ? <ModalDownloadApp /> : null}
      <LoginPageWrapper>
        <ContainerImage>
          <ImageLogo src={LOGO_VIVEMAS} />
        </ContainerImage>
        <Formik
          initialValues={{ phone: "", terms_conditions: false }}
          onSubmit={async ({ phone, terms_conditions }) => {
            try {
              if (!terms_conditions)
                return toast.info("Acepta Términos y condiicones", {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              setLoading(true);
              await loginOtp({ phone: `+57 ${phone}` });
              navigate("/verification-code");
            } catch (error) {
              setLoading(false);
              toast.error(
                "Opps! Ha ocurrido un problema en procesar la solicitud. Inténtalo más tarde!",
                {
                  position: "top-center",
                  autoClose: 3500,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                }
              );
              console.log("error try login", error);
            }
          }}
          validationSchema={FormLogin}
          enableReinitialize
          validateOnMount
        >
          {({
            handleBlur,
            handleChange,
            handleSubmit,
            values,
            errors,
            touched,
            setFieldValue,
          }) => (
            <ContainerForm>
              <FormTitle>Ingresa tu número para continuar</FormTitle>
              <FragmentInput>
                <ContenInput>
                  <InputLeft>
                    <ImageLeft />
                    <CountryCode>+57</CountryCode>
                  </InputLeft>
                  <Separator height="100" width={4} />
                  <LoginInput
                    value={values.phone}
                    onChange={handleChange("phone")}
                    onBlur={handleBlur("phone")}
                    placeholder="Numero de teléfono"
                    maxLength={10}
                    inputMode={"numeric"}
                  />
                </ContenInput>
                {errors.phone && touched.phone && (
                  <TextError>{errors.phone}</TextError>
                )}
              </FragmentInput>
              <LoginButton type="button" onClick={() => handleSubmit()}>
                {loading ? <Loader /> : "Continuar"}
              </LoginButton>
              <ContentTerms>
                <Checkbox
                  onChange={({ target }: CheckboxChangeEvent) =>
                    setFieldValue("terms_conditions", target.checked)
                  }
                  checked={values.terms_conditions}
                />
                <TermsAndConditions />
              </ContentTerms>
              {errors.terms_conditions && touched.terms_conditions && (
                <TextError>{errors.terms_conditions}</TextError>
              )}
            </ContainerForm>
          )}
        </Formik>
      </LoginPageWrapper>
    </OverContainer>
  );
};

export default Index;
