import React from "react";
import {
  ContainerModal,
  ContentCodeBenefitUniDrogas,
  BodyCodeBenefitUniDrogas,
  ImageCheck,
  SubTitle,
  TextUniDrogas,
  PressableCloseUniDrogas,
  TextPressUniDrogas,
} from "./styles";
import { ModalBackground } from "../styles";
import { ICON_ALERT_YELLOW } from "assets/icons";

import { createPortal } from "react-dom";

interface ErrorModalUnidrogasProps {
  onClose: () => void;
}

const ErrorModalUnidrogas = (
  props: ErrorModalUnidrogasProps & { message: string }
) => {
  return createPortal(
    <ModalBackground>
      <ContainerModal>
        <ContentCodeBenefitUniDrogas>
          <BodyCodeBenefitUniDrogas>
            <ImageCheck src={ICON_ALERT_YELLOW} />
            <SubTitle>BENEFICIO RECLAMADO</SubTitle>
            <TextUniDrogas>{props.message}</TextUniDrogas>
          </BodyCodeBenefitUniDrogas>
          <PressableCloseUniDrogas onClick={props.onClose}>
            <TextPressUniDrogas>Cerrar</TextPressUniDrogas>
          </PressableCloseUniDrogas>
        </ContentCodeBenefitUniDrogas>
      </ContainerModal>
    </ModalBackground>,
    document.getElementById("modal-benefit-detail") as HTMLElement
  );
};

export default ErrorModalUnidrogas;
