import Lottie from "react-lottie";
import { defaultOptions } from "utils/contants";
import { Container } from "./styles";

const LoaderApp = () => {
  return (
    <Container>
      <Lottie options={defaultOptions} height={150} width={150} />
    </Container>
  );
};

export default LoaderApp;
