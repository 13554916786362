import { GET_CATEGORIES, SET_SELECTED_CATEGORY_TAB } from "../types";

const INITIAL_STATE: any = {
  categories: [],
  selectedTabCategory: { id: null, title: "" },
};

export const categoriesReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case GET_CATEGORIES:
      return {
        ...state,
        categories: action.payload,
      };
    case SET_SELECTED_CATEGORY_TAB:
      return {
        ...state,
        selectedTabCategory: action.payload,
      };
    default:
      return state;
  }
};
