import { useEffect, useState } from "react";
import "swiper/css";
import { DownOutlined } from "@ant-design/icons";
import { Dropdown } from "antd";
import { ContainerData, Dir } from "./styles";
import { useViveSelector } from "hooks/reduxHooks";
import { useViveDispatch } from "../../../hooks/reduxHooks";
import {
  _GET_DEPARTMENTS,
  _SET_SELECTED_DEPARTMENT,
  _UPDATE_USER_DEPARTMENT,
} from "redux/actions/departmentsAction";
import { _SET_SELECTED_CATEGORY_TAB } from "redux/actions/categoryAction";
import DepartmentTab from "./Departmenttab";
import { changeLocation } from "utils/mixpanel";
import { DepartmentItems } from "redux/redux.dto";
import { SET_CURRENT_DEPARTMENT } from "redux/types";

const SelectDepartment = () => {
  const [itemSelected, setItemSelected] = useState<string | undefined>("");
  const { departments, selectedDepartment } = useViveSelector(
    (state) => state.departments
  );
  const { user } = useViveSelector((state) => state.user);

  const dispatch = useViveDispatch();

  useEffect(() => {
    dispatch(_GET_DEPARTMENTS());
  }, [dispatch]);

  useEffect(() => {
    if (selectedDepartment.name) {
      dispatch({
        type: SET_CURRENT_DEPARTMENT,
        payload: selectedDepartment.name,
      });
      return setItemSelected(selectedDepartment.name);
    }
    if (departments.length > 0) {
      const value = departments.find(
        (department) => department.id === user.department_id ?? "1"
      ) as DepartmentItems;
      setItemSelected(value?.name || "Atlántico");
      dispatch({
        type: SET_CURRENT_DEPARTMENT,
        payload: value?.name ?? "Atlantico",
      });
    }
  }, [departments, user.department_id, selectedDepartment.name, dispatch]);

  const newLocationMixPanel = (NewLocation: string) => {
    const oldUserLocation = departments.find(
      (department) => department.id === user.department_id
    ) as DepartmentItems;
    changeLocation({
      OldLocation: oldUserLocation.name,
      NewLocation,
    });
  };

  const handleChangeCity = (itemId: string) => {
    let value = departments.find((option) => option.id === itemId);
    setItemSelected(value?.name || "");
    dispatch(_SET_SELECTED_DEPARTMENT(value));
    if (user.id) {
      newLocationMixPanel(value?.name!);
      const data = { ...user, department_id: value?.id };
      dispatch(_UPDATE_USER_DEPARTMENT(data));
    }
    dispatch(
      _SET_SELECTED_CATEGORY_TAB({
        id: null,
        title: "",
      })
    );
  };

  return (
    <Dropdown
      overlayStyle={{ left: "0px" }}
      overlay={<DepartmentTab selectDeparment={handleChangeCity} />}
      placement="bottom"
      arrow={{ pointAtCenter: true }}
      trigger={["click"]}
    >
      <Dir>
        <ContainerData>{itemSelected}</ContainerData>
        <DownOutlined style={{ fontSize: "15px", marginTop: "12px" }} />
      </Dir>
    </Dropdown>
  );
};

export default SelectDepartment;
