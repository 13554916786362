import axios from "axios";
import { API_URL } from "./contants";

export const SecureRequest = (token: string) => {
  return axios.create({
    headers: {
      "X-Requested-With": "XMLHttpRequest",
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    baseURL: `${API_URL.prod}`,
    // baseURL: `${API_URL.test}`,
  });
};
