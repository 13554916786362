import styled from "styled-components";

export const ContainerHomeFeatures = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 380px;
  width: 380px;
  min-height: 210px;
  margin: 20px 0;
  ${({ theme: { media } }) => media.tablet`
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 770px;
    width: 100%;
    min-height: 360px;
    margin: 40px 0;
  `};
  ${({ theme: { media } }) => media.desktop`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 1000px;
    max-width: 1000px;
    min-height: 400px;
    margin: 0 10px 10px 20px;
  `};
`;
export const SliderContainer = styled.div`
  padding-bottom: 20px;
  max-width: 380px;
  overflow-y: hidden;
  height: 160px;
  padding: 0 3px;
  .swiper-pagination {
    position: absolute;
    text-align: center;
    transition: 0.3s opacity;
    transform: translate3d(0, 0, 0);
    z-index: 10;
    margin-bottom: -10px;
    ${({ theme: { media } }) => media.tablet`
      margin-bottom: -14px;
    `};
    ${({ theme: { media } }) => media.desktop`
      margin-bottom: -11px;
    `};
  }

  .swiper-button-prev,
  .swiper-button-next {
    display: none;
    ${({ theme: { media } }) => media.tablet`
     display: none;
    `};
    ${({ theme: { media } }) => media.desktop`
      display: block;
    `};
  }

  ${({ theme: { media } }) => media.tablet`
    padding-bottom: 30px;
    max-width: 100%;
    display: flex;
    overflow-y: hidden;
    flex-direction: row; 
    height: 320px;
    margin-top: 20px;
  `};
  ${({ theme: { media } }) => media.desktop`
    padding-bottom: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 202.5px;
  `};
`;

export const RetailContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  height: 160px;
  margin-bottom: 15px;
  ${({ theme: { media } }) => media.tablet`
    align-items: flex-start;
    width: 100%;
    height: 100%;
    overflow-y: hidden;
  `};
  ${({ theme: { media } }) => media.desktop`
    align-items: center;
    width: 100%;
    height: 100%;
    overflow-y: hidden;
    & :hover {
      cursor: pointer;
    }

    ::-webkit-scrollbar {
      background: #e1e1e1;
      height: 5px;
    }

    ::-webkit-scrollbar-thumb {
      background: #b9b9b9;
      height: 5px;
      border-radius: 30px;
    }
  `};
`;

export const ContentSlide = styled.div`
  width: 528.34px;
  height: 202.5px;
  margin: 0 16.28px 0 0;
  ${({ theme: { media } }) => media.tablet`
     width: 100%;
    height: 202.5px;
    margin: 0 16.28px 0 0;
  `};
  ${({ theme: { media } }) => media.desktop`
     width: 528.34px;
    height: 202.5px;
    margin: 0 16.28px 0 0;
  `};
`;

export const SlideMenu1 = styled.div`
  width: 100%;
  height: 18px;
  z-index: 1;
`;

export const MenuImage = styled.div`
  width: 350px;
  margin-left: 10px;
  height: 140px;
  cursor: pointer;
  ${({ theme: { media } }) => media.tablet`
    width: 600px; 
    margin-left: 20px; 
    height: 260px;
    cursor: pointer;
  `};
  ${({ theme: { media } }) => media.desktop`
    width: 528.34px;
    height: 95%;
  `};
`;
export const Image2 = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;

  ${({ theme: { media } }) => media.tablet`
    width: 100%;
    height: 100%;
    object-fit: cover;
  `};
  ${({ theme: { media } }) => media.desktop`
    width: 100%;
    height: 100%;
    object-fit: cover;
  `}
`;
