import { Homepage } from "components/pages";
import LayoutHome from "layouts/layouthome/LayoutHome";

function Home() {
  return (
    <LayoutHome>
      <Homepage />
    </LayoutHome>
  );
}

export default Home;
