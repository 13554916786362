import { ICommerceReducer } from "../interfaces";
import {
  CLEAN_INFO_COMMERCE,
  GET_ALL_COMMMERCES,
  GET_BENEFITS_COMMMERCE_BY_ID,
  GET_COMMERCES,
  GET_COMMERCES_BY_ID,
  GET_SCORES_BENEFIT,
  SET_IS_LOADING_DETAIL_COMMERCE,
  CLEAN_COMMMERCES
} from "../types";

const INITIAL_STATE: ICommerceReducer = {
  commerces: [],
  isLoading: true,
  selectedCommerce: {
    id: "",
    created_at: "",
    updated_at: "",
    deleted_at: null || "",
    allow_consume_in_site: false,
    allow_minors: false,
    allow_pets: false,
    city: "",
    image: "",
    direction: null || "",
    description: "",
    banner_image: "",
    has_delivery: false,
    latitude: 0,
    longitude: 0,
    name: "",
    user_id: 0,
    score: 0,
    galleries: [],
    benefits: [],
  },
  isLoadingDetailCommerce: true,
  benefitsCommerce: [],
  scoresBenefits: [],
  allCommerces: [],
  isLoadingAllCommerces: true,
};

export const commercesReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case CLEAN_COMMMERCES:
      return {
        ...state,
        commerces: action.payload,
      };
    case GET_COMMERCES:
      return {
        ...state,
        commerces: action.payload,
        isLoading: false,
      };
    case GET_COMMERCES_BY_ID:
      return {
        ...state,
        selectedCommerce: action.payload,
        isLoadingDetailCommerce: false,
      };
    case SET_IS_LOADING_DETAIL_COMMERCE:
      return {
        ...state,
        isLoadingDetailCommerce: true,
      };
    case GET_BENEFITS_COMMMERCE_BY_ID:
      return {
        ...state,
        benefitsCommerce: action.payload,
      };
    case GET_SCORES_BENEFIT:
      return {
        ...state,
        scoresBenefits: action.payload,
      };
    case CLEAN_INFO_COMMERCE:
      return {
        ...state,
        isLoadingDetailCommerce: true,
        selectedCommerce: {
          id: "",
          created_at: "",
          updated_at: "",
          deleted_at: null || "",
          allow_consume_in_site: false,
          allow_minors: false,
          allow_pets: false,
          city: "",
          image: "",
          direction: null || "",
          description: "",
          banner_image: "",
          has_delivery: false,
          latitude: 0,
          longitude: 0,
          name: "",
          user_id: 0,
          score: 0,
          galleries: [],
          benefits: [],
        },
      };
    case GET_ALL_COMMMERCES:
      return {
        ...state,
        allCommerces: action.payload,
        isLoadingAllCommerces: false,
      };
    default:
      return state;
  }
};
