import React, { Fragment } from "react";
import { Modal } from "antd";
import { S3_CREDENTIAL } from "utils/contants";
import { useNavigate } from "react-router-dom";
import { _USER_DELETE_ACCOUNT, _USER_LOGOUT } from "redux/actions/userAction";
import { CLOSE_SESSION, EDIT_PROFILE, WHATS_SUPPORT } from "assets/icons";

import { useViveSelector, useViveDispatch } from "../../../hooks/reduxHooks";
import { DELETE_ACCOUNT } from "../../../assets/icons";

import UserTab from "./UserTab";
import {
  ContainerMenuGlobal2,
  ContainerMenuPerfile,
  ContainerMenuPerfile2,
  Content,
  Datos1,
  IconPerfile,
  InformationSeccion,
  InformationSeccionTwo,
  Name,
  Texto6,
  Texto8,
  UserImage,
} from "./styles";

const UserMenu = ({ setIsVisible }: any) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const { user } = useViveSelector((s) => s.user);
  const dispatch = useViveDispatch();
  const navigate = useNavigate();

  const handleEditInfo = () => {
    navigate("/edit-profile");
  };
  const handleLogOut = () => {
    dispatch(_USER_LOGOUT());
  };
  const handleDeleteAccount = async () => {
    setIsOpen(false);
    await dispatch(_USER_DELETE_ACCOUNT(user.id));
    dispatch(_USER_LOGOUT());
  };

  const handleWhatsSupport = () => {
    const data = `https://web.whatsapp.com/send?text=Hola, te escribe ${user.first_name} de Vive Mas. Necesito ayuda en lo siguiente: &phone=573106525888`;
    window.open(data, "_blank");
  };

  const handleOpenModalDeleteAccount = () => {
    setIsVisible(false);
    setIsOpen(true);
  };

  return (
    <Fragment>
      <ContainerMenuGlobal2>
        <ContainerMenuPerfile>
          <IconPerfile>
            <UserImage
              src={`${S3_CREDENTIAL.baseUrl}/${
                user.avatar ? user.avatar : "placeholders/user.png"
              }`}
              alt="user image profile"
            />
          </IconPerfile>
          <Texto8>
            <Name>{user.first_name || ""},</Name>
            <Content>
              Próximamente una actualización sobre el estado de tu factura de
              energía.
            </Content>
          </Texto8>
        </ContainerMenuPerfile>
        <ContainerMenuPerfile2>
          {isOpen && (
            <Modal
              visible={isOpen}
              onOk={handleDeleteAccount}
              onCancel={() => setIsOpen(false)}
            >
              <span>¿Estás seguro que deseas elimnar tu cuenta?</span>
              <span> Su cuenta será eliminada en los próximos 15 días.</span>
            </Modal>
          )}
          <InformationSeccion>
            <Datos1>
              <Name>Nombre</Name>
              <Texto6>{user.first_name || ""}</Texto6>
            </Datos1>
            <Datos1>
              <Name>Cédula</Name>
              <Texto6>{user.identification_number || "123456789"}</Texto6>
            </Datos1>
          </InformationSeccion>
          <InformationSeccion>
            <Datos1>
              <Name>Apellido</Name>
              <Texto6>{user.last_name || ""}</Texto6>
            </Datos1>
            <Datos1>
              <Name>Correo</Name>
              <Texto6>{user.email || ""}</Texto6>
            </Datos1>
          </InformationSeccion>
          <InformationSeccionTwo>
            <UserTab
              action={handleEditInfo}
              title="Editar Perfil"
              icon={EDIT_PROFILE}
            />
            <UserTab
              title="Soporte en Línea"
              action={handleWhatsSupport}
              right
              icon={WHATS_SUPPORT}
            />
          </InformationSeccionTwo>
          <InformationSeccionTwo>
            <UserTab
              action={handleLogOut}
              title="Cerrar Sesión"
              icon={CLOSE_SESSION}
            />
            <UserTab
              title="Eliminar cuenta"
              action={handleOpenModalDeleteAccount}
              right
              icon={DELETE_ACCOUNT}
            />
          </InformationSeccionTwo>
        </ContainerMenuPerfile2>
      </ContainerMenuGlobal2>
    </Fragment>
  );
};

export default UserMenu;
