import { IDepartmentsReducer } from "../interfaces";
import {
  GET_DEPARTMENTS,
  SET_SELECTED_DEPARTMENT,
  SET_CURRENT_DEPARTMENT,
  SET_VISIBLE_DEPARTMENT_MANDATORY,
} from "../types/index";

const INITIAL_STATE: IDepartmentsReducer = {
  departments: [],
  selectedDepartment: {
    id: "",
    created_at: "",
    updated_at: "",
    deleted_at: "",
    name: "",
    latitude: 0,
    longitude: 0,
  },
  isLoading: true,
  currentUserDepartment: "Atlantico",
  isVisibleSelectDepartmentMandatory: true,
};

export const departmentsReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case GET_DEPARTMENTS:
      return {
        ...state,
        departments: action.payload,
      };
    case SET_SELECTED_DEPARTMENT:
      return {
        ...state,
        selectedDepartment: action.payload,
      };
    case SET_CURRENT_DEPARTMENT:
      return {
        ...state,
        currentUserDepartment: action.payload,
      };
    case SET_VISIBLE_DEPARTMENT_MANDATORY:
      return {
        ...state,
        isVisibleSelectDepartmentMandatory: action.payload,
      };
    default:
      return state;
  }
};
