import { Fragment } from "react";
import { ICON_RELOAD } from "assets/icons";

import { TextCodeType } from "./BenefitGeneric";
import {
  IconRealoadPlaceListCode,
  ReloadCodePlaceListCode,
  TextReloadPlaceListCode,
} from "./styles";

type BenefitInplaceListCodeProps = {
  code: string;
  handleReloadCodeBenefit: () => void;
};

const BenefitInplaceListCode = ({
  code,
  handleReloadCodeBenefit,
}: BenefitInplaceListCodeProps) => {
  return (
    <Fragment>
      <TextCodeType>{code || ""}</TextCodeType>
      <ReloadCodePlaceListCode onClick={handleReloadCodeBenefit}>
        <IconRealoadPlaceListCode src={ICON_RELOAD} />
        <TextReloadPlaceListCode>Nuevo Código</TextReloadPlaceListCode>
      </ReloadCodePlaceListCode>
    </Fragment>
  );
};

export default BenefitInplaceListCode;
