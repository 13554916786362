import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Category from "components/Category/Category";
import { ISelectedTabCategory } from "redux/redux.dto";

import { useViveDispatch, useViveSelector } from "hooks/reduxHooks";
import { useUserMembershipLevel } from "../../../../hooks/useMembershipLevel";
import { CategoriesItem } from "../../../../redux/redux.dto";
import {
  _GET_CATEGORIES,
  _SET_SELECTED_CATEGORY_TAB,
} from "redux/actions/categoryAction";

import {
  CategoryTabs,
  ContainerHomeCategories,
  ContainerCommerceHits,
  TextEmpty,
} from "./styles";

function HomeCategories() {
  const dispatch = useViveDispatch();
  const navigate = useNavigate();
  const { membershipLevel } = useUserMembershipLevel();
  const { categories, selectedTabCategory } = useViveSelector(
    (state) => state.categories
  );
  const { user } = useViveSelector((state) => state.user);

  useEffect(() => {
    if (membershipLevel.level || membershipLevel.level === 0) {
      dispatch(_GET_CATEGORIES(membershipLevel.level, user.department_id || "1"));
    }
  }, [dispatch, membershipLevel.level, user.department_id]);

  const setSelectedTab = (data: ISelectedTabCategory) => {
    if (selectedTabCategory.title === data.title)
      return dispatch(_SET_SELECTED_CATEGORY_TAB({ id: null, title: "" }));
    else {
      navigate("/search");
      return dispatch(_SET_SELECTED_CATEGORY_TAB(data));
    }
  };
  return (
    <ContainerHomeCategories>
      <ContainerCommerceHits>
        {categories.length ? categories.map((el: CategoriesItem) => (
          <CategoryTabs onClick={() => setSelectedTab(el)} key={el.id}>
            <Category {...el} key={el.id} />
          </CategoryTabs>
        )): 
          <TextEmpty>No hay categorias para esta región.</TextEmpty>
        }
      </ContainerCommerceHits>
    </ContainerHomeCategories>
  );
}

export default HomeCategories;
