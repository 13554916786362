import { useViveSelector } from "hooks/reduxHooks";
import { useState } from "react";
import { ContainerOption, ContentOption, Option } from "./styles";
import { Orbit } from "@uiball/loaders";
import { useTheme } from "styled-components";

interface CustomOptions {
  name: string;
  loading: boolean;
  id: string;
  handleSelect: (value: string) => Promise<void>;
}

export function CustomOption(props: CustomOptions) {
  const { name, handleSelect, loading, id } = props;
  const theme = useTheme();
  const { currentUserDepartment } = useViveSelector(
    (state) => state.departments
  );
  const [isSelected, setIsSelected] = useState<boolean>(false);

  const handleOptionClick = async () => {
    try {
      setIsSelected(true);
      await handleSelect(id);
    } finally {
      setIsSelected(false);
    }
  };

  return (
    <ContainerOption
      currentSelected={name === currentUserDepartment}
      onClick={handleOptionClick}
    >
      <ContentOption>
        <Option>{name}</Option>
      </ContentOption>
      {isSelected && loading ? (
        <Orbit size={35} color={theme.colors.primary} />
      ) : null}
    </ContainerOption>
  );
}
