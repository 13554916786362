import styled from "styled-components";
import { SEARCH } from "../../../../assets/icons";
import { Monserrat12px500 } from "../../../typography/typographt";

export const Search = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 360px;
  border-radius: 10px;
  min-height: 50px;
  padding: 0 10px;
  margin: 20px 0 10px;
  background-color: #fff;
  justify-content: space-between;
  cursor: pointer;
  ${({ theme: { media } }) => media.tablet`
    width: 755px;
    margin: 35px 10px 10px ;
    padding: 0 ;
    min-height: 60px;
      background-color:#fff;
  `};
  ${({ theme: { media } }) => media.desktop`
    width: 980px;
    margin: 33px 20px 0 20px;
    padding: 0 ; 
    min-height:60px;
    background-color:#fff;
    margin-bottom: 20px;
  `};
`;

export const SeacionOne = styled.div`
  width: 90%;
  padding: 10px 20px 10px 0;
  display: flex;
  align-items: center;
  height: 100%;
  font-size: 12px;
  ${({ theme: { media } }) => media.tablet`
    font-size: 15px;
    padding: 10px 20px;
  `};
  ${({ theme: { media } }) => media.desktop`
    font-size: 16px;  
  `};
`;

export const ImageSearch = styled.img.attrs({
  src: SEARCH,
  alt: "Image search",
})`
  width: 90%;
  height: 90%;
  ${({ theme: { media } }) => media.desktop`
  `};
`;

export const SeacionTwo = styled.div`
  max-width: 27px;
  width: 10%;
  height: 25px;
  ${({ theme: { media } }) => media.tablet`
    max-width: 21px;
    width: 10%;
    height: 21px;
    margin-right: 20px;
    margin-top: 10px; 
  `};
  ${({ theme: { media } }) => media.desktop`
    max-width: 36px;
    width: 10%;
    height: 35.59px;
    margin-right: 20px;
    margin-top: 10px;
  `};
`;

//contianer commerce

export const Container = styled.div`
  background-color: #e5e5e5;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 20px 0;
`;
export const IconsBack = styled.div`
  width: 10%;
  height: 100%;
  ${({ theme: { media } }) => media.desktop`
   padding-left: 20px;
   width: 5%;    
  `};
  ${({ theme: { media } }) => media.desktop`
    display: flex;
    padding: 6px;
    margin: 0;
    align-items: center;
    justify-content: flex-start;
  `};
`;

export const Content = styled.div`
  width: 360px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${({ theme: { media } }) => media.tablet`
  width: 770px;
  `};
  ${({ theme: { media } }) => media.desktop`
    width: 1000px;
  `};
`;

export const ContainerSearch = styled.div`
  width: 100%;
  height: 50px;
  margin: 20px 0;
  display: flex;
  align-items: center;
  flex-direction: row;
`;

export const ContainerCommerceAlgolia = styled.div`
  width: 100%;
  height: 130px;
  margin-bottom: 30px;
  ${({ theme: { media } }) => media.tablet`
    width: 770px;
    height: 130px;
    margin-bottom: 30px;
  `};
  ${({ theme: { media } }) => media.desktop`
    height: 130px;
    width: 100%;
    margin-bottom: 30px;
  `};
`;

export const TitleSectionSearch = styled(Monserrat12px500)`
  color: #000;
  ${({ theme: { media } }) => media.tablet`
    font-size: 17px;
  `};
  ${({ theme: { media } }) => media.desktop`
    font-size: 20px;
  `};
`;

export const ImageY = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  filter: invert(48%);
`;
