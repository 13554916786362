import { useEffect, useState } from "react";
import "swiper/css";
import { Formik } from "formik";
import { validationSchema } from "utils/ValidationSchemas";
import moment from "moment";
import { updateInfoUser } from "api/user";
import { Modal } from "antd";
import { useNavigate } from "react-router-dom";
import { _UPDATE_STATE_USER_INFO } from "redux/actions/userAction";

import { useViveSelector, useViveDispatch } from "../../../hooks/reduxHooks";

import InputsProfile from "./InputsProfile";
import { ContainerInputsProfile } from "./styles";
import { pageView } from "utils/mixpanel";

const FormInputsProfile = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { user, id_token } = useViveSelector((state) => state.user);
  const dispatch = useViveDispatch();
  const navigate = useNavigate();

  const handleDataUpdate = (data: any) => {
    dispatch(_UPDATE_STATE_USER_INFO(data));
  };

  useEffect(() => {
    if (user.id) {
      pageView({
        PageType: "Complete Profile Web",
        Name: "Complete Personal Information Web",
        UserTime: new Date().toDateString(),
      });
    }
  }, [user.id]);

  const handleCompleteProfile = async (rest: any, resetForm: Function) => {
    try {
      setIsLoading(true);
      const response = await updateInfoUser({
        id: user.id,
        id_token: id_token,
        ...rest,
        birthday_date: moment(rest.birthday_date).format("MM/DD/YYYY"),
      });
      if (response.status === 200) {
        Modal.success({
          content: "Datos actualizados con éxito!",
          onOk: () => {
            handleDataUpdate(response.data);
            navigate(-1);
          },
        });
        resetForm();
        return;
      }
      setIsLoading(false);
      Modal.error({
        content: "Opps!, no se pudo actualizar tus datos.",
      });
    } catch (error: any) {
      setIsLoading(false);
      console.log("error on handleCompleteProfile", error);
      Modal.error({
        content:
          error?.response?.data?.message ??
          "Opps! no se pudo enviar tus datos.",
      });
    }
  };

  return (
    <ContainerInputsProfile>
      <Formik
        validateOnMount
        enableReinitialize
        validationSchema={validationSchema}
        initialValues={{
          identification_number: "",
          first_name: "",
          second_name: "",
          email: "",
          nic: "",
          customer_quality: null,
          document_type: null,
          last_name: "",
          second_last_name: "",
          landline: "",
          birthday_date: "",
          sex: null,
        }}
        onSubmit={async (values, { resetForm }) =>
          handleCompleteProfile(values, resetForm)
        }
      >
        {({
          errors,
          values,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          touched,
        }) => (
          <InputsProfile
            errors={errors}
            handleBlur={handleBlur}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            touched={touched}
            values={values}
            setFieldValue={setFieldValue}
            isLoading={isLoading}
          />
        )}
      </Formik>
    </ContainerInputsProfile>
  );
};

export default FormInputsProfile;
