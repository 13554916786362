import styled from "styled-components";

import { createPortal } from "react-dom";
import { HeaderCloseModal, IconClose, Img, ModalBackground } from "./styles";
import { Monserrat20x500 } from "components/typography/typographt";
import {
  APPSTORE_DOWNLOAD,
  PAYSTORE_DOWNLOAD,
  USING_APP,
  DINAMIC_DOWNLOAD,
  VIVEMAS_LOGO,
} from "assets/image";
import DOWNLOAD_MOBILE from "../../assets/image/background-download-mobile.svg";
import { useViveDispatch } from "hooks/reduxHooks";
import { DISMISS_MODAL_DOWNLOAD } from "redux/types";

function ModalDownloadApp({ hideBanner }: any) {
  const dispatch = useViveDispatch();
  function hideModalDownloadApp() {
    dispatch({ type: DISMISS_MODAL_DOWNLOAD, payload: false });
  }

  function openOSDownload() {
    window.open(
      "https://apps.apple.com/co/app/vive-mas/id1630842847",
      "_blank"
    );
  }

  function openPlayStoreDownload() {
    window.open(
      "https://play.google.com/store/apps/details?id=com.vivemas",
      "_blank"
    );
  }

  return createPortal(
    <ModalBackground>
      <ContainerModal>
        <BodyModal>
          <TitleHeaderModal2>Bienvenido a Vive Mas</TitleHeaderModal2>
          <ContentSubTitle>
            <SubTitle>
              <strong>Descarga la app Vive Mas</strong> y disfruta de
              beneficios, descuentos, premios y juegos exclusivos.
            </SubTitle>
          </ContentSubTitle>
          <ContentImageDownload>
            <div onClick={openOSDownload}>
              <ImageDownload src={APPSTORE_DOWNLOAD} />
            </div>
            <div onClick={openPlayStoreDownload}>
              <ImageDownload src={PAYSTORE_DOWNLOAD} />
            </div>
          </ContentImageDownload>
          <DinamicDownload src={DINAMIC_DOWNLOAD} />
        </BodyModal>
        <ContentImageApp>
          <HeaderCloseModal onClick={hideModalDownloadApp}>
            <Img />
          </HeaderCloseModal>
          <ImageApp src={USING_APP} />
        </ContentImageApp>
        <ContentVivemasLogo>
          <CloseVivemasLogo onClick={hideModalDownloadApp}>
            <IconClose />
          </CloseVivemasLogo>
          <VivemasLogo src={VIVEMAS_LOGO} />
        </ContentVivemasLogo>
      </ContainerModal>
    </ModalBackground>,
    document.getElementById("modal-benefit-detail") as HTMLElement
  );
}

export default ModalDownloadApp;

const ContentImageApp = styled.div`
  display: none;
  ${({ theme: { media } }) => media.tablet`
    display: none;
  `};
  ${({ theme: { media } }) => media.desktop`
    max-width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
  `};
`;

const ImageApp = styled.img`
  display: none;
  ${({ theme: { media } }) => media.tablet`
    width: 100%;
    height: 100%;
    display: block;
  `};
  ${({ theme: { media } }) => media.desktop`
    height: auto;
    width: 100%;
    display: block; 
  `};
`;

export const ContentVivemasLogo = styled.div`
  max-width: 100%;
  height: 70%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  ${({ theme: { media } }) => media.tablet`
    max-width: 100%;
    height: 70%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
  `};
  ${({ theme: { media } }) => media.desktop`
    display: none;
  `};
`;

export const CloseVivemasLogo = styled.div`
  width: 21.37px;
  height: 21px;
  padding: 4px;
  display: flex;
  background-color: #cbcbcb;
  border-radius: 100px;
  margin: 6px 6.53px 0 0;
  ${({ theme: { media } }) => media.tablet`
    width: 51.73px;
    height: 48.15px;
    padding: 10px;
    display: flex;
    background-color: #cbcbcb;
    border-radius: 100px;
    margin: 6px 6.53px 0 0;
  `};
  ${({ theme: { media } }) => media.desktop`
    display: none;
  `};
`;

export const VivemasLogo = styled.img`
  width: 66px;
  height: 43px;
  ${({ theme: { media } }) => media.tablet`
    width: 156px;
    height: 101px;
    margin-right: 2rem;
  `};
  ${({ theme: { media } }) => media.desktop`
    width: 100%;
    height: 100%;
    display: none;
  `};
`;

const DinamicDownload = styled.img`
  width: 72px;
  height: 24px;
  display: flex;
  ${({ theme: { media } }) => media.tablet`
    width: 217px;
    height: 60px;
    display: block;
  `};
  ${({ theme: { media } }) => media.desktop`
    display: none;
  `};
`;

export const HeaderModal = styled.div`
  width: 100%;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  cursor: pointer;
`;

export const TitleHeaderModal2 = styled(Monserrat20x500)`
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  font-weight: 900;
  color: #006abf;
  padding-left: 1rem;
  ${({ theme: { media } }) => media.tablet`
    font-weight: 600;
    font-size: 27px;
    line-height: 40px;
    margin: 0;
  `};
  ${({ theme: { media } }) => media.desktop`
    font-size: 28px;
    line-height: 3rem;
    text-align: left;
    color: #006abf;
  `};
`;

export const ContentSubTitle = styled.div`
  width: 100%;
  padding: 0 1rem;
  ${({ theme: { media } }) => media.tablet`
    width: 100%;
    padding: 0 1.3rem;
  `};
  ${({ theme: { media } }) => media.desktop`
    width: 100%;
    padding: 0 1.3rem;
  `};
`;

export const SubTitle = styled(Monserrat20x500)`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
  color: #006abf;
  ${({ theme: { media } }) => media.tablet`
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    margin: 0;
  `};
  ${({ theme: { media } }) => media.desktop`
    font-size: 23px;
    line-height: 30px;
    text-align: left;
  `};
`;

export const BodyModal = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${({ theme: { media } }) => media.tablet`
    width: 100%;
    height: 100%;
    display: flex;
    padding: 38px 0 40px 30px;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  `};
  ${({ theme: { media } }) => media.desktop`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
  `};
`;

export const ContentBodyModal = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  ${({ theme: { media } }) => media.tablet`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  `};
  ${({ theme: { media } }) => media.desktop`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  `};
`;

export const ContainerModal = styled.div`
  width: 308px;
  height: 200px;
  border-radius: 1rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  background-image: url(${DOWNLOAD_MOBILE});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  ${({ theme: { media } }) => media.tablet`
    width: 685px;
    height: 397px;
    border-radius: 29px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    background-image: url(require("assets/image/image-test.png"));
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  `};
  ${({ theme: { media } }) => media.desktop`
    width: calc(55rem - 50px);
    height: calc(30rem - 50px);
    border-radius: 1rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    background-image: url(require("assets/image/image-test.png"));
    flex-direction: column;
    justify-content: flex-start;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  `};
`;

export const ContentImageDownload = styled.div`
  display: none;
  ${({ theme: { media } }) => media.tablet`
    display: none;
  `};
  ${({ theme: { media } }) => media.desktop`
    width: 100%;
    height: 100px;
    display: flex;
    padding: 0 1rem;
    justify-content: space-between;
    align-items: center;
  `};
`;

export const ImageDownload = styled.img`
  width: 8rem;
  height: 80px;
  object-fit: contain;
  ${({ theme: { media } }) => media.tablet`
    width: 235px;
    height: 67px;
    object-fit: contain;
  `};
  ${({ theme: { media } }) => media.desktop`
    width: 150px;
    height: 70px;
    object-fit: contain;
  `};
`;
