import { getApartments } from "../../api/departments";
import { GET_DEPARTMENTS, SET_SELECTED_DEPARTMENT } from "../types";
import { updateUserDepartment } from "../../api/user";

export const _GET_DEPARTMENTS =
  () => async (dispathc: any, getState: Function) => {
    try {
      const token = getState().user?.id_token;
      let response = await getApartments(token);
      if (response.status === 200) {
        dispathc({
          type: GET_DEPARTMENTS,
          payload: response.data.data,
        });
      } else {
        dispathc({
          type: GET_DEPARTMENTS,
          payload: [],
        });
      }
    } catch (error) {
      console.log("error get departments", error);
    }
  };

export const _SET_SELECTED_DEPARTMENT = (data: any) => (dispatch: any) => {
  dispatch({
    type: SET_SELECTED_DEPARTMENT,
    payload: data,
  });
};

export const _UPDATE_USER_DEPARTMENT =
  (data: any) => async (dispatch: any, getState: Function) => {
    try {
      const token = getState().user?.id_token;
      const id = getState().user.user.id;
      const res = await updateUserDepartment(token, id, data);
      dispatch({
        type: "SET_NEW_DEPARTMENT",
        payload: res.data,
      });
    } catch (error) {
      console.log("error get departments", error);
    }
  };
