import { Fragment } from "react";
import { Link } from "react-router-dom";

import { S3_CREDENTIAL } from "utils/contants";
import { CommerceItems } from "../../../redux/redux.dto";
import { CIRCLE_CLOSE, CIRCLE_OPEN, START_REVIEW } from "assets/icons";

import {
  BusinessTabsdesktop,
  CardReview,
  CardReviewTextStatus,
  CommerceCenter,
  CommerceLeft,
  CommerceRigth,
  CommerceText,
  CommerceTitle,
  Condition,
  ContentImage,
  Icon,
  Image3,
  ImagenReview,
  Qualification,
} from "./styles";
import { imageHandler } from "utils/imageHandlers";

const Item: React.FC<CommerceItems> = ({
  id,
  image,
  name,
  description,
  isOpenCommerce,
  score,
}) => {
  return (
    <Link to={`/commerce-detail/${id}`} key={id}>
      <BusinessTabsdesktop>
        <CommerceLeft>
          <ContentImage>
            <Image3
              src={
                image
                  ? imageHandler(image)
                  : `${S3_CREDENTIAL.baseUrl}/placeholders/discount.png`
              }
              style={{ borderRadius: "50px" }}
            />
          </ContentImage>
        </CommerceLeft>
        <CommerceCenter>
          <CommerceTitle>{name}</CommerceTitle>
          <CommerceText>{description}</CommerceText>
        </CommerceCenter>
        <CommerceRigth>
          <Condition>
            {isOpenCommerce ? (
              <Fragment>
                <Icon
                  src={CIRCLE_OPEN}
                  alt="icon state open"
                  width={7}
                  height={7}
                />
                <CardReviewTextStatus>Abierto Ahora</CardReviewTextStatus>
              </Fragment>
            ) : (
              <Fragment>
                <Icon
                  src={CIRCLE_CLOSE}
                  alt="icon state open"
                  width={7}
                  height={7}
                />
                <CardReviewTextStatus>Cerrado</CardReviewTextStatus>
              </Fragment>
            )}
          </Condition>
          <CardReview>
            <Qualification>{score ?  Number(+score).toFixed(1) : 0}/5</Qualification>
            <ImagenReview src={START_REVIEW} alt="icon start review" />
          </CardReview>
        </CommerceRigth>
      </BusinessTabsdesktop>
    </Link>
  );
};

export default Item;
